<template>
    <v-container fluid style="background-color: #F4F4F5 !important;">
        <v-row
            class="mt-5"
            justify="center" align="center"
        >
            <v-card
                width="820"
                elevation="2"
            >
                <v-card-title class="text-small">
                    <small><strong>Media</strong></small>
                    <v-spacer/>
                    <v-btn
                        color="#588BAD"
                        rounded
                        small
                        class="pl-10 pr-10"
                        dark
                        style="textTransform: none !important; color: white;"
                        @click="add_media_dialog_fn"
                    >
                        Add media
                    </v-btn>
                </v-card-title>
                <v-card-subtitle>
                    Add images to use them in your itinerary
                </v-card-subtitle>
                <v-card-text>
                    <v-alert
                        elevation="2"
                        outlined
                        icon="mdi-information-outline"
                        style="font-size: 12px;"
                    >
                        For the best results, we recommend an image size of 1440 x 700 pixels.
                    </v-alert>
                    <v-hover
                        v-if="Object.keys(get_selected_images).length !== 0 || get_supplier_setup.image !== null"
                    >
                        <template v-slot:default="{ hover }">
                            <v-img 
                                :src="get_supplier_setup.image == null ? get_selected_images.url : get_supplier_setup.image"
                                :lazy-src="get_supplier_setup.image == null ? get_selected_images.url : get_supplier_setup.image"
                                aspect-ratio="1"
                                hover
                            >
                                <v-overlay
                                    absolute
                                    color="#000"
                                    v-if="hover"
                                >
                                    <v-btn
                                        icon
                                        class="mr-3"
                                        @click="add_media_dialog_fn"
                                    >
                                        <v-icon>mdi-pen</v-icon>
                                    </v-btn>
                                    <v-btn
                                        icon
                                        @click="remove_selected_image"
                                    >
                                        <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                </v-overlay>
                            </v-img>
                        </template>
                    </v-hover>
                </v-card-text>
            </v-card>
        </v-row>
        <MediaUpload
            :value="add_media_dialog"
            @close_dialog="close_dialog"
        />
    </v-container>
</template>

<script>
import MediaUpload from './inputs/MediaUpload.vue'
import { mapGetters } from 'vuex'
export default {
  components: {
    MediaUpload
  },
  props: [
  ],
  data: () => ({
    add_media_dialog: false,
  }),
  mounted () {
  },
  created () {
  },
  computed: {
    ...mapGetters({
        get_selected_images:                'supplier/get_selected_images',
        get_supplier_setup:                 'supplier/get_supplier_setup',
        get_selected_company:               'auth/get_selected_company'
    })
  },
  methods: {
    add_media_dialog_fn(){
        this.add_media_dialog = true
    },
    close_dialog(){
        this.add_media_dialog = false
    },
    async remove_selected_image(){
        this.$store.dispatch('supplier/set_selected_images', {})
        await this.$axios.patch('/suppliers/update_media', {
            media:              null,
            supplier_id:        this.get_supplier_setup.id,
            company_id:         this.get_selected_company.id
        })
        .then(({data}) => {
            if(data.response){
                this.$store.dispatch('supplier/fetch_supplier', this.get_selected_company.id)
                this.$store.dispatch('supplier/set_supplier_setup', data.supplier_setup)
            }
        })
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>