<template>
    <v-dialog persistent :value="value" max-width="720">
        <v-card>
            <v-card-title>
                {{ get_edit_rates_obj.day }}
                <v-spacer />
                <v-icon @click="close_edit_dialog()">mdi-close</v-icon>
            </v-card-title>
            <v-card-subtitle>
                Edit rate for this day
            </v-card-subtitle>
            <v-card-text>
                <v-text-field v-model="edit_rate_model.net" label="Net" :hint="`Original Net: ${get_edit_rates_obj.net}`"
                    outlined dense type="number" @keyup="computeeditrates(edit_rate_model)">
                </v-text-field>
                <v-text-field v-model="edit_rate_model.markup" label="Markup %"
                    :hint="`Original Markup: ${get_edit_rates_obj.markup}`" outlined dense type="number"
                    @keyup="computeeditrates(edit_rate_model)">
                </v-text-field>
                <v-text-field v-model="edit_rate_model.gross" label="Gross"
                    :hint="`Original Gross ${get_edit_rates_obj.gross}`" outlined dense readonly type="number">
                </v-text-field>
                <v-text-field v-model="edit_rate_model.commission" label="Commission"
                    :hint="`Original Commission ${get_edit_rates_obj.commission}%`" outlined dense readonly type="number">
                </v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn class="pl-6 pr-6" outlined rounded @click="close_edit_dialog()">
                    <v-icon class="mr-2">
                        mdi-close
                    </v-icon>
                    close
                </v-btn>
                <v-btn class="pl-10 pr-10" color="primary" rounded @click="save_edited()">
                    <v-icon class="mr-2">
                        mdi-content-save
                    </v-icon>
                    Save
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    components: {
    },
    props: [
        'value'
    ],
    data() {
        return {
            edit_rate_model: {
                net: 0,
                markup: 0,
                gross: 0,
                commission: 0
            }
        }
    },
    mounted() {
    },
    created() {
    },
    computed: {
        ...mapGetters({
            get_edit_rates_obj: 'supplier_rates/get_edit_rates_obj',
            get_user: 'auth/get_user',
            get_supplier_setup: "supplier/get_supplier_setup",
        })
    },
    methods: {
        showSnackBar(message) {
            this.$store.commit("auth/setMessage",
                { show: true, message: message },
                { root: 1 })
        },
        computeeditrates(item) {
            const a = item.net * (item.markup / 100);
            const gross = Number(item.net) + Number(a);
            this.edit_rate_model.gross = Number(gross).toFixed(0);
            this.edit_rate_model.commission = Number(item.markup / gross).toFixed(2);
        },
        close_edit_dialog() {
            this.$emit('close_dialog')
        },
        async save_edited() {
            if (this.edit_rate_model.net == 0 || this.edit_rate_model.markup == 0 || this.edit_rate_model.gross == 0 || this.edit_rate_model.commission == 0) {
                this.showSnackBar('Net, Gross, Markup, Commission should not be set to 0')
                return
            }
            this.edit_rate_model.updated_by = this.get_user.id
            this.edit_rate_model.id = this.get_edit_rates_obj.id
            await this.$store.dispatch('supplier_rates/edit_rate_api_call', this.edit_rate_model)
            await this.$store.dispatch('supplier_rates/fetch_rates', this.get_supplier_setup.id)
            this.close_edit_dialog()
        }
    },
    watch: {
    }
}
</script>

<style scoped lang="scss"></style>