<template>
    <v-container fluid class="mt-5">
        <v-card>
            <v-card-title style="font-size: 16px;">
                <strong>Team Members</strong>
                <v-spacer></v-spacer>
                <v-btn color="#525A68" class="pl-10 pr-10" dark rounded @click="add_member()"
                    :disabled="get_user.user_role_id === 1 ? false : true">
                    <v-icon class="mr-2">mdi-plus</v-icon>
                    add a member
                </v-btn>
            </v-card-title>
            <v-card-subtitle>
                team members that belongs to <strong>{{ get_selected_company.company_name }}</strong>
            </v-card-subtitle>
            <v-card-text>
                <v-data-table :headers="headers" :items="get_members">
                    <template v-slot:item="{ item }">
                        <tr>
                            <td>
                                {{ item.name }}
                            </td>
                            <td>
                                {{ item.email }}
                            </td>
                            <td>
                                {{ item.created_at }}
                            </td>
                            <td>
                                <v-icon style="cursor: pointer;" small color="black" @click="edit_user(item)"
                                    v-if="get_user.user_role_id === 1">
                                    mdi-pen
                                </v-icon>
                                <v-icon style="cursor: pointer;" @click="delete_user(item)" small color="black"
                                    v-if="get_user.user_role_id === 1">
                                    mdi-delete
                                </v-icon>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <v-row justify="center">
            <v-dialog max-width="620" v-model="add_member_model" persistent>
                <v-card>
                    <v-card-title style="font-size: 16px;">
                        <small>Add a member</small>
                        <v-spacer></v-spacer>
                        <v-icon style="cursor: pointer;" @click="close_dialog_add_member">mdi-close-box-outline</v-icon>
                    </v-card-title>
                    <v-card-text>
                        <v-text-field dense label="First Name" hint="First Name" outlined
                            prepend-inner-icon="mdi-tooltip-text-outline" v-model="m.first_name">
                        </v-text-field>
                        <v-text-field dense label="Last Name" hint="Last Name" outlined
                            prepend-inner-icon="mdi-tooltip-text-outline" v-model="m.last_name">
                        </v-text-field>
                        <v-text-field dense label="Email Address" hint="Email Address" outlined
                            prepend-inner-icon="mdi-email-outline" v-model="m.email">
                        </v-text-field>
                        <v-autocomplete v-model="m.role" :items="user_roles" item-text="name" item-value="id" chips
                            outlined dense prepend-inner-icon="mdi-account-key" label="user role">
                        </v-autocomplete>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn block color="#588BAD" dark small rounded @click="add_member_fn()">
                            Create
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-col cols="auto">
            <v-dialog max-width="600" v-model="dialog_delete_user" persistent>
                <v-card>
                    <v-card-title>
                        Delete User
                    </v-card-title>
                    <v-card-text>
                        <label>are you sure you want to delete this user: </label><strong>{{ get_to_delete_user.name }}
                            ?
                        </strong>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                        <v-btn text class="pl-10 pr-10" @click="dialog_delete_user = false">
                            Close
                        </v-btn>
                        <v-btn color="primary" class="pr-10 pl-10" @click="continue_delete_user">
                            delete
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-col>
        <v-col cols="auto">
            <v-dialog max-width="600" v-model="dialog_edit_user" persistent>
                <v-card>
                    <v-card-title>
                        {{ get_to_edit_user.name }}
                    </v-card-title>
                    <v-card-subtitle>
                        Edit information about this user
                    </v-card-subtitle>
                    <v-card-text>
                        <v-text-field v-model="get_to_edit_user.name" :value="get_to_edit_user.name"
                            prepend-inner-icon="mdi-account" outlined dense label="Name" hint="Name" type="text">
                        </v-text-field>
                        <v-text-field v-model="get_to_edit_user.email" :value="get_to_edit_user.email" :disabled="true"
                            dense outlined label="Email Address" prepend-inner-icon="mdi-email-outline" type="email">
                        </v-text-field>
                        <v-text-field v-model="edit.password" dense outlined label="Password" hint="Password"
                            type="password" prepend-inner-icon="mdi-account-key">
                        </v-text-field>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                        <v-btn text class="pl-10 pr-10" @click="cancel_edit_user()">
                            <v-icon class="mr-3">mdi-cancel</v-icon>
                            Cancel
                        </v-btn>
                        <v-btn color="primary" class="pr-10 pl-10" @click="save_user_information">
                            <v-icon class="mr-3">mdi-content-save</v-icon>
                            Save
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-col>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    components: {

    },
    props: [
        'domain_data'
    ],
    data() {
        return {
            add_member_model: false,
            m: {
                first_name: null,
                last_name: null,
                email: null,
                role: null,
            },
            headers: [
                { text: 'Name' },
                { text: 'Email Address' },
                { text: 'Date Added' },
                { text: 'Action(s)' }
            ],
            dialog_delete_user: false,
            dialog_edit_user: false,
            edit: {
                password: null
            }
        }
    },
    async mounted() {
        await this.$store.dispatch('organization/get_user_roles')
        await this.$store.dispatch('organization/get_org_members', this.domain_data.id)
    },
    created() {

    },
    computed: {
        ...mapGetters({
            'user_roles': 'organization/get_roles',
            'get_response': 'organization/get_response',
            'get_user': 'auth/get_user',
            'get_selected_company': 'auth/get_selected_company',
            'get_members': 'organization/get_members',
            'get_to_delete_user': 'organization/get_to_delete_user',
            'get_to_edit_user': 'organization/get_to_edit_user'
        })
    },
    methods: {
        showSnackBar(message) {
            this.$store.commit("auth/setMessage",
                { show: true, message: message },
                { root: 1 })
        },
        add_member() {
            this.add_member_model = true
        },
        close_dialog_add_member() {
            this.add_member_model = false
        },
        async add_member_fn() {
            if (Object.keys(this.domain_data).length === 0) {
                this.showSnackBar("Choose a company first!")
                return
            }
            let payload = {
                company_id: this.get_selected_company.id,
                ...this.m
            }
            await this.$store.dispatch('organization/register_team_member', payload)
            if (this.get_response.response) {
                this.showSnackBar(this.get_response.data)
                await this.$store.dispatch('organization/get_org_members', this.domain_data.id)
                this.close_dialog_add_member()
            }
        },
        delete_user(data) {
            this.dialog_delete_user = true
            this.$store.dispatch('organization/set_to_delete_user', data)
        },
        async continue_delete_user() {
            await this.$store.dispatch('organization/archive_user', this.get_to_delete_user.id)
            await this.$store.dispatch('organization/get_org_members', this.domain_data.id)
            this.dialog_delete_user = false
        },
        edit_user(data) {
            this.dialog_edit_user = true
            this.$store.dispatch('organization/set_to_edit_user', data)
        },
        async cancel_edit_user() {
            this.dialog_edit_user = false
            await this.$store.dispatch('organization/get_org_members', this.domain_data.id)
        },
        async save_user_information() {
            let tp = {
                id: this.get_to_edit_user.id,
                name: this.get_to_edit_user.name,
                email: this.get_to_edit_user.email,
                password: this.edit.password
            }
            await this.$store.dispatch('organization/save_user_information', tp)
            if (this.get_response.response) {
                this.dialog_edit_user = false
                this.edit.password = null
                await this.$store.dispatch('organization/get_org_members', this.domain_data.id)
            }
        }
    },
    watch: {

    }

}
</script>