<template>
    <v-row justify="center">
        <v-dialog :value="value" persistent max-width="560">
            <v-card v-if="loaded && !has_warning">
                <v-card-title>
                    Add Option for
                    {{ get_chosen_service_supplier.supplier_info.supplier_name }}
                    <v-spacer></v-spacer>
                    <v-icon @click="close_dialog">
                        mdi-close
                    </v-icon>
                </v-card-title>
                <v-divider />
                <v-card-text>
                    <v-card elevation="0">
                        <v-checkbox v-for="(item, itemindex) in get_chosen_service_supplier.product_options"
                            :key="itemindex" v-model="check" :value="item.id" class="pl-5 pr-5">
                            <template v-slot:label>
                                <v-row v-if="item.is_archived == 0">
                                    <v-col cols="8">
                                        <small>
                                            {{ item.name }}
                                        </small>
                                    </v-col>
                                </v-row>
                            </template>
                        </v-checkbox>
                    </v-card>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="primary" class="px-10 py-5" rounded @click="add_itinerary_item">
                        Add
                    </v-btn>
                </v-card-actions>
                <v-card-text v-if="show_check_in_info">
                    <v-divider class="mt-5 mb-5"></v-divider>
                    <v-card v-for="(item, itemindex) in get_to_push_amenities" :key="itemindex" class="mb-5"
                        style="background: #FEFAEB !important;">
                        <v-card-title>
                            <small style="text-decoration: underline;">{{ item.amenities }}</small>
                            <v-spacer />
                            <v-text-field type="number" v-model="item.count" style="max-width:25%;" label="Count">
                            </v-text-field>
                        </v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col cols="6">
                                    <v-menu v-model="menu[itemindex]" :close-on-content-click="false" :nudge-right="40"
                                        transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-model="item.check_in_date" label="check in date"
                                                prepend-icon="mdi-calendar" readonly v-bind="attrs"
                                                v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="item.check_in_date" :min="itinerary.start_date"
                                            :max="itinerary.end_date + 1" label="check in date"
                                            @input="menu[itemindex] = false"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field :value="item.check_in_time" v-model="item.check_in_time" type="time"
                                        label="check in time">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-menu v-model="menu2[itemindex]" :close-on-content-click="false" :nudge-right="40"
                                        transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-model="item.check_out_date" label="check out date"
                                                prepend-icon="mdi-calendar" readonly v-bind="attrs"
                                                v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="item.check_out_date" :min="itinerary.start_date"
                                            :max="itinerary.end_date + 1" label="check in date"
                                            @input="menu2[itemindex] = false"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field :value="item.check_out_time" v-model="item.check_out_time" type="time"
                                        label="check out time">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field label="check in instructions" :value="item.instruction"
                                        v-model="item.instruction">
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                    <v-card-actions>
                        <v-spacer />
                        <v-btn class="pl-10 pr-10 pt-5 pb-5" color="primary" rounded @click="updateOption">
                            Update
                        </v-btn>
                    </v-card-actions>
                </v-card-text>
            </v-card>
            <v-card v-else-if="has_warning">
                <v-card-title>
                    Supplier warning
                </v-card-title>
                <v-card-text>
                    <div v-html="get_chosen_service_supplier.supplier_info.warning_note"></div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="has_warning = false" color="primary" class="px-10 py-5" rounded>Ok</v-btn>
                </v-card-actions>
            </v-card>
            <v-card v-else>
                <v-card-title>Please wait...</v-card-title>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    components: {
    },
    props: [
        'value', 'itinerary'
    ],
    data() {
        return {
            has_warning: false,
            loaded: false,
            check: [],
            show_check_in_info: false,
            menu: [],
            menu2: [],
        }
    },
    async mounted() {
        await this.$store.dispatch('itinerary/fetch_supplier_amenities', this.get_chosen_service_supplier.id)
        if (this.get_chosen_service_supplier.supplier_info) {
            this.loaded = true
            if (this.get_chosen_service_supplier.supplier_info.warning_note != null) {
                this.has_warning = true
            }
        }
    },
    async created() {

    },
    computed: {
        ...mapGetters({
            get_chosen_service_supplier: 'itinerary/get_chosen_service_supplier',
            get_supplier_amenities: 'itinerary/get_supplier_amenities',
            get_to_push_amenities: 'itinerary/get_to_push_amenities',
        }),
        supplier_amenities() {
            return this.$store.getters['itinerary/get_amenities'](this.check)
        }
    },
    methods: {
        add_itinerary_item() {
            // this.$emit('add_itinerary_item', this.check)
            this.show_check_in_info = true
            this.supplier_amenities
        },
        updateOption() {
            this.$emit('updateOption')
        },
        close_dialog() {
            this.$emit('close_dialog')
        },
    },
    watch: {
    }
}
</script>

<style scoped lang="scss"></style>