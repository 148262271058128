<template>
  <div>
    <div class="header">
      <div class="header-title">
        <h3>{{ itinerary.itinerary_name }}</h3>
      </div>
      <div>
        <v-tabs dark background-color="#588BAD" v-model="tab" fixed-tabs>
          <v-tab href="#tab-1">Setup</v-tab>
          <v-tab href="#tab-2" :disabled="itinerary_action != 'UpdateItinerary'">
            Tasks
          </v-tab>
          <v-tab href="#tab-3" :disabled="itinerary_action != 'UpdateItinerary'">
            Build
          </v-tab>
          <v-tab href="#tab-4" :disabled="itinerary_action != 'UpdateItinerary'">
            Pricing
          </v-tab>
          <v-tab href="#tab-5" :disabled="itinerary_action != 'UpdateItinerary'">
            Bookings
          </v-tab>
          <v-tab href="#tab-6" :disabled="itinerary_action != 'UpdateItinerary'">
            Payments
          </v-tab>
          <v-tab href="#tab-7" :disabled="itinerary_action != 'UpdateItinerary'">
            Preview
          </v-tab>
        </v-tabs>
      </div>
    </div>
    <div>
      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-1">
          <v-card flat>
            <Setup :itinerary="itinerary" @refreshItinerary="getItinerary" :action="itinerary_action" />
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-2">
          <v-card flat>
            <Tasks :itinerary="itinerary" @refreshItinerary="getItinerary" />
          </v-card>
        </v-tab-item>
        <v-tab-item value="tab-3">
          <v-card flat>
            <Build :itinerary="itinerary" @refreshItinerary="getItinerary" @refreshBuilds="getBuildsWithRates(true)" />
          </v-card>
        </v-tab-item>
        <v-tab-item value="tab-4">
          <v-card flat>
            <Pricing :itinerary="itinerary" :builds="builds_with_rates" :conversion="conversion" :net="net"
              :gross="gross" :loading_builds="loading_builds" @refreshBuilds="getBuildsWithRates(true)" />
          </v-card>
        </v-tab-item>
        <v-tab-item value="tab-5">
          <v-card flat>
            <Booking :itinerary="itinerary" @refreshItinerary="getItinerary"
              @refreshBuilds="getBuildsWithRates(true)" />
          </v-card>
        </v-tab-item>
        <v-tab-item value="tab-6">
          <v-card flat>
            <Payments :itinerary="itinerary" @refreshItinerary="getItinerary" :loading_builds="loading_builds"
              :net="net" :gross="gross" @refreshBuilds="getBuildsWithRates(true)" />
          </v-card>
        </v-tab-item>
        <v-tab-item value="tab-7">
          <v-card flat>
            <Preview :itinerary="itinerary" :builds="builds_with_rates" :grouped_builds="grouped_builds_with_rates"
              :conversion="conversion" :net="net" :gross="gross" :loading_builds="loading_builds"
              @refreshBuilds="getBuildsWithRates(true)" />
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Setup from '../setup/Setup.vue'
import Tasks from '../tasks/Tasks.vue'
import Build from '../build_two/BuildTwo.vue'
import Pricing from '../pricing/Pricing.vue'
import Booking from '../booking/Booking.vue'
import Payments from '../payments/Payments.vue'
import Preview from '../preview/Preview.vue'

export default {
  name: 'Initial',
  components: {
    Setup,
    Tasks,
    Build,
    Preview,
    Pricing,
    Booking,
    Payments,
  },
  data: () => ({
    loading_builds: true,
    itinerary_id: null,
    itinerary_action: null,
    itinerary: {
      id: null,
      company_id: 0,
      itinerary_name: '',
      itinerary_number: null,
      currency: 'EUR',
      internal_reference: '',
      itinerary_status_id: 1,
      itinerary_template_id: 1,
      start_date: '',
      end_date: '',
      itinerary_map_type: 1,
      agency_info: null,
      traveller_info: null,
      contributor_info: null,
      tags: [],
      location: [],
      notes: [],
      created_by: 0,
      travellers_number: '',
      is_confirm: false,
      service_charge: 0,
      orr: 0,
    },
    builds_with_rates: [],
    grouped_builds_with_rates: [],
    conversion: 1,
    net: 0,
    gross: 0,
    show_archived: false,
    menu: false,
    tab: null,
    headers: [
      { text: '', value: 'initial' },
      { text: 'Title', value: 'title' },
      { text: 'Dates', value: 'dates' },
      { text: 'Confirm', value: 'confirm' },
      { text: 'Status', value: 'status' },
      { text: 'Template', value: 'template' },
      { text: 'Created', value: 'created_at' },
      { text: '', value: 'alert' },
      { text: '', value: 'action' },
    ],
  }),
  computed: {
    ...mapGetters({
      get_selected_company: 'auth/get_selected_company',
    }),
  },
  async mounted() {
    this.itinerary_id = parseInt(this.$route.params.id)
    this.itinerary_action = this.$route.name
    this.getItinerary()
    this.getBuildsWithRates()
    // this.setItineraryPricing()
    this.fetchCompanies()
  },
  methods: {
    async getItinerary() {
      if (this.itinerary_action == 'UpdateItinerary') {
        await this.$axios.get(`v2/itineraries/get_itinerary/${this.itinerary_id}`)
          .then(({ data }) => {
            if (data.response) {
              this.itinerary = data.data
            }
          })
      }
    },
    async getBuildsWithRates(refresh = false) {
      await this.$axios.get(`v2/itineraries/get_builds_with_rates/${this.itinerary_id}`)
        .then(({ data }) => {
          console.log(data)
          this.builds_with_rates = data.data

          let sort_order = [1, 3, 2]
          this.grouped_builds_with_rates = Object.values(Object.groupBy(data.data, ({ ib_id }) => ib_id))
          this.grouped_builds_with_rates.forEach(day => {
            day.sort((a, b) => (sort_order.indexOf(a.option.product.supplier.service_type_id) > sort_order.indexOf(b.option.product.supplier.service_type_id))
              ? 1 : ((sort_order.indexOf(b.option.product.supplier.service_type_id) > sort_order.indexOf(a.option.product.supplier.service_type_id)) ? -1 : 0))
          })

          this.conversion = this.itinerary.currency == 'EUR' ? data.gbp_to_eur : data.eur_to_gbp
          this.loading_builds = false

          this.updateItinerary()
          if (refresh == true) {
            this.$toast.success('Refreshed data successfully')
          }
        })
    },
    async updateItinerary() {
      let payload = {
        net: this.getNet(),
        gross: this.getGross(),
      }
      await this.$axios.post(`v2/itineraries/update_itinerary/${this.itinerary.id}`, payload)
    },
    getNet() {
      let total = 0
      this.builds_with_rates.forEach(build => {
        let days = this.$date(build.check_out_date).diff(this.$date(build.check_in_date), 'd')
        if (this.itinerary.currency != build.option.product.supplier.currency) {
          total += (build.rate_day ? build.rate_day.net : 0) * (days > 0 ? days : 1) * build.count * this.conversion
        }
        else {
          total += (build.rate_day ? build.rate_day.net : 0) * (days > 0 ? days : 1) * build.count
        }
      })
      this.net = Math.abs(total)
    },
    getGross() {
      let total = 0
      this.builds_with_rates.forEach(build => {
        let days = this.$date(build.check_out_date).diff(this.$date(build.check_in_date), 'd')
        if (this.itinerary.currency != build.option.product.supplier.currency) {
          total += (build.rate_day ? build.rate_day.gross : 0) * (days > 0 ? days : 1) * build.count * this.conversion
        }
        else {
          total += (build.rate_day ? build.rate_day.gross : 0) * (days > 0 ? days : 1) * build.count
        }
      })
      this.gross = Math.abs(total) + parseFloat(this.itinerary.service_charge) + parseFloat(this.itinerary.orr)
    },
    async fetchCompanies() {
      await this.$axios.get('v2/companies/get_companies')
        .then(({ data }) => {
          if (data.response) {
            this.$store.dispatch('auth/set_companies', data.data)
          }
        })
    },
  },
  watch: {},
}
</script>

<style scoped>
.header {
  position: sticky;
  z-index: 3;
  top: 64px;
}

.header .header-title {
  line-height: 50px;
  background-color: #79a2bd;
  box-shadow: 0 2px 4px 0px rgb(0 0 0 / 20%);
  position: relative;
  z-index: 1;
}

.header .header-title h3 {
  color: white;
  text-align: center;
}

:deep(button) {
  text-transform:unset;
}
</style>
