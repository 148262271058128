import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        snackbar: {
            show: false,
            message: "",
            button: false,
        },
        user: {},
        token: '',
        selected_company: {}
    },
    mutations: {
        setMessage(state, v) {
            state.snackbar.show = v.show
            state.snackbar.message = v.message
            state.snackbar.button = v.button
        },
        set_user(state, payload){
            state.user = payload
        },
        set_token(state, payload){
            state.token = payload
        },
        set_companies(state, payload){
            state.user.companies = [...payload]
        },
        set_selected_company(state, payload){
            state.selected_company = payload
        },
        set_empty_data(state){
            state.user = {}
            state.selected_company = {}
        }
    },
    getters: {
        mSnackbar : state => state.snackbar,
        get_user : state => state.user,
        get_token : state => state.token,
        get_companies : state => state.user.companies,
        get_selected_company : state => {
            if(state.user.user_role_id === 2){
                return state.user.get_company_belong_to
            }
            return state.selected_company
        }
    },
    actions: {
        set_user({commit}, payload){
            commit('set_user', payload)
        },
        set_token({commit}, payload){
            commit('set_token', payload)
        },
        set_companies({commit}, payload){
            commit('set_companies', payload)
        },
        set_selected_company({commit}, payload){
            commit('set_selected_company', payload)
        },
        save_company_information({ dispatch }, payload) {
            Vue.axios.post('auth/add_company_logo', payload)
                .then(({data}) => {
                    dispatch('set_companies', data.data);
                }).catch(err => console.error(err));
        },

        delete_company_logo({ dispatch }, payload) {
            Vue.axios.patch(`auth/delete_company_logo/${payload.id}`)
                .then(({data}) => {
                    dispatch('set_companies', data.data);
                }).catch(err => console.error(err));
        }
    }
}