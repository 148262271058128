<template>
  <div>
    <v-skeleton-loader type="table" v-if="loading"></v-skeleton-loader>
    <v-row v-else no-gutters>
      <v-col cols="12" v-for="(item, errorindex) in builds" :key="'error' + errorindex">
        <div class="build-wrapper mb-5 pa-2" v-if="item.date == null && Object.keys(item.get_child)[0]">
          <div class="build-header">
            <strong>{{ $date(item.date).format('dddd MMM DD, YYYY') }}</strong>
          </div>
          <v-divider class="mb-5 mt-2" style="background-color: black;"></v-divider>
          <div class="build-content">
            <div class="build-content-main">
              <v-row>
                <v-btn class="mx-2" fab dark large style="cursor:initial;" :color="item.date != null ? 'black' : 'red'">
                  <div class="build-content-day-wrapper" v-if="item.date != null">
                    <div class="build-content-day">Day</div>
                    <div class="build-content-number">{{ errorindex + 1 }}</div>
                  </div>
                  <div class="build-content-day-wrapper" v-else>
                    <div class="build-content-number" style="font-size:26px;">!</div>
                  </div>
                </v-btn>
                <!-- Error service -->
                <div v-for="(iitem, iitemindex) in item.get_child" :key="'erroritem' + iitemindex">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="mx-2" fab large v-bind="attrs" v-on="on">
                        <div class="build-content-day-wrapper">
                          <div class="build-content-number">
                            <v-icon v-if="iitem[0].supplier_product_type">
                              {{ iitem[0].supplier_product_type.icon }}
                            </v-icon>
                          </div>
                        </div>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item v-for="(action, actionindex) in items" :key="'erroraction' + actionindex"
                        @click="act(action, iitem, item)">
                        <v-list-item-title>
                          <v-icon class="mr-2">{{ action.icon }}</v-icon>{{ action.title }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <strong v-if="iitem[0].supplier_info" style="display: block;" class="ml-3 mt-2">
                    {{ iitem[0].supplier_info.supplier_name }}<br>
                    <small v-if="iitem[0].supplier_product" style="font-weight:600;">{{ iitem[0].supplier_product.name
                      }}</small>
                    <br>
                    <small v-for="(option, optionindex) in item.get_child[iitemindex]"
                      :key="'erroroption' + optionindex">
                      <label v-if="optionindex > 0">, </label>
                      <b style="font-size:10px;">x{{ option.count }}</b>
                      <div v-if="option.supplier_product_option">
                        {{ option.supplier_product_option.name }}
                      </div>
                    </small>
                    <br>
                    <small v-if="iitem[0].check_in_time">
                      {{ $date('1/1/2024 ' + iitem[0].check_in_time).format('h:mm A') }}
                      —
                      {{ $date('1/1/2024 ' + iitem[0].check_out_time).format('h:mm A') }}
                    </small>
                    <small v-else>No time selected</small>
                  </strong>
                </div>
                <template>
                  <v-menu right bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="mx-2 action-button" fab large color="primary" v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="openCreateServiceDialog(item)">
                        <v-list-item-title>
                          <v-icon class="mr-2">mdi-semantic-web</v-icon>Service
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="openClearBuildDialog(item)">
                        <v-list-item-title>
                          <v-icon class="mr-2">mdi-trash-can</v-icon>Clear day
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
              </v-row>
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="12" v-for="(item, buildindex) in builds.filter((date) => date.date != null)"
        :key="'build' + buildindex">
        <div class="build-wrapper mb-5 pa-2" v-if="item.date != null">
          <div class="build-header">
            <strong>{{ $date(item.date).format('dddd MMM DD, YYYY') }}</strong>
          </div>
          <v-divider class="mb-5 mt-2" style="background-color: black;"></v-divider>
          <div class="build-content">
            <div class="build-content-main">
              <v-row>
                <v-btn class="mx-2" fab dark large style="cursor:initial;" :color="item.date != null ? 'black' : 'red'">
                  <div class="build-content-day-wrapper" v-if="item.date != null">
                    <div class="build-content-day">Day</div>
                    <div class="build-content-number">{{ buildindex + 1 }}</div>
                  </div>
                  <div class="build-content-day-wrapper" v-else>
                    <div class="build-content-number" style="font-size:26px;">!</div>
                  </div>
                </v-btn>
                <!-- Preview for multiple day service -->
                <div v-for="(child_days, childdaysindex) in item.get_child_days" :key="'itemdays' + childdaysindex"
                  style="display:flex;">
                  <div v-for="(item_days, itemdaysindex) in child_days" :key="'iitem' + itemdaysindex">
                    <v-btn class="mx-2" fab large disabled>
                      <div class="build-content-day-wrapper">
                        <div class="build-content-number">
                          <v-icon v-if="item_days.supplier_product_type">
                            {{ item_days.supplier_product_type.icon }}
                          </v-icon>
                          <v-icon v-else color="red" size="90">
                            mdi-alert-octagon
                          </v-icon>
                        </div>
                      </div>
                    </v-btn>
                    <div v-if="item_days.supplier_info" style="display:block;color:grey;max-width:150px;"
                      class="ml-3 mt-2">
                      <b>{{ item_days.supplier_info.supplier_name }}</b>
                      <br>
                      <div class="mb-2">
                        <small v-if="item_days.supplier_product" style="font-weight:600;">
                          {{ item_days.supplier_product.name }}
                        </small>
                      </div>
                      <div>
                        <small>
                          <b style="font-size:10px;">x{{ item.get_child_days[childdaysindex][itemdaysindex].count }}</b>
                          <label v-if="item.get_child_days[childdaysindex][itemdaysindex].supplier_product_option">
                            {{ item.get_child_days[childdaysindex][itemdaysindex].supplier_product_option.name }}
                          </label>
                        </small>
                      </div>
                      <div class="my-2">
                        <small v-if="item_days.check_in_time">
                          {{ $date('1/1/2024 ' + item_days.check_in_time).format('h:mm A') }}
                          —
                          {{ $date('1/1/2024 ' + item_days.check_out_time).format('h:mm A') }}
                        </small>
                        <small v-else>No time selected</small>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Actual service -->
                <div v-for="(supplier, supplier_index) in item.get_child" :key="'item' + supplier_index"
                  style="display:flex;">
                  <div v-for="(iitem, iitemindex) in supplier" :key="'iitem' + iitemindex">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn class="mx-2" fab large v-bind="attrs" v-on="on">
                          <div class="build-content-day-wrapper">
                            <div class="build-content-number">
                              <v-icon v-if="iitem.supplier_product_type">
                                {{ iitem.supplier_product_type.icon }}
                              </v-icon>
                              <v-icon v-else color="red" size="90">
                                mdi-alert-octagon
                              </v-icon>
                            </div>
                          </div>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item v-for="(action, actionindex) in items" :key="'action' + actionindex"
                          @click="act(action, iitem, item)">
                          <v-list-item-title>
                            <v-icon class="mr-2">{{ action.icon }}</v-icon>{{ action.title }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <div v-if="iitem.supplier_info" style="display:block;max-width:150px;" class="ml-3 mt-2">
                      <b>{{ iitem.supplier_info.supplier_name }}</b>
                      <br>
                      <div class="mb-2">
                        <small v-if="iitem.supplier_product" style="font-weight:600;">
                          {{ iitem.supplier_product.name }}
                        </small>
                      </div>
                      <div>
                        <small>
                          <b style="font-size:10px;">x{{ item.get_child[supplier_index][iitemindex].count }}</b>
                          <label v-if="item.get_child[supplier_index][iitemindex].supplier_product_option">
                            {{ item.get_child[supplier_index][iitemindex].supplier_product_option.name }}
                          </label>
                        </small>
                      </div>
                      <div class="my-2">
                        <small v-if="iitem.check_in_time">
                          {{ $date('1/1/2024 ' + iitem.check_in_time).format('h:mm A') }}
                          —
                          {{ $date('1/1/2024 ' + iitem.check_out_time).format('h:mm A') }}
                        </small>
                        <small v-else>No time selected</small>
                      </div>
                      <div v-if="iitem.supplier_info.warning_note">
                        <small class="tooltip" style="color:red;"><b>Supplier warning</b>
                          <span class="tooltiptext">
                            <div v-html="iitem.supplier_info.warning_note"></div>
                          </span>
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
                <template>
                  <v-menu right bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="mx-2 action-button" fab large color="primary" v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="openCreateServiceDialog(item)">
                        <v-list-item-title>
                          <v-icon class="mr-2">mdi-semantic-web</v-icon>Service
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="openClearBuildDialog(item)">
                        <v-list-item-title>
                          <v-icon class="mr-2">mdi-trash-can</v-icon>Clear day
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
              </v-row>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <AddServiceDialog :value="open_service_dialog" @closeDialog="closeDialog"
      @close_service_dialog="close_service_dialog" />
    <AddOptionDialog v-if="service_setup" :value="service_setup" :itinerary="itinerary" @close_dialog="closeDialog"
      @updateOption="updateOption" />
    <DeleteRowDialog v-if="delete_row" :value="delete_row" @cancel="closeDialog" @delete_row="deleteRow" />
    <EditOptionsDialog :value="edit_options_dialog" :e="e" :itinerary="itinerary"
      @closeEditOptionsDialog="closeEditOptionsDialog" @addOption="addOption" />
    <DialogDeleteItem :value="delete_itinerary_item_model" @close_dialog_delete="close_dialog_delete" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AddServiceDialog from './child_component/AddServiceDialog.vue'
import AddOptionDialog from './child_component/AddOptionDialog.vue'
import DeleteRowDialog from './child_component/DeleteRowDialog.vue'
import EditOptionsDialog from './child_component/EditOptionsDialog.vue'
import DialogDeleteItem from './child_component/Delete_Item.vue'
export default {
  components: {
    AddServiceDialog,
    AddOptionDialog,
    DeleteRowDialog,
    EditOptionsDialog,
    DialogDeleteItem
  },
  props: [
    'itinerary'
  ],
  data() {
    return {
      builds: [],
      loading: true,
      open_service_dialog: false,
      service_setup: false,
      delete_row: false,
      to_delete: {},
      items: [
        { title: 'Edit', icon: 'mdi-pen', action: 'edit' },
        { title: 'Delete', icon: 'mdi-delete', action: 'delete' },
      ],
      edit_options_dialog: false,
      delete_itinerary_item_model: false,
      e: {
        check_in_date: [],
        check_in_time: [],
        check_out_date: [],
        check_out_time: [],
        instructions: []
      }
    }
  },
  async mounted() {
    this.getBuild()
  },
  created() {
  },
  computed: {
    ...mapGetters({
      get_chosen_service_supplier: 'itinerary/get_chosen_service_supplier',
      get_to_push_amenities: 'itinerary/get_to_push_amenities',
      get_day_selected: 'itinerary/get_day_selected'
    })
  },
  methods: {
    async getBuild() {
      const dateArr = []
      let currentDate = new Date(this.itinerary.start_date)
      const stopDate = new Date(this.itinerary.end_date)
      while (currentDate <= stopDate) {
        dateArr.push(
          new Date(currentDate).toLocaleString(undefined, {
            year: "numeric",
            day: "2-digit",
            month: "short",
            weekday: "short",
          })
        )
        currentDate.setUTCDate(currentDate.getUTCDate() + 1)
      }
      let date_data = {
        date_range: dateArr,
        start_date: this.itinerary.start_date,
        end_date: this.itinerary.end_date
      }
      this.$axios.post(`/itinerary/build/base_build/${this.itinerary.id}`, date_data)
        .then(({ data }) => {
          if (data.response) {
            this.builds = data.data
            this.loading = false
            this.$emit('refreshBuilds')
          }
        })
    },
    openCreateServiceDialog(item) {
      this.$store.dispatch('itinerary/set_day_selected', item)
      this.open_service_dialog = true
    },
    closeDialog() {
      this.open_service_dialog = false
      this.service_setup = false
      this.delete_row = false
      this.to_delete = {}
      this.$store.commit('itinerary/set_to_push_amenities')
    },
    close_service_dialog() {
      this.open_service_dialog = false
      this.service_setup = true
    },
    async updateOption() {
      let tp = {
        ib_id: this.get_day_selected.id,
        supplier_id: this.get_chosen_service_supplier.supplier_id,
        amenities: this.get_to_push_amenities,
        service_type_id: this.get_chosen_service_supplier.supplier_info.service_type_id,
      }
      if (tp.amenities[0].check_in_date == null || tp.amenities[0].check_out_date == null) {
        this.$toast.error('Date and Time should not be empty!')
        return
      }
      this.$store.dispatch('itinerary/save_itinerary_item', tp)
      this.getBuild()
      this.closeDialog()
    },
    openClearBuildDialog(item) {
      this.delete_row = true
      this.to_delete = item
    },
    async deleteRow() {
      await this.$axios.delete(`/itinerary/build/delete_children/${this.to_delete.id}`)
        .then(({ data }) => {
          if (data.response) {
            this.getBuild()
            this.closeDialog()
          }
        })
    },
    act(item, actual, day) {
      this.$store.dispatch('itinerary/set_day_selected', day)
      if (item.action == 'edit') {
        this.edit_item(actual)
        this.e.check_in_date[0] = this.$date(actual.check_in_date).format('YYYY-MM-DD')
        this.e.check_in_time[0] = actual.check_in_time
        this.e.check_out_date[0] = this.$date(actual.check_out_date).format('YYYY-MM-DD')
        this.e.check_out_time[0] = actual.check_out_time
        this.e.instructions[0] = actual.instructions
        return
      }
      if (item.action == 'delete') {
        this.delete_item(actual)
        return
      }
    },
    async edit_item(item) {
      await this.$axios.get('itinerary/build/fetch_supplier_product_by_id', { supplier_product_id: item.supplier_product.id })
        .then(({ data }) => {
          this.$store.dispatch('itinerary/set_chosen_service_supplier', data.data)
          this.$store.dispatch('itinerary/set_itinerary_edit_item', [item])
          this.edit_options_dialog = true
        })
    },
    closeEditOptionsDialog() {
      this.edit_options_dialog = false
      this.getBuild()
    },
    addOption(item) {
      this.$store.dispatch('itinerary/set_chosen_service_supplier', item)
      this.edit_options_dialog = false
      this.service_setup = true
    },
    delete_item(item) {
      this.$store.dispatch('itinerary/set_itinerary_edit_item', [item])
      this.delete_itinerary_item_model = true
    },
    close_dialog_delete() {
      this.delete_itinerary_item_model = false
      this.getBuild()
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
.card {
  max-width: 100%;
  background-color: white;
  margin: 25px 15px;
  padding: 25px 30px;
  border-radius: 10px;
}

.btnheader {
  margin: 0px 10px 15px 10px;
}

.btnfooter {
  margin: 15px 10px 0px 10px;
}

.build-header {
  font-size: 12px;
  margin-left: 10px;
  margin-top: 5px;
}

.build-content .build-content-main {
  margin: 12px;
}

.build-content-day {
  margin: 3px;
}

.action-button {
  display: none;
}

.build-content-main:hover .action-button {
  display: inline-block;
}

.card {
  max-width: 100%;
  background-color: white;
  margin: 25px 15px;
  padding: 25px 30px;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0px rgb(0 0 0 / 20%);
}

.card .card-header {
  font-size: 24px;
  color: #343642;
  margin-bottom: 15px;
}

.card .card-header-items {
  text-align: center;
}

.card .card-header-items .header-items {
  margin: 0px 0px;
}

.media-image:hover {
  cursor: pointer;
  box-shadow: 0px 1px 5px 5px rgba(169, 169, 169, 0.6);
}

.image-show-wrapper {
  width: 100%;
}

.image-show-wrapper .image-show {
  max-width: 50%;
  margin: 0 auto;
}

.image-show-wrapper .image-show-name {
  font-size: 10px;
  text-align: center;
}

.tags-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.tags-wrapper .tags {
  max-height: 50px;
  display: flex;
  background-color: #dcdee0;
}

.tags-wrapper .tags .tag-name {
  margin: auto 0;
  padding: 12px;
  color: #343642;
  font-weight: bold;
  font-size: 16px;
  background: rgb(237, 238, 239);
}

.tags-wrapper .tags .tag-action {
  margin: auto 0;
}

.media-button {
  text-align: center;
}

.media-button .input-media {
  /* color: rgb(82, 90, 104); */
  display: none;
}

.dialog-close {
  margin-left: -75px;
  position: absolute;
}

@media only screen and (max-width: 959px) {
  .card .card-header {
    text-align: center;
  }

  .card .card-header-items .header-items {
    margin: 5px 5px 10px 5px;
  }
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted red;
  /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  min-width: 120px;
  background-color: white;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>