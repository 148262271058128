<template>
  <v-dialog persistent width="50%" :value="value">
    <v-card v-if="get_itinerary_edit_item[0] && itinerary">
      <v-card-title>
        <v-icon class="mr-2" v-if="get_itinerary_edit_item[0].supplier_product_type">
          {{ get_itinerary_edit_item[0].supplier_product_type.icon }}
        </v-icon>
        <div v-if="get_itinerary_edit_item[0].supplier_product">{{ get_itinerary_edit_item[0].supplier_product.name }}
        </div>
        <v-spacer />
        <v-icon @click="closeEditOptionsDialog()">mdi-close</v-icon>
      </v-card-title>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" class="pt-5 pb-5 pl-10 pr-10" rounded @click="addOption()">
          Add
        </v-btn>
      </v-card-actions>
      <v-divider />
      <v-card-subtitle class="mt-5">
        <v-alert outlined type="warning" prominent border="left" dense>
          Changing dates may alter the price of these items on your itinerary.
        </v-alert>
      </v-card-subtitle>
      <v-card-text v-for="(item, index) in get_itinerary_edit_item" :key="index">
        <v-card class="mt-5" elevation="0" style="border: 1px solid grey; border-radius: 8px;">
          <v-card-title>
            <v-select dense outlined style="max-width:25%;" :items="get_chosen_service_supplier.product_options"
              item-text="name" item-value="id" v-model="item.supplier_product_id"></v-select>
            <v-spacer></v-spacer>
            <v-text-field type="number" v-model="item.count" style="max-width:25%;" label="Count">
            </v-text-field>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-menu v-model="menu[index]" :close-on-content-click="false" :nudge-right="40"
                  transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="e.check_in_date[index]" label="check in date" prepend-icon="mdi-calendar"
                      readonly v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="e.check_in_date[index]" :min="itinerary.start_date" :max="itinerary.end_date + 1"
                    label="check in date" @input="menu[index] = false"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-text-field type="time" label="Check-in time" v-model="e.check_in_time[index]">
                </v-text-field>
              </v-col>
              <!-- end -->
              <v-col cols="6">
                <v-menu v-model="menu2[index]" :close-on-content-click="false" :nudge-right="40"
                  transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="e.check_out_date[index]" label="check out date" prepend-icon="mdi-calendar"
                      readonly v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="e.check_out_date[index]" :min="itinerary.start_date" :max="itinerary.end_date + 1"
                    label="check out date" @input="menu2[index] = false"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-text-field type="time" label="Check-out time" v-model="e.check_out_time[index]">
                </v-text-field>
              </v-col>
            </v-row>
            <v-text-field type="text" outlined :value="item.description" dense label="Check-in instructions"
              v-model="e.instructions[index]">
            </v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="red" @click="openConfirmDeleteDialog(item, index)">Delete</v-btn>
          </v-card-actions>
        </v-card>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn outlined rounded class="pl-8 pr-8" @click="closeEditOptionsDialog()">
          <v-icon class="mr-2">
            mdi-close
          </v-icon>
          cancel
        </v-btn>
        <v-btn color="primary" rounded class="pl-10 pr-10" @click="updateOption()">
          <v-icon class="mr-2">
            mdi-content-save
          </v-icon>
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="confirm_delete_dialog" persistent max-width="400">
      <v-card v-if="confirm_delete_dialog">
        <v-card-title>
          Are you sure?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeConfirmDeleteDialog()">Cancel</v-btn>
          <v-btn text color="red" @click="deleteOption(item_to_delete, index_to_delete)">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {
  },
  props: [
    'value', 'e', 'itinerary'
  ],
  data() {
    return {
      menu: [],
      menu2: [],
      confirm_delete_dialog: false,
      item_to_delete: null,
      index_to_delete: null,
    }
  },
  async mounted() {
  },
  created() {
  },
  computed: {
    ...mapGetters({
      get_itinerary_edit_item: 'itinerary/get_itinerary_edit_item',
      get_chosen_service_supplier: 'itinerary/get_chosen_service_supplier',
    })
  },
  methods: {
    showSnackBar(message) {
      this.$store.commit("auth/setMessage",
        { show: true, message: message },
        { root: 1 })
    },
    openConfirmDeleteDialog(item, index) {
      this.item_to_delete = item
      this.index_to_delete = index
      this.confirm_delete_dialog = true
    },
    closeConfirmDeleteDialog() {
      this.item_to_delete = null
      this.index_to_delete = null
      this.confirm_delete_dialog = false
    },
    async deleteOption(item, index) {
      this.get_itinerary_edit_item.splice(index, 1)
      this.$store.dispatch('itinerary/delete_itinerary_item', item.id)
      this.$store.dispatch('itinerary/fetch_itinerary_build_config', this.itinerary.id)
      this.$store.dispatch('itinerary/set_itinerary_edit_item', this.get_itinerary_edit_item)
      this.showSnackBar('Deleted option successfully')
      this.closeConfirmDeleteDialog()
      if (this.get_itinerary_edit_item.length == 0) {
        this.closeEditOptionsDialog()
      }
    },
    async addOption() {
      await this.$axios.get('itinerary/build/fetch_supplier_product_by_id', { supplier_product_id: this.get_itinerary_edit_item[0].supplier_product.id })
        .then(({ data }) => {
          this.$emit('addOption', data.data)
        })
    },
    async updateOption() {
      this.get_itinerary_edit_item.forEach((item, index) => {
        if (this.e.check_in_date[index] == null || this.e.check_out_date[index] == null) {
          this.showSnackBar('Date and time should not be empty!')
          return
        }
        let pass = {
          id: item.id,
          count: item.count,
          check_in_date: this.e.check_in_date[index],
          check_in_time: this.e.check_in_time[index],
          check_out_date: this.e.check_out_date[index],
          check_out_time: this.e.check_out_time[index],
          instructions: this.e.instructions[index],
          itinerary_id: this.itinerary.id,
          supplier_product_id: item.supplier_product_id
        }
        this.$store.dispatch('itinerary/edit_itinerary_item', pass)
      })
      this.closeEditOptionsDialog()
    },
    closeEditOptionsDialog() {
      this.$emit('closeEditOptionsDialog')
    },
  },
  watch: {
  }
}
</script>

<style scoped lang="scss"></style>