<template>
    <v-container fluid>
        <v-skeleton-loader type="table" v-if="loading_builds"></v-skeleton-loader>
        <v-card elevation="0" v-if="!loading_builds">
            <v-card-title>
                <v-btn @click="refreshBuilds(true)" rounded color="primary" class="mx-2">
                    <v-icon class="mr-2">mdi-refresh</v-icon>
                    Refresh
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn rounded color="primary" class="pt-4 pb-4 mr-2" style="text-transform: unset !important;"
                    @click="sendPayment()">
                    <v-icon class="mr-2">
                        mdi-send-circle-outline
                    </v-icon>
                    Send Payment Notification
                </v-btn>
                <v-btn rounded color="primary" class="pt-4 pb-4 ml-2" style="text-transform: unset !important;"
                    @click="printPDF()">
                    <v-icon class="mr-2">
                        mdi-download-circle-outline
                    </v-icon>
                    Export Pricing
                </v-btn>
            </v-card-title>
        </v-card>
        <v-divider />
        <v-row v-if="!loading_builds">
            <v-col cols="4" style="display:flex;margin-top:20px;">
                <v-text-field type="number" v-model="itinerary.service_charge" label="Service Charge" outlined dense
                    style="max-width:300px;margin-right:20px;"></v-text-field>
                <v-text-field type="number" v-model="itinerary.orr" label="ORR" outlined dense
                    style="max-width:300px;"></v-text-field>
                <v-btn dark class="ml-2" @click="saveCharges()">Save</v-btn>
            </v-col>
        </v-row>
        <v-row v-if="!loading_builds">
            <v-col cols="12" style="text-align:right;">
                <v-btn @click="hide_row = []" text v-if="hide_row.length > 0">Show all
                    <v-icon>mdi-eye-outline</v-icon></v-btn>
            </v-col>
        </v-row>
        <v-data-table :headers="pricing_table_header" :items="builds" class="mt-5" hide-default-footer
            disable-pagination id="element-to-convert-pricing" style="margin-bottom:500px;" v-if="!loading_builds">
            <template v-slot:item="{ item }">
                <tr v-if="hide_row.indexOf(item.id) == -1"
                    :set="days = $date(item.check_out_date).diff($date(item.check_in_date), 'd') > 0 ? $date(item.check_out_date).diff($date(item.check_in_date), 'd') : 1">
                    <td>
                        <v-checkbox v-model="item.is_paid" @click="markPaid(item)">
                        </v-checkbox>
                    </td>
                    <td>
                        <div style="background: #343642; color: white !important; width: 90px;"
                            class="text-center pt-1 pb-1 pl-1 pr-1">
                            {{ days }} day<label v-if="days > 1">s</label>
                        </div>
                    </td>
                    <td>
                        <v-list-item
                            :style="item.is_paid == 1 ? 'background-color: #00ff00;' : 'background-color: #f5f2d0;'">
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ item.option.product.supplier.supplier_name }}
                                </v-list-item-title>
                                <v-list-item-subtitle v-if="item.option.product">
                                    <small>{{ item.option.product.name }}</small>
                                    <br>
                                    <small>
                                        <b>x{{ item.count }}</b>
                                        {{ item.option.name }}
                                    </small>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle v-else>
                                    <label style="color:red;">Error: Please check this service in the build tab</label>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </td>
                    <td>
                        <div>
                            <v-icon color="black" v-if="item.option.product.type">
                                {{ item.option.product.type.icon }}
                            </v-icon>
                            <v-icon color="red" v-else>
                                mdi-alert-octagon
                            </v-icon>
                        </div>
                    </td>
                    <td>
                        <small>
                            {{ $date(item.check_in_date).format('MMM DD, YYYY') }}
                            <br>
                            {{ $date(item.check_out_date).format('MMM DD, YYYY') }}
                        </small>
                    </td>
                    <td>
                        <v-list-item v-if="item.rate_day">
                            <v-list-item-content :set="rate = item.rate_day.net * days * item.count"
                                :set2="converted_rate = item.rate_day.net * conversion * days * item.count">
                                <v-list-item-title>
                                    <small>
                                        <b>{{ itinerary.currency }} </b>
                                        <label v-if="item.option.product.supplier.currency == itinerary.currency">
                                            <b>{{ rate.toFixed(2) }}</b>
                                        </label>
                                        <label v-else>
                                            <b>{{ converted_rate.toFixed(2) }}</b>
                                        </label>
                                    </small>
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    <small>
                                        {{ itinerary.currency }}
                                        <label v-if="item.option.product.supplier.currency == itinerary.currency">
                                            {{ item.rate_day.net }}
                                        </label>
                                        <label v-else>
                                            {{ (item.rate_day.net * conversion).toFixed(2) }}
                                        </label>
                                        * {{ days }} day<label v-if="days > 1">s</label>
                                        * {{ item.count }} count
                                        <br>
                                        <label style="color:grey;"
                                            v-if="item.option.product.supplier.currency != itinerary.currency">
                                            ({{ item.option.product.supplier.currency }}
                                            {{ item.rate_day.net }} unconverted rate)
                                        </label>
                                    </small>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-else>
                            <small>No rate found for this period</small>
                        </v-list-item>
                    </td>
                    <td>
                        <v-list-item v-if="item.rate_day">
                            <v-list-item-content :set="rate = item.rate_day.gross * days * item.count"
                                :set2="converted_rate = item.rate_day.gross * conversion * days * item.count">
                                <v-list-item-title>
                                    <small>
                                        <b>{{ itinerary.currency }} </b>
                                        <label v-if="item.option.product.supplier.currency == itinerary.currency">
                                            <b>{{ rate.toFixed(2) }}</b>
                                        </label>
                                        <label v-else>
                                            <b>{{ converted_rate.toFixed(2) }}</b>
                                        </label>
                                    </small>
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    <small>
                                        {{ itinerary.currency }}
                                        <label v-if="item.option.product.supplier.currency == itinerary.currency">
                                            {{ item.rate_day.gross }}
                                        </label>
                                        <label v-else>
                                            {{ (item.rate_day.gross * conversion).toFixed(2) }}
                                        </label>
                                        * {{ days }} day<label v-if="days > 1">s</label>
                                        * {{ item.count }} count
                                        <br>
                                        <label style="color:grey;"
                                            v-if="item.option.product.supplier.currency != itinerary.currency">
                                            ({{ item.option.product.supplier.currency }}
                                            {{ item.rate_day.gross }} unconverted rate)
                                        </label>
                                    </small>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-else>
                            <small>No rate found for this period</small>
                        </v-list-item>
                    </td>
                    <td>
                        <div style="display:flex;" v-if="item.rate_day">
                            {{ item.rate_day.markup }}%
                        </div>
                    </td>
                    <td>
                        <div style="display:flex;" v-if="item.rate_day">
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-menu right bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon v-bind="attrs" v-on="on">
                                                    <v-icon>mdi-dots-vertical</v-icon>
                                                </v-btn>
                                            </template>
                                            <v-list>
                                                <v-list-item @click="editPricing(item)">
                                                    <v-list-item-title>
                                                        Edit pricing
                                                    </v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-btn icon class="mt-3"
                                @click="hide_row.push(item.id)"><v-icon>mdi-eye-outline</v-icon></v-btn>
                        </div>
                    </td>
                </tr>
            </template>
            <template slot="body.append">
                <tr
                    v-if="itinerary.service_charge != null && itinerary.service_charge > 0 && hide_row.indexOf(-1) == -1">
                    <td></td>
                    <td></td>
                    <td>
                        <v-list-item
                            :style="service_charge_selected == 1 ? 'background-color: #00ff00;' : 'background-color: #f5f2d0;'">
                            <v-list-item-content>
                                <v-list-item-title>
                                    <label>Service Charge</label>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <small>
                                        <strong>{{ itinerary.currency }}</strong> {{ itinerary.service_charge }}
                                    </small>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </td>
                    <td style="text-align:right;">
                        <v-btn icon class="mt-3" @click="hide_row.push(-1)"><v-icon>mdi-eye-outline</v-icon></v-btn>
                    </td>
                </tr>
                <tr v-if="itinerary.orr != null && itinerary.orr > 0 && hide_row.indexOf(-2) == -1">
                    <td></td>
                    <td></td>
                    <td>
                        <v-list-item
                            :style="orr_selected == 1 ? 'background-color: #00ff00;' : 'background-color: #f5f2d0;'">
                            <v-list-item-content>
                                <v-list-item-title>
                                    <label>ORR</label>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <small>
                                        <strong>{{ itinerary.currency }}</strong> {{ itinerary.orr }}
                                    </small>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                    </td>
                    <td style="text-align:right;">
                        <v-btn icon class="mt-3" @click="hide_row.push(-2)"><v-icon>mdi-eye-outline</v-icon></v-btn>
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td><strong>Net Total: {{ itinerary.currency }} {{ net | formatNumber }}</strong></td>
                    <td><strong>Gross Total: {{ itinerary.currency }} {{ gross | formatNumber }}</strong>
                    </td>
                    <td>
                        <strong>
                            Margin:
                            {{ itinerary.currency }} {{ gross - net | formatNumber }}
                            ({{ ((gross - net) / gross * 100).toFixed(2) }}%)
                        </strong>
                    </td>
                </tr>
            </template>
        </v-data-table>
        <DialogWarning :value="show_dialog_warning" @close_dialog="close_dialog" />
        <DialogEditRate :value="open_custom_rate_dialog" :rate="selected_rate"
            @close_dialog_edit_rate="close_dialog_edit_rate" :itinerary="itinerary" />
    </v-container>
</template>

<script>
import html2pdf from "html2pdf.js"
import DialogWarning from './components/DialogWarning.vue'
import DialogEditRate from './components/DialogEditRate.vue'

export default {
    components: {
        DialogWarning, DialogEditRate,
    },
    props: [
        'itinerary', 'builds', 'conversion', 'net', 'gross', 'loading_builds'
    ],
    data() {
        return {
            pricing_table_header: [
                { text: '', value: 'check', sortable: false },
                { text: 'Days', value: 'days', sortable: false },
                { text: 'Item', value: 'item_value', sortable: false },
                { text: 'Type', value: 'option.product.type.id', sortable: true },
                { text: 'Dates', value: 'check_in_date', width: '100px' },
                { text: 'Net', value: 'net', sortable: false },
                { text: 'Gross', value: 'gross', sortable: false },
                { text: 'Markup', value: 'markup', sortable: false },
                { text: '', value: 'action', sortable: false }
            ],
            service_charge_selected: false,
            orr_selected: false,
            hide_row: [],
            show_dialog_warning: false,
            open_custom_rate_dialog: false,
            selected_rate: null,
            edit_net_price_dialog: false,
        }
    },
    async mounted() {
    },
    created() {
    },
    computed: {
    },
    methods: {
        async refreshBuilds(message = false) {
            this.$emit('refreshBuilds')
            if (message) {
                this.$toast.info('Refreshing...')
            }
        },
        printPDF() {
            html2pdf(document.getElementById('element-to-convert-pricing'), {
                margin: 0.2,
                filename: this.itinerary.itinerary_name + '.pdf',
                image: { type: 'jpeg', quality: 1.0 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' }
            })
        },
        async saveCharges() {
            let payload = {
                id: this.itinerary.id,
                service_charge: parseFloat(this.itinerary.service_charge),
                orr: parseFloat(this.itinerary.orr),
            }
            await this.$axios.post('itinerary/save_charges', payload)
                .then(() => {
                    this.$toast.success('Saved service charge and ORR successfully')
                    this.refreshBuilds()
                })
        },
        editPricing(item) {
            this.selected_rate = item.rate_day
            this.open_custom_rate_dialog = true
        },
        markPaid(item) {
            this.$store.dispatch('itinerary/update_payment_status_pricing', item)
        },
        sendPayment() {
            let paid_list = []
            this.builds.filter(q => {
                if (q.is_paid) {
                    paid_list.push(q)
                }
            })
            this.show_dialog_warning = true
            this.$store.dispatch('itinerary/set_payment_notification_suppliers', paid_list)
        },
        close_dialog() {
            this.show_dialog_warning = false
        },
        close_dialog_edit_rate() {
            this.selected_rate = false
            this.open_custom_rate_dialog = false
            this.refreshBuilds()
        },
    },
    watch: {
    }
}
</script>

<style scoped lang="scss"></style>