<template>
  <v-container
    fluid
    style="background-color: #f4f4f5 !important"
    class="ma-0 pa-0"
  >
    <v-alert color="#79A2BD" dark class="text-center">
      <strong>{{ get_selected_company.company_name }}</strong>
    </v-alert>
    <v-row class="mt-5" justify="center" align="center">
      <v-card elevation="2" width="80%" class="mt-5">
        <v-card-title class="text-small">
          <small><strong>Contacts</strong></small>
          <v-spacer />
          <v-btn
            rounded
            outlined
            class="pl-10 pr-10 mr-3"
            style="texttransform: none !important"
          >
            import / export
          </v-btn>
          <v-btn
            color="#525A68"
            rounded
            class="pl-10 pr-10"
            style="texttransform: none !important"
            @click="$router.push({ name: '/create-contact' })"
            dark
          >
            Create contact
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-tabs v-model="tab">
            <v-tab
              v-for="(nav, navitem) in navigation"
              :key="navitem"
              :href="nav.href"
              ripple
            >
              {{ nav.label }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item value="tab-1">
              <AllTab
                :headers="headers_all"
                @arhive="arhive"
                @restore="restore"
                @contact_setup="contact_setup"
              />
            </v-tab-item>
          </v-tabs-items>
          <v-tabs-items v-model="tab">
            <v-tab-item value="tab-2">
              <Traveler
                :headers="headers_all"
                @arhive="arhive"
                @restore="restore"
                @contact_setup="contact_setup"
              />
            </v-tab-item>
          </v-tabs-items>
          <v-tabs-items v-model="tab">
            <v-tab-item value="tab-3">
              <Agent
                :headers="headers_all"
                @arhive="arhive"
                @restore="restore"
                @contact_setup="contact_setup"
              />
            </v-tab-item>
          </v-tabs-items>
          <v-tabs-items v-model="tab">
            <v-tab-item value="tab-4">
              <Supplier
                :headers="headers_all"
                @arhive="arhive"
                @restore="restore"
                @contact_setup="contact_setup"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import AllTab from "./components/AllTab.vue";
import Traveler from "./components/TravelerComponent.vue";
import Agent from "./components/AgentComponent.vue";
import Supplier from "./components/SupplierComponent.vue";
export default {
  components: { AllTab, Traveler, Agent, Supplier },
  props: [],
  data: () => ({
    tab: null,
    navigation: [
      {
        label: "All",
        href: "#tab-1",
      },
      {
        label: "Travellers",
        href: "#tab-2",
      },
      {
        label: "Agents",
        href: "#tab-3",
      },
      {
        label: "Suppliers",
        href: "#tab-4",
      },
    ],
    headers_all: [
      { text: "FIRST NAME" },
      { text: "LAST NAME" },
      { text: "EMAIL" },
      { text: "TYPE" },
      { text: "CREATED" },
      { text: "" },
    ],
  }),
  async mounted() {
    // await this.$store.dispatch("contact/fetch_contacts");
    await this.$store.dispatch(
      "contact/fetch_contacts",
      this.get_selected_company.id
    );
    await this.$store.dispatch("contact/fetch_contact_list");
    await this.$store.dispatch("contact/fetch_gender");
  },
  created() {},
  computed: {
    ...mapGetters({
      get_selected_company: "auth/get_selected_company",
      get_contacts: "contact/get_contacts",
    }),
  },
  methods: {
    async arhive(id) {
      console.log("arhive: " + id);
    },
    async restore(id) {
      console.log("arhive: " + id);
    },
    contact_setup(data) {
      console.log("setup: " + data);
      console.log(data);
      this.$store.dispatch("contact/set_contact_setup", data);
      this.$router.push({ name: "/contact/setup" });
    },
  },
  watch: {},
};
</script>

<style scoped lang="scss"></style>
