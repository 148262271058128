<template>
    <div v-if="!success && show" class="err-message">
        {{ message }}
    </div>
    <div v-else-if="success && show" class="suc-message">
        {{ message }}
    </div>
</template>

<script>
  export default {
    name: 'ErrMessage',
    props: {
        message: String,
        show: Boolean,
        success: Boolean
    },
    data: () => ({
    }),
  }
</script>

<style scoped>
.err-message {
    font-size: 10px;
    margin-top: -15px;
    color: red;
}
.suc-message {
    font-size: 10px;
    margin-top: -15px;
    color: green;
}
</style>