<template>
    <v-container fluid class="mt-5">
      <!-- EDIT DIALOG -->
      <EditCompany 
        :value="edit_dialog"
        :items="toEdit"
        @toggle_edit_dialog="toggle_edit_dialog"
      />

      <v-card>
        <v-card-title
            style="fontSize: 16px;"
        >
            <strong>Company</strong>
            <v-spacer></v-spacer>
            <v-btn
                color="#525A68"
                class="pl-10 pr-10"
                dark
                rounded
                @click="add_company"
                :disabled="get_user.user_role_id === 1 ? false : true"
            >
                <v-icon
                    class="mr-2"
                >mdi-plus</v-icon>
                add company
            </v-btn>
        </v-card-title>
        <v-card-subtitle>
            Companies owned by you
        </v-card-subtitle>
        <v-card-text>
            <v-data-table
                :headers="headers"
                :items="get_companies"
            >
                <template v-slot:item="{ item, expand, isExpanded }" >
                    <tr
                        style="cursor:pointer;" 
                        @click="expand(!isExpanded)"
                    >
                        <td>
                            {{ item.company_name }}
                        </td>
                        <td>
                            {{ item.created_at }}
                        </td>
                        <td>
                            <v-icon
                                small
                                @click="edit(item); toggle_edit_dialog(true)"
                            >
                                mdi-pencil
                            </v-icon>
                        </td>
                    </tr>
                </template>
                <template v-slot:item.action></template>
            </v-data-table>
        </v-card-text>
      </v-card>
      <v-row justify="center">
        <v-dialog
            max-width="620"
            v-model="add_company_dialog"
            persistent
        >
            <v-card>
                <v-card-title
                    style="fontSize: 16p;x"
                >
                    <small>Add company</small>
                    <v-spacer></v-spacer>
                    <v-icon
                        style="cursor: pointer;"
                        @click="close_dialog_add_company"
                    >mdi-close-box-outline</v-icon>
                </v-card-title>
                <v-card-text>
                    <v-text-field
                        dense
                        label="company name"
                        hint="company name"
                        outlined
                        prepend-inner-icon="mdi-tooltip-text-outline"
                        v-model="c.company_name"
                        @keyup.enter="add_company_fn"
                    >
                    </v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        block
                        color="#588BAD"
                        dark
                        small
                        rounded
                        @click="add_company_fn"
                    >
                        Create
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
      </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import EditCompany from '../components/EditCompanyComponent.vue'

export default {
  components: {
    EditCompany
  },
  props: [
  ],
  data: () => ({
    headers: [
        {text: 'Company Name'},
        {text: 'Created At'},
        {text: 'Action(s)', value: 'action'}
    ],
    add_company_dialog: false,
    c: {
        company_name: null
    },

    edit_dialog: false,
    toEdit: []
  }),
  mounted () {
  },
  created () {
  },
  computed: {
    ...mapGetters({
        get_companies:          'auth/get_companies',
        'get_user':             'auth/get_user',
    })
  },
  methods: {
    add_company(){
        this.add_company_dialog = true
    },
    close_dialog_add_company(){
        this.add_company_dialog = false
        this.c = {}
    },
    showSnackBar(message){
        this.$store.commit("auth/setMessage", 
        {show: true, message: message}, 
        {root: 1})
    },
    async add_company_fn(){
        await this.$axios.post('auth/add_company', {
            company_name:       this.c.company_name
        })
        .then(({data}) => {
            this.showSnackBar(data.message)
            this.$store.dispatch('auth/set_companies', data.data)
            this.close_dialog_add_company()
        })
        .catch(err => {
            this.showSnackBar(err.message)
        })
    },

    // Author: Bruce James Dela Cruz
    // Aug 21, 2023
    edit(obj) {
        this.toEdit = obj;
    },
    
    toggle_edit_dialog(bool) {
        this.edit_dialog = bool;
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>