<template>
    <div>
        <v-dialog
            :value="get_value"
            width="500"
            persistent
        >
            <v-card>
                <v-card-title>
                    {{ get_items.company_name }}
                    <v-spacer></v-spacer>
                    <v-icon
                        small
                        color="red"
                        @click="toggle_dialog(false)"
                    >
                        mdi-close
                    </v-icon>
                </v-card-title>
                <v-card-text>
                    <v-form
                        class="mt-2"
                        ref="form"
                        lazy-validation
                    >
                        <v-file-input
                            outlined
                            hide-details
                            label="Logo"
                            dense
                            :rules="nameRules"
                            v-model="file"
                        ></v-file-input>
                    </v-form>
                    <small class="ml-8">File only accepts .png, .jpg formats.</small>
                    <div
                        v-if="get_items.company_logo"
                        class="mt-5"
                    >
                        <v-divider></v-divider>
                        <div
                            class="mt-2"
                        >
                            <v-img
                                class="mx-auto"
                                contain
                                max-height="150"
                                max-width="250"
                                :src="get_items.company_logo"
                            ></v-img>
                            <v-icon
                                class="d-flex mx-auto mt-5"
                                @click="toDelete = !toDelete"
                                
                            >
                                mdi-delete
                            </v-icon>
                            <div
                                v-if="toDelete == true"
                                class="mt-5 d-inline-flex flex-row justify-center ml-15"
                                
                            >
                                <p>Are you sure you want to delete this logo?</p>
                                <v-btn
                                    class="ml-2"
                                    x-small
                                    color="error"
                                    @click="remove_logo(get_items)"
                                >
                                    Delete
                                </v-btn>
                            </div>
                        </div>
                    </div>
                </v-card-text>
                <v-btn
                    class="rounded-0"
                    block
                    color="primary"
                    @click="submit"
                >
                    submit
                </v-btn>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    export default {
        components: {

        },

        props: [
            'value',
            'items'
        ],

        destroyed() {
            this.toDelete = false;
            this.file = null;
        },

        data: () => ({
            file: null,
            nameRules: [
                v => !!v || 'File is required'
            ],
            toDelete: false
        }),

        computed: {
            get_value() {
                return this.value;
            },
            get_items() {
                return this.items;
            }
        },

        methods: {
            toggle_dialog(bool) {
                this.$emit('toggle_edit_dialog', bool);
            },

            extension(item) {
                const parts = item.name.split('.');
                const file_type = parts[parts.length - 1].toLowerCase();

                return file_type;
            },

            submit() {
                const validation = this.$refs.form.validate();
                const ext = this.extension(this.file);

                if(!validation) return;

                if(ext != 'png' && ext != 'jpeg' && ext != 'jpg') {
                    alert('Please select a valid file!');
                    return;
                }

                const formData = new FormData();
                formData.append('file', this.file);
                formData.append('company_id', this.items.id);
                formData.append('user_id', this.items.user_id);

                this.$store.dispatch('auth/save_company_information', formData);

                this.file = null;
                this.toggle_dialog(false);
            },

            remove_logo(obj) {
                this.$store.dispatch('auth/delete_company_logo', obj);
                this.toDelete = false;
            }

        }
    }
</script>

<style scoped>

</style>