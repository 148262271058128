import Vue from 'vue'

export default {
    namespaced: true,
    state: {
        commission_type: [],
        agencies_info: [],
        contact_info: [],
        // agencies_contact_info: [],
    },
    mutations: {
        set_commission_type(state, payload){
            state.commission_type = [...payload]
        },
        set_agencies_info(state, payload){
            state.agencies_info = [...payload]
        },
        set_agency(state, payload){
            state.agencies_info.push(payload)
        },
        set_contact_info(state, payload){
            state.contact_info = [...payload]
        },
        // set_agencies_contact_info(state, payload){
        //     state.agencies_contact_info = [...payload]
        // }
    },
    getters: {
        get_commission_type : state => state.commission_type,
        get_agencies_info : state => (search) => {
            if(search){
                return state.agencies_info.filter(element => {
                    if(element.business_name.toLowerCase().startsWith(search) || element.business_name.toUpperCase().startsWith(search)){
                        return element
                    }
                    else{
                        if(element.tags != null){
                            return element.tags.some((tag) => {
                                if(tag.toLowerCase().startsWith(search) || tag.toUpperCase().startsWith(search)){
                                    return element
                                }
                            });
                        }
                    }
                })
            }
            else{
                return state.agencies_info
            }
        },
        get_selected_commission_type : state => (id) => {
            return state.commission_type.filter((element) => {
                if(element.id==id){
                    return element.is_percentage
                }
            })
        },
        get_contact_info : state => (filter) => {
            if(filter.length!=0){
                return state.contact_info.filter(el => {
                    if(!filter.some(value => value.contact_id == el.id)){
                        return el
                    }
                })
            }
            else{
                return state.contact_info
            }
        },
        get_agencies_itinerary_info : state => () => {
            let info = []
            for(let agency of state.agencies_info){
                for(let item of agency.agency_contact){
                    info.push({
                        id: agency.id,
                        business_name: agency.business_name,
                        contact_id: item.contact_id,
                        contact_info: item.contact_info
                    });
                }
            }
            return info
        }
    },
    actions: {
        async fetch_commission_type({commit}){
            await Vue.axios.get('/agencies/fetch_commission_type')
            .then(({data}) => {
                commit('set_commission_type', data.data)
            })
        },
        async fetch_agencies_info({commit}, payload){
            await Vue.axios.get(`/agencies/${payload}`)
            .then(({data}) => {
                commit('set_agencies_info', data.data)
            })
        },
        set_agency({commit}, payload){
            commit('set_agency', payload)
        },
        async fetch_archived_agencies_info({commit}, payload){
            await Vue.axios.get(`/agencies/fetch_archived_agencies_info/${payload}`)
            .then(({data}) => {
                commit('set_agencies_info', data.data)
            })
        },
        async fetch_contact_info({commit}, payload){
            await Vue.axios.get(`/agencies/fetch_contact_info/${payload}`)
            .then(({data}) => {
                commit('set_contact_info', data.data)
            })
        },
        // async fetch_agencies_contact_info({commit}, payload){
        //     await Vue.axios.get(`/agencies/${payload}`)
        //     .then(({data}) => {
        //         commit('set_agencies_contact_info', data.data)
        //     })
        // },
    }
}