<template>
  <div style="margin-bottom:100px;">
    <v-skeleton-loader type="table" v-if="itinerary.id == null && action == 'UpdateItinerary'"></v-skeleton-loader>
    <v-row v-if="itinerary.id || action != 'UpdateItinerary'">
      <v-col cols="6">
        <div class="card">
          <v-row no-gutters>
            <v-col cols="12" class="mb-2">
              <h3>Itinerary</h3>
            </v-col>
            <v-col cols="12">
              <v-text-field class="pad-all" v-model="itinerary.itinerary_name" label="Itinerary name *"
                outlined></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="5" :nudge-bottom="61"
                transition="scale-transition" min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="itinerary.start_date" label="Start date *" readonly v-bind="attrs" v-on="on"
                    class="pad-all" outlined></v-text-field>
                </template>
                <v-date-picker v-model="itinerary.start_date"
                  @input="menu1 = false; date_changed = true;"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6">
              <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="5" :nudge-bottom="61"
                transition="scale-transition" min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="itinerary.end_date" label="End date *" readonly v-bind="attrs" v-on="on"
                    class="pad-all" outlined></v-text-field>
                </template>
                <v-date-picker v-model="itinerary.end_date"
                  @input="menu2 = false; date_changed = true;"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" v-if="action != 'UpdateItinerary'">
              <div class="text-right">
                <v-btn rounded color="#68a74f" dark @click="createItinerary()" style="font-size:16px;font-weight:600;"
                  v-if="!creating">
                  Create
                </v-btn>
                <v-btn rounded color="#68a74f" disabled style="font-size:16px;font-weight:600;" v-else>
                  Please wait
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters v-if="action == 'UpdateItinerary'">
            <v-col cols="6">
              <v-text-field class="pad-all" v-model="itinerary.itinerary_number" label="Itinerary number"
                outlined></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="itinerary.internal_reference" label="Internal reference" class="pad-all"
                outlined></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-select class="pad-all" :items="get_companies" v-model="itinerary.company_id" item-text="company_name"
                item-value="id" outlined label="Company"></v-select>
            </v-col>
            <v-col cols="6">
              <v-select v-model="itinerary.currency" label="Currency" class="pad-all" outlined
                :items="['EUR', 'GBP', 'ISK']"></v-select>
            </v-col>
            <v-col cols="6">
              <div class="form-group pad-all">
                <v-autocomplete v-model="itinerary.itinerary_status_id" :items="get_itinerary_status"
                  class="form-control" item-value="id" item-text="name" label="Status" outlined></v-autocomplete>
              </div>
            </v-col>
            <v-col cols="6">
              <div class="form-group pad-all">
                <v-autocomplete v-model="itinerary.itinerary_template_id" :items="get_itinerary_template"
                  class="form-control" item-value="id" item-text="name" label="Template" outlined></v-autocomplete>
              </div>
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="itinerary.travellers_number" label="Initial number of travellers" class="pad-all"
                outlined></v-text-field>
            </v-col>
            <v-col cols="12">
              <h4>Comments (will appear on itinerary)</h4>
              <wysiwyg v-model="itinerary.comments" />
            </v-col>
            <v-col cols="12" class="mt-3">
              <div class="text-right">
                <v-btn rounded color="#68a74f" dark @click="updateItinerary()" style="font-size:16px;font-weight:600;">
                  Update
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col cols="6">
        <div class="card" v-if="action == 'UpdateItinerary'">
          <v-row no-gutters>
            <v-col cols="12" style="display:flex;">
              <h3>Assignees</h3>
              <v-spacer></v-spacer>
              <v-btn rounded dark @click="addContributor()">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12">
              <div class="traveller-wrapper" v-for="(contributor, index) in itinerary.contributor_info"
                :key="contributor.id">
                <v-col cols="12" class="traveller-content">
                  <div class="traveller">
                    <p v-if="contributor.user">
                      {{ contributor.user.name }}
                      <br>
                      {{ contributor.user.email }}
                    </p>
                  </div>
                  <div class="traveller-action">
                    <v-btn color="error" class="btnnote" icon @click="openRemoveContributorDialog(index, contributor)">
                      <v-icon dark>mdi-delete</v-icon>
                    </v-btn>
                  </div>
                </v-col>
              </div>
            </v-col>
          </v-row>
        </div>
        <div class="card" v-if="action == 'UpdateItinerary'">
          <v-row no-gutters>
            <v-col cols="12" style="display:flex;">
              <h3>Travellers</h3>
              <v-spacer></v-spacer>
              <v-menu transition="slide-y-transition" bottom>
                <template v-slot:activator="{ on, attrs }" class="text-right">
                  <v-btn rounded dark v-bind="attrs" v-on="on">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="addExistingTraveller('Existing')">
                    <v-list-item-title>Add existing contact</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="openTravellerDialog('New')">
                    <v-list-item-title>Create new</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
            <v-col cols="12">
              <div class="traveller-wrapper" v-for="(traveller, travellerindex) in itinerary.traveller_info"
                :key="travellerindex">
                <v-col cols="12" class="traveller-content">
                  <div class="traveller">
                    <p v-if="traveller.contact">
                      {{ traveller.contact.first_name }} {{ traveller.contact.last_name }}
                      <br>
                      {{ traveller.contact.email }}
                    </p>
                    <p v-else>
                      {{ JSON.parse(traveller.travellers_info).firstname }} {{
                        JSON.parse(traveller.travellers_info).lastname }}
                      <br>
                      {{ JSON.parse(traveller.travellers_info).email }}
                    </p>
                  </div>
                  <div class="traveller-action">
                    <v-btn v-if="!traveller.contact" color="error" class="btnnote" icon
                      @click="editTraveller('Edit', traveller)">
                      <v-icon dark>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn color="error" class="btnnote" icon
                      @click="openDeleteTravellerDialog(travellerindex, traveller)">
                      <v-icon dark>mdi-delete</v-icon>
                    </v-btn>
                  </div>
                </v-col>
              </div>
            </v-col>
          </v-row>
        </div>
        <div class="card" v-if="action == 'UpdateItinerary'">
          <v-row no-gutters>
            <v-col cols="12" style="display:flex;">
              <h3>Notes</h3>
              <v-spacer></v-spacer>
              <v-btn rounded dark @click="addNote()">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12">
              <div class="note-wrapper" v-for="(notes, index) in itinerary.notes" :key="notes.id">
                <v-col cols="12" class="note-content">
                  <div class="note">
                    <div v-html="notes.note"></div>
                    <small>
                      {{ notes.created_info.name }} -
                      {{ notes.created_info.created_at }}
                    </small>
                  </div>
                  <div class="note-action">
                    <v-btn color="error" class="btnnote" icon @click="editNote(index, notes)">
                      <v-icon dark> mdi-pencil </v-icon>
                    </v-btn>
                    <v-btn color="error" class="btnnote" icon @click="openDeleteNoteDialog(index, notes.id)">
                      <v-icon dark> mdi-delete </v-icon>
                    </v-btn>
                  </div>
                </v-col>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <v-row class="mr-5" v-if="itinerary.id || action != 'UpdateItinerary'">
      <v-col cols="12" v-if="action == 'UpdateItinerary'">
        <div class="text-right">
          <v-btn rounded color="error" @click="dialog_delete_itinerary = true"
            style="font-size:16px;font-weight:600;"><v-icon>mdi-delete</v-icon>Delete
            Itinerary</v-btn>
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog_note" persistent max-width="570px" overlay-color="#f4f4f5" overlay-opacity="1">
      <v-btn fab @click="dialog_note = false" class="dialog-close">
        <v-icon dark> mdi-arrow-left </v-icon>
      </v-btn>
      <v-card>
        <v-card-title>
          <span v-if="dialog_note_action == 'New'">Add note</span>
          <span v-if="dialog_note_action == 'Update'">Edit note</span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <wysiwyg v-model="note_payload.note" label="Start typing *" class="mt-5 pr-2 pad-all" outlined />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="dialog_note_action == 'New'" color="green darken-1" text @click="saveNote()">
            Save note
          </v-btn>
          <v-btn v-if="dialog_note_action == 'Update'" color="green darken-1" text @click="updateNote()">
            Update note
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_contributor" persistent max-width="570px" overlay-color="#f4f4f5" overlay-opacity="1">
      <v-btn fab @click="dialog_contributor = false" class="dialog-close">
        <v-icon dark>mdi-arrow-left</v-icon>
      </v-btn>
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <v-autocomplete v-model="contributor_payload.user_id" :items="get_itinerary_contributors" class="form-control"
            item-value="data.item" label="Search *" dense outlined>
            <template v-slot:selection="data">
              {{ data.item.name }}
            </template>
            <template v-slot:item="data">
              <template>
                <v-list-item-avatar>
                  <v-chip class="data-chip">{{ data.item.name.charAt(0).toUpperCase() }}</v-chip>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                  <v-list-item-subtitle>{{ data.item.email }}</v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="saveContributor()">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_traveller" persistent max-width="570px" overlay-color="#f4f4f5" overlay-opacity="1">
      <v-btn fab @click="dialog_traveller = false" class="dialog-close">
        <v-icon dark> mdi-arrow-left </v-icon>
      </v-btn>
      <v-card>
        <v-card-title>
          <span v-if="dialog_traveller_action == 'New'">Add traveller</span>
        </v-card-title>
        <v-card-text v-if="dialog_traveller_action == 'Existing'">
          <v-autocomplete v-model="traveller_payload.contact_id" :items="get_contact_info" class="form-control"
            item-value="data.item" label="Search *" dense outlined>
            <template v-slot:selection="data">
              {{ data.item.first_name }} {{ data.item.last_name }}
            </template>
            <template v-slot:item="data">
              <template>
                <v-list-item-avatar>
                  <v-chip class="data-chip">{{ data.item.first_name.charAt(0).toUpperCase() }}</v-chip>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ data.item.first_name }} {{ data.item.last_name }}</v-list-item-title>
                  <v-list-item-subtitle>{{ data.item.email }}</v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-card-text>
        <v-card-text v-if="dialog_traveller_action == 'New' || dialog_traveller_action == 'Edit'">
          <v-text-field v-model="traveller_payload.firstname" label="Firstname*" outlined></v-text-field>
          <v-text-field v-model="traveller_payload.lastname" label="Lastname*" outlined></v-text-field>
          <v-text-field v-model="traveller_payload.email" label="Email" outlined></v-text-field>
          <v-switch v-if="dialog_traveller_action == 'New'" v-model="concent" label="Save to contacts library"
            style="margin-top: -10px;" @click="checkConsent()"></v-switch>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="dialog_traveller_action == 'New' || dialog_traveller_action == 'Edit'" color="green darken-1"
            text @click="saveTraveller()">
            Save
          </v-btn>
          <v-btn v-if="dialog_traveller_action == 'Existing'" color="green darken-1" text @click="addExistingContact()">
            Add contact
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_add_contact_consent" max-width="600px">
      <v-card>
        <v-card-title>
          <span>You're about to save personal data</span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          In order to save you must confirm that you have a legitimate interest, contractual basis or consent to store
          this personal data.
          <v-checkbox v-model="traveller_payload.concent"
            label="I have a legitimate interest and/or consent to store this information"></v-checkbox>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="closeAddContactDialog()">
            Cancel
          </v-btn>
          <v-btn color="green darken-1" text :disabled="!traveller_payload.concent"
            @click="dialog_add_contact_consent = false">
            Accept
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_delete_itinerary" persistent max-width="570px" overlay-color="#f4f4f5"
      overlay-opacity="1">
      <v-btn fab @click="dialog_delete_itinerary = false" class="dialog-close">
        <v-icon dark> mdi-arrow-left </v-icon>
      </v-btn>
      <v-card>
        <v-card-title>
          Are you sure you want to delete this itinerary?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog_delete_itinerary = false">
            Cancel
          </v-btn>
          <v-btn text color="error" @click="deleteItinerary()">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="remove_notes_dialog" persistent max-width="570px" overlay-color="#f4f4f5" overlay-opacity="1">
      <v-btn fab @click="remove_notes_dialog = false" class="dialog-close">
        <v-icon dark> mdi-arrow-left </v-icon>
      </v-btn>
      <v-card>
        <v-card-title>
          Are you sure you want to delete this note?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="remove_notes_dialog = false">
            Cancel
          </v-btn>
          <v-btn text color="error" @click="removeNote(remove_note_index, remove_note_id)">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="delete_traveller_dialog" persistent max-width="570px" overlay-color="#f4f4f5"
      overlay-opacity="1">
      <v-btn fab @click="delete_traveller_dialog = false" class="dialog-close">
        <v-icon dark> mdi-arrow-left </v-icon>
      </v-btn>
      <v-card>
        <v-card-title>
          Are you sure you want to delete this traveller?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeDeleteTravellerDialog()">
            Cancel
          </v-btn>
          <v-btn text color="error" @click="deleteTraveller()">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="remove_contributor_dialog" persistent max-width="570px" overlay-color="#f4f4f5"
      overlay-opacity="1">
      <v-btn fab @click="remove_contributor_dialog = false" class="dialog-close">
        <v-icon dark> mdi-arrow-left </v-icon>
      </v-btn>
      <v-card>
        <v-card-title>
          Are you sure you want to remove this contributor?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeRemoveContributorDialog()">
            Cancel
          </v-btn>
          <v-btn text color="error" @click="removeContributor()">
            Remove
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Setup',
  components: {
  },
  props: [
    'action', 'id', 'itinerary'
  ],
  data: () => ({
    creating: false,
    date_changed: false,
    menu1: false,
    menu2: false,
    dialog_note: false,
    dialog_note_action: 'New',
    dialog_agency: false,
    dialog_contributor: false,
    dialog_traveller: false,
    dialog_traveller_action: 'New',
    dialog_add_contact_consent: false,
    dialog_delete_itinerary: false,
    concent: false,
    tag_name: '',
    location_name: '',
    agency_payload: {
      itinerary_id: 0,
      agency_info: null
    },
    note_payload: {
      index: 0,
      id: 0,
      itinerary_id: 0,
      note: '',
      created_by: 0,
    },
    contributor_payload: {
      id: 0,
      itinerary_id: 0,
      user_id: 0,
    },
    traveller_payload: {
      id: 0,
      company_id: 0,
      itinerary_id: 0,
      contact_id: 0,
      firstname: '',
      lastname: '',
      email: '',
      concent: false
    },
    remove_notes_dialog: false,
    remove_note_index: null,
    remove_note_id: null,
    delete_traveller_dialog: false,
    delete_traveller_index: null,
    traveller_to_delete: null,
    remove_contributor_dialog: false,
    remove_contributor_index: null,
    contributor_to_remove: null,
  }),
  async mounted() {
  },
  created() {
  },
  computed: {
    ...mapGetters({
      get_selected_company: 'auth/get_selected_company',
      get_companies: 'auth/get_companies',
      get_map_type: 'itinerary/get_map_type',
      get_user: 'auth/get_user',
      get_itinerary_template: 'itinerary/get_itinerary_template',
      get_itinerary_status: 'itinerary/get_itinerary_status',
      get_contact_info: 'itinerary/get_contact_info',
      get_itinerary_contributors: 'itinerary/get_itinerary_contributors'
    }),
  },
  methods: {
    async createItinerary() {
      if (this.$date(this.itinerary.end_date).isBefore(this.$date(this.itinerary.start_date)) || this.$date(this.itinerary.end_date).isSame(this.$date(this.itinerary.start_date))) {
        this.$toast.error('End date must be greater than the start date')
        return
      }
      this.creating = true
      let payload = {
        ...this.itinerary,
        company_id: this.get_selected_company.id,
        created_by: this.get_user.id,
      }
      await this.$axios.post('v2/itineraries/create_itinerary', payload)
        .then(({ data }) => {
          this.creating = false
          if (data.response) {
            this.$toast.success(data.message)
            this.$router.push({ name: 'UpdateItinerary', params: { id: data.data.id } })
            window.location.reload()
          }
          else {
            this.$toast.error(data.message)
          }
        })
    },
    updateItinerary() {
      let payload = {
        ...this.itinerary,
      }
      this.$axios.post(`v2/itineraries/update_itinerary/${this.itinerary.id}`, payload)
        .then(({ data }) => {
          if (data.response) {
            this.$toast.success(data.message)
            if (this.date_changed) {
              this.updateDates()
            }
          } else {
            this.$toast.error(data.message)
          }
        })
    },
    updateDates() {
      if (this.$date(this.itinerary.end_date).isBefore(this.$date(this.itinerary.start_date)) || this.$date(this.itinerary.end_date).isSame(this.$date(this.itinerary.start_date))) {
        this.$toast.error('End date must be greater than the start date')
        return
      }
      let payload = {
        id: this.itinerary.id,
        start_date: this.itinerary.start_date,
        end_date: this.itinerary.end_date
      }
      this.$axios.post('itinerary/update_itinerary_dates', payload)
        .then(({ data }) => {
          if (data.response) {
            this.$toast.success(data.message)
            window.location.reload()
          }
        })
    },
    deleteItinerary() {
      this.$axios.delete(`v2/itineraries/delete_itinerary/${this.itinerary.id}`)
        .then(({ data }) => {
          if (data.response) {
            this.$toast.success(data.message)
            this.dialog_delete_itinerary = false
            this.$router.push('/itinerary')
          }
        })
    },
    addNote() {
      this.clearNote()
      this.dialog_note_action = 'New'
      this.dialog_note = true
      this.note_payload.itinerary_id = this.itinerary.id
      this.note_payload.note = ''
      this.note_payload.created_by = this.get_user.id
    },
    saveNote() {
      this.$axios.post('itinerary/add_itinerary_note', this.note_payload)
        .then(({ data }) => {
          if (data.response) {
            this.dialog_note = false
            this.$emit('refreshItinerary')
          }
        })
    },
    openDeleteNoteDialog(index, id) {
      this.remove_notes_dialog = true
      this.remove_note_index = index
      this.remove_note_id = id
    },
    removeNote(index, id) {
      this.$axios.post('itinerary/remove_itinerary_note', { id: id })
        .then(({ data }) => {
          if (data.response) {
            this.itinerary.notes.splice(index, 1)
            this.remove_notes_dialog = false
            this.remove_note_index = null
            this.remove_note_id = null
          }
        })
    },
    editNote(index, data) {
      this.clearNote()
      this.dialog_note_action = 'Update'
      this.dialog_note = true
      this.note_payload.index = index
      this.note_payload.id = data.id
      this.note_payload.note = data.note
    },
    updateNote() {
      this.$axios.post('itinerary/update_itinerary_note', this.note_payload)
        .then(({ data }) => {
          if (data.response) {
            let info = this.itinerary.notes.find(
              (element) => element.id == this.note_payload.id
            )
            info.note = this.note_payload.note
            this.dialog_note = false
          }
        })
    },
    clearNote() {
      this.note_payload.index = 0
      this.note_payload.id = 0
      this.note_payload.itinerary_id = 0
      this.note_payload.note = ''
      this.note_payload.created_by = 0
    },
    addContributor() {
      this.dialog_contributor = true
      this.contributor_payload.itinerary_id = this.itinerary.id
      this.$store.dispatch('itinerary/fetch_itinerary_contributors', this.contributor_payload)
    },
    saveContributor() {
      let info = {
        itinerary_id: this.contributor_payload.itinerary_id,
        user_id: this.contributor_payload.user_id.id
      }
      this.$axios.post('itinerary/add_itinerary_contributor', info)
        .then(({ data }) => {
          if (data.response) {
            this.itinerary.contributor_info.push(data.data)
            this.contributor_payload.id = 0
            this.contributor_payload.itinerary_id = 0
            this.contributor_payload.user_id = 0
            this.dialog_contributor = false
          }
        })
    },
    openRemoveContributorDialog(index, item) {
      this.remove_contributor_dialog = true
      this.remove_contributor_index = index
      this.contributor_to_remove = item
    },
    closeRemoveContributorDialog() {
      this.remove_contributor_dialog = false
      this.remove_contributor_index = null
      this.contributor_to_remove = null
    },
    removeContributor() {
      this.$axios.post('itinerary/remove_itinerary_contributor', { id: this.contributor_to_remove.id })
        .then(({ data }) => {
          if (data.response) {
            this.itinerary.contributor_info.splice(this.remove_contributor_index, 1)
            this.closeRemoveContributorDialog()
          }
        })
    },
    openTravellerDialog(action) {
      this.dialog_traveller = true
      this.traveller_payload.company_id = this.get_selected_company.id
      this.traveller_payload.itinerary_id = this.itinerary.id
      this.traveller_payload.contact_id = 0
      this.dialog_traveller_action = action
    },
    closeAddContactDialog() {
      this.dialog_add_contact_consent = false
      this.traveller_payload.concent = false
      this.concent = false
    },
    checkConsent() {
      if (this.concent) this.dialog_add_contact_consent = true
      this.traveller_payload.concent = false
    },
    saveTraveller() {
      if (this.dialog_traveller_action == 'New') {
        this.$axios.post('itinerary/add_itinerary_traveller', this.traveller_payload)
          .then(({ data }) => {
            if (data.response) {
              this.traveller_payload.contact_id = 0
              this.traveller_payload.firstname = ''
              this.traveller_payload.lastname = ''
              this.traveller_payload.email = ''
              this.itinerary.traveller_info.push(data.data)
              this.dialog_traveller = false
            }
          })
      }
      if (this.dialog_traveller_action == 'Edit') {
        this.$axios.post('itinerary/update_itinerary_traveller', this.traveller_payload)
          .then(({ data }) => {
            if (data.response) {
              this.traveller_payload.id = 0
              this.traveller_payload.firstname = ''
              this.traveller_payload.lastname = ''
              this.traveller_payload.email = ''
              this.dialog_traveller = false
              this.$emit('refreshItinerary')
            }
          })
      }
    },
    openDeleteTravellerDialog(index, traveller) {
      this.delete_traveller_dialog = true
      this.delete_traveller_index = index
      this.traveller_to_delete = traveller
    },
    closeDeleteTravellerDialog() {
      this.delete_traveller_dialog = false
      this.delete_traveller_index = null
      this.traveller_to_delete = null
    },
    deleteTraveller() {
      this.$axios.post('itinerary/remove_itinerary_traveller', { id: this.traveller_to_delete.id })
        .then(({ data }) => {
          if (data.response) {
            this.itinerary.traveller_info.splice(this.delete_traveller_index, 1)
            this.closeDeleteTravellerDialog()
          }
        })
    },
    addExistingTraveller(action) {
      this.dialog_traveller = true
      this.traveller_payload.company_id = this.get_selected_company.id
      this.traveller_payload.itinerary_id = this.itinerary.id
      this.traveller_payload.contact_id = 0
      this.dialog_traveller_action = action
      this.$store.dispatch('itinerary/fetch_contact_info', this.traveller_payload)
    },
    addExistingContact() {
      let info = {
        company_id: this.traveller_payload.company_id,
        itinerary_id: this.traveller_payload.itinerary_id,
        contact_id: this.traveller_payload.contact_id.id
      }
      this.$axios.post('itinerary/add_itinerary_extraveller', info)
        .then(({ data }) => {
          if (data.response) {
            this.itinerary.traveller_info.push(data.data)
            this.dialog_traveller = false
          }
        })
    },
    editTraveller(action, data) {
      this.dialog_traveller = true
      this.traveller_payload.id = data.id
      this.traveller_payload.firstname = JSON.parse(data.travellers_info).firstname
      this.traveller_payload.lastname = JSON.parse(data.travellers_info).lastname
      this.traveller_payload.email = JSON.parse(data.travellers_info).email
      this.dialog_traveller_action = action
    }
  },
  watch: {},
}
</script>

<style scoped>
.card {
  max-width: 100%;
  background-color: white;
  margin: 25px 15px;
  padding: 25px 30px;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0px rgb(0 0 0 / 20%);
}

.pad-all {
  padding: 5px;
}

.form-group {
  margin-top: -10px;
}

.form-group h3 {
  padding-bottom: 10px;
  font-size: 12px;
}

.form-group .form-control {
  margin-top: 5px;
}

.margin-top-zero {
  margin-top: -25px;
  margin-left: 5px;
  margin-bottom: 10px;
}

.tags-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-left: 4px;
  gap: 1rem;
}

.tags-wrapper .tags {
  max-height: 50px;
  display: flex;
  background-color: #dcdee0;
}

.tags-wrapper .tags .tag-name {
  margin: auto 0;
  padding: 12px;
  color: #343642;
  font-weight: bold;
  font-size: 16px;
  background: rgb(237, 238, 239);
}

.tags-wrapper .tags .tag-action {
  margin: auto 0;
}

.dialog-close {
  margin-left: -75px;
  position: absolute;
}

.note-wrapper .note-content {
  display: flex;
}

.note-wrapper .note-content .note {
  width: 100%;
}

.note-wrapper .note-content .note-action {
  margin: auto 0px;
  width: 20%;
  text-align: right;
}

.agency-wrapper {
  display: flex;
  width: 100%;
}

.agency-wrapper .agency-info {
  display: flex;
  width: 100%;
  border: 1px solid #c0c7cd;
  border-radius: 5px;
  justify-content: space-between;
}

.agency-wrapper .agency-info .agency-content {
  width: 50%;
  border-right: 1px solid #c0c7cd;
  padding: 20px;
}

.agency-wrapper .agency-info .agency-connection {
  width: 50%;
  padding: 20px;
}

.agency-wrapper .agency-action {
  width: 20%;
  padding: 20px;
}

.agency-wrapper .agency-action .agency-action-btn {
  margin-top: 9px;
}

.assignees-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.assignees-wrapper .assignees {
  width: 100%;
  border-radius: 10px;
  display: flex;
}

.assignees-wrapper .assignees .assignees-avatar {
  background-color: #343642;
  color: white;
  font-size: 25px;
}

.assignees-wrapper .assignees .assignees-initial {
  display: flex;
  flex-direction: column;
  margin-top: 6px;
  margin-bottom: 6px;
  margin-left: 10px;
  margin-right: 12px;
}

.assignees-wrapper .assignees .assignees-initial p {
  margin: 0px;
  font-size: 18px;
}

.assignees-wrapper .assignees .assignees-initial p::first-letter {
  font-weight: bolder;
}

.assignees-wrapper .assignees .assignees-initial span {
  font-size: 10px;
}

.assignees-wrapper .assignees .assignees-action {
  margin: 0 auto;
  text-align: right;
}

.data-chip {
  background-color: rgb(56, 154, 116) !important;
  font-weight: bolder;
  font-size: 14px;
  color: white;
  margin-right: 5px;
}

.traveller-wrapper .traveller-content {
  display: flex;
}

.traveller-wrapper .traveller-content .traveller {
  width: 100%;
}

.traveller-wrapper .traveller-content .traveller p {
  margin: auto 0px;
}

.traveller-wrapper .traveller-content .traveller-action {
  margin: auto 0px;
  width: 20%;
  text-align: right;
}

@media only screen and (max-width: 959px) {}
</style>
