<template>
  <div class="card">
    <v-row no-gutters>
      <v-col cols="12" md="6">
        <div class="card-header">Media library</div>
      </v-col>
      <v-col cols="12" md="6">
        <div class="card-header-items">
          <div class="media-button">
            <input
              type="file"
              ref="files"
              class="input-media"
              accept="image/png, image/gif, image/jpeg"
              v-on:change="addMedia($event)"
              multiple
            />
            <v-btn rounded color="#525a68" dark @click="$refs.files.click()">
              Upload Media
            </v-btn>
          </div>
        </div>
      </v-col>
      <v-col cols="12">
        <v-text-field v-model="media_search" label="Search"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-for="(media, index) in get_media_info"
        :key="media.id"
        class="d-flex child-flex"
        cols="2"
      >
        <v-img
          :src="media.url"
          :lazy-src="media.url"
          aspect-ratio="1"
          class="grey lighten-2 media-image"
          @click="OpenDialog(media, index)"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialog"
      persistent
      max-width="570px"
      overlay-color="#f4f4f5"
      overlay-opacity="1"
    >
      <v-btn fab @click="dialog = false" class="dialog-close">
        <v-icon dark> mdi-arrow-left </v-icon>
      </v-btn>
      <v-card>
        <v-card-title> </v-card-title>
        <v-card-text v-for="(item, index) in payload" :key="item.id">
          <v-container>
            <v-row>
              <v-col cols="12">
                <div class="image-show-wrapper">
                  <v-img
                    :src="item.url"
                    :lazy-src="item.url"
                    aspect-ratio="1"
                    class="grey lighten-2 preview-image image-show"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <div class="image-show-name">
                    <span>{{ item.name }}</span>
                  </div>
                </div>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="item.original_name"
                  label="Name"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="item.tag_name"
                  label="Tags"
                  dense
                  outlined
                  v-on:keyup.enter="AddTag(item)"
                ></v-text-field>
                <ErrMessage
                  :message="validation.tags.message"
                  :show="validation.tags.show"
                  :success="validation.tags.success"
                />
              </v-col>
              <v-col cols="12">
                <div class="tags-wrapper">
                  <div
                    class="tags"
                    v-for="(tag, index) in item.tags"
                    :key="tag.id"
                  >
                    <div class="tag-name">
                      {{ tag }}
                    </div>
                    <div class="tag-action">
                      <v-btn color="error" icon @click="RemoveTag(index)">
                        <v-icon dark> mdi-delete </v-icon>
                      </v-btn>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12">
                <div class="action-button">
                  <v-btn color="error" fab @click="RemoveMedia(item, index)">
                    <v-icon dark> mdi-delete </v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ErrMessage from "../../errmessage/ErrMessage.vue";

export default {
  components: {
    ErrMessage,
  },
  data: () => ({
    dialog: false,
    payload: [],
    // payload: {
    //     index: Number,
    //     id: Number,
    //     name: String,
    //     original_name: String,
    //     url: String,
    //     tags: []
    // },
    validation: {
      tags: {
        message: "Tags make it easier to find and categorise images",
        show: true,
        success: true,
      },
    },
    media_search: "",
  }),
  mounted() {},
  created() {},
  computed: {
    ...mapGetters({
      get_selected_company: "auth/get_selected_company",
    }),
    get_media_info() {
      return this.$store.getters["resources/get_media_info"](this.media_search);
    },
  },
  methods: {
    OpenDialog(data, index) {
      this.payload = [];
      this.payload.push({
        index: index,
        id: data.id,
        name: data.name,
        original_name: data.original_name,
        url: data.url,
        tags: data.tags == null ? [] : data.tags,
        tag_name: "",
      });
      this.dialog = true;
      console.log(this.payload);
    },
    RemoveTag(index) {
      this.payload.tags.splice(index, 1);
      let media = this.get_media_info.find(
        (element) => element.id == this.payload.id
      );
      media.tags = this.payload.tags;
      this.$axios
        .post("resources/update_media_tags", this.payload)
        .then(({ data }) => {
          console.log(data);
        });
    },
    AddTag(item) {
      this.ValidateTags(item.tags, item.tag_name).then((response) => {
        if (!response) {
          let media = this.get_media_info.find(
            (element) => element.id == item.id
          );
          item.tags.push(item.tag_name);
          media.tags = item.tags;
          item.tag_name = "";
          this.$axios
            .post("resources/update_media_tags", item)
            .then(({ data }) => {
              console.log(data);
            });
        }
      });
    },
    ValidateTags(tags, tag_name) {
      const promise = new Promise(function (resolve) {
        tags.forEach((element) => {
          if (element.toUpperCase() == tag_name.toUpperCase()) {
            resolve(true);
          }
        });
        resolve(false);
      });
      return promise;
    },
    async RemoveMedia(item, index) {
      this.payload.splice(index, 1);
      await this.$axios
        .post("resources/remove_media", item)
        .then(({ data }) => {
          if (data.response) {
            this.$store.dispatch(
              "resources/fetch_media_info",
              this.get_selected_company.id
            );
            this.$store.dispatch(
              "resources/fetch_resources_info",
              this.get_selected_company.id
            );
            if (this.payload.length == 0) {
              this.dialog = false;
            }
          }
        });
    },
    async addMedia(event) {
      if (event.target.files.length != 0) {
        let counter = 0;
        let formdata = new FormData();
        formdata.append("company_id", this.get_selected_company.id);
        for (let img of event.target.files) {
          formdata.append("media" + counter, img);
          counter++;
        }
        formdata.append("counter", counter);
        await this.$axios
          .post("resources/add_media", formdata)
          .then(({ data }) => {
            if (data.response) {
              this.$store.dispatch(
                "resources/fetch_media_info",
                this.get_selected_company.id
              );
              this.payload = [];
              data.data.forEach((value, index) => {
                this.payload.push({
                  index: index,
                  id: value.id,
                  name: value.name,
                  original_name: value.original_name,
                  url: value.url,
                  tags: value.tags == null ? [] : data.tags,
                  tag_name: "",
                });
              });
              this.dialog = true;
            }
          });
      }
    },
  },
  watch: {},
};
</script>

<style scoped>
.card {
  max-width: 100%;
  background-color: white;
  margin: 25px 15px;
  padding: 25px 30px;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0px rgb(0 0 0 / 20%);
}
.card .card-header {
  font-size: 24px;
  color: #343642;
  margin-bottom: 15px;
}
.card .card-header-items {
  text-align: center;
}
.card .card-header-items .header-items {
  margin: 0px 0px;
}
.media-image:hover {
  cursor: pointer;
  box-shadow: 0px 1px 5px 5px rgba(169, 169, 169, 0.6);
}
.image-show-wrapper {
  width: 100%;
}
.image-show-wrapper .image-show {
  max-width: 50%;
  margin: 0 auto;
}
.image-show-wrapper .image-show-name {
  font-size: 10px;
  text-align: center;
}
.tags-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.tags-wrapper .tags {
  max-height: 50px;
  display: flex;
  background-color: #dcdee0;
}
.tags-wrapper .tags .tag-name {
  margin: auto 0;
  padding: 12px;
  color: #343642;
  font-weight: bold;
  font-size: 16px;
  background: rgb(237, 238, 239);
}
.tags-wrapper .tags .tag-action {
  margin: auto 0;
}
.media-button {
  text-align: center;
}
.media-button .input-media {
  /* color: rgb(82, 90, 104); */
  display: none;
}
.dialog-close {
  margin-left: -75px;
  position: absolute;
}
@media only screen and (max-width: 959px) {
  .card .card-header {
    text-align: center;
  }
  .card .card-header-items .header-items {
    margin: 5px 5px 10px 5px;
  }
}
</style>
