<template>
    <v-container fluid class="ma-0 pa-0">
        <v-row class="mt-5" justify="center" align="center">
            <v-card elevation="0">
                <v-card-text style="text-align:center;">
                    <div>Please enter your reference number below.</div>
                    <br>
                    <v-text-field label="Your Reference Number" outlined
                        v-model="booking_reference_number"></v-text-field>
                    <v-btn @click="submitReferenceNumber()" dark v-if="status_id == 3">Submit & Confirm Booking</v-btn>
                    <v-btn @click="submitReferenceNumber()" dark v-if="status_id == 4">Submit & Cancel Booking</v-btn>
                    <v-btn @click="submitReferenceNumber()" dark v-if="status_id == 7">Submit & Reject Booking</v-btn>
                </v-card-text>
                <!-- <v-card-text>
                    <label style="font-weight: 100; font-size: medium;">
                        Thanks for your response. You can close this window now.
                    </label>
                </v-card-text> -->
            </v-card>
        </v-row>
    </v-container>
</template>

<script>
export default {
    components: {
    },
    props: [
    ],
    data() {
        return {
            booking_reference_number: null,
            booking_id: null,
            status_id: 0,
        }
    },
    mounted() {
        this.verifyToken()
    },
    created() {
    },
    computed: {
    },
    methods: {
        async submitReferenceNumber() {
            if (this.booking_reference_number == null) {
                this.$toast.error('Please enter a reference number')
                return
            }
            let payload = {
                id: this.booking_id,
                booking_reference_number: this.booking_reference_number,
                status_id: this.status_id,
            }
            await this.$axios.post(`/email/update_booking/${this.booking_id}`, payload)
                .then(({ data }) => {
                    console.log(data)
                    this.$toast.success('Booking status updated successfully')
                })
        },
        async verifyToken() {
            if (this.$router.currentRoute.name == '/accept') {
                let tp = {
                    token: this.$router.currentRoute.params.token
                }
                this.status_id = 3
                await this.$axios.get('/email/decrypt_booking_id', tp)
                    .then(({ data }) => {
                        console.log(data)
                        if (data.response) {
                            this.booking_id = data.data
                        }
                    })
            }
            if (this.$router.currentRoute.name == '/accept_manual') {
                this.status_id = 3
                this.booking_id = this.$router.currentRoute.params.token
            }
            if (this.$router.currentRoute.name == '/cancel') {
                let tp = {
                    token: this.$router.currentRoute.params.token
                }
                this.status_id = 4
                await this.$axios.get('/email/decrypt_booking_id', tp)
                    .then(({ data }) => {
                        console.log(data)
                        if (data.response) {
                            this.booking_id = data.data
                        }
                    })
            }
            if (this.$router.currentRoute.name == '/reject') {
                let tp = {
                    token: this.$router.currentRoute.params.token
                }
                this.status_id = 7
                await this.$axios.get('/email/decrypt_booking_id', tp)
                    .then(({ data }) => {
                        console.log(data)
                        if (data.response) {
                            this.booking_id = data.data
                        }
                    })
            }
        }
    },
    watch: {
    }
}
</script>

<style scoped lang="scss"></style>