import Itinerary from '../Itinerary.vue'
import Initial from '../components/initial/Initial.vue'
import ViewItinerary from '../components/ViewItinerary.vue'

export default [
    {
        path: '/itinerary',
        name: '/itinerary',
        component: Itinerary,
    },
    {
        path: '/itinerary/update/:id',
        name: 'UpdateItinerary',
        component: Initial,
    },
    {
        path: '/itinerary/create',
        name: 'CreateItinerary',
        component: Initial,
    },
    {
        path: '/view_itinerary/:id',
        name: 'View Itinerary',
        component: ViewItinerary,
        meta: { layout: 'blank' }
    },
]