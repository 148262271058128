import { render, staticRenderFns } from "./CreateSupplier.vue?vue&type=template&id=486795c7&scoped=true&"
import script from "./CreateSupplier.vue?vue&type=script&lang=js&"
export * from "./CreateSupplier.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "486795c7",
  null
  
)

export default component.exports