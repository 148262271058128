<template>
    <v-row justify="center">
        <v-dialog :value="value" persistent max-width="85%">
            <v-card>
                <v-card-title class="text-h5">
                    Add Service
                    <v-spacer />
                    <v-icon style="cursor:pointer;" @click="closeDialog()">
                        mdi-close
                    </v-icon>
                </v-card-title>
                <v-card-subtitle>
                    {{ get_day_selected.date }}
                </v-card-subtitle>
                <v-divider class="mb-5"></v-divider>
                <v-card-text>
                    <v-row>
                        <v-col cols="3">
                            <v-autocomplete label="Town" prepend-inner-icon="mdi-view-list-outline" :items="towns"
                                v-model="town" @change="search_supplier" />
                        </v-col>
                        <v-col cols="3">
                            <v-autocomplete label="County" prepend-inner-icon="mdi-view-list-outline" :items="counties"
                                v-model="county" @change="search_supplier" />
                        </v-col>
                        <v-col cols="3">
                            <v-autocomplete label="Province" prepend-inner-icon="mdi-view-list-outline"
                                :items="provinces" v-model="province" @change="search_supplier" />
                        </v-col>
                        <v-col cols="3">
                            <v-autocomplete label="Country" prepend-inner-icon="mdi-view-list-outline"
                                :items="countries" v-model="country" @change="search_supplier" />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-text>
                    <v-row>
                        <v-col cols="6">
                            <v-autocomplete label="Supplier Type" prepend-inner-icon="mdi-view-list-outline"
                                :items="supplier_types" item-title="name" item-value="value" v-model="supplier_type"
                                @change="search_supplier" />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Supplier Name" prepend-inner-icon="mdi-account"
                                v-model="s.supplier_name" v-on:keyup.enter="search_supplier">
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-text v-if="is_loading" class="text-center">
                    <strong>Searching...</strong>
                    <br />
                    <v-progress-circular :size="120" :width="10" color="primary" indeterminate></v-progress-circular>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn outlined class="pa-5" rounded @click="clear_search">
                        Clear Search
                    </v-btn>
                    <v-btn color="primary" class="pa-5" rounded @click="search_supplier">
                        Search
                    </v-btn>
                </v-card-actions>
                <v-divider />
                <v-card-text class="mt-5" v-if="!is_loading">
                    <v-data-table :headers="headers" :items="suppliers" :expanded.sync="expanded" single-expand
                        :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
                        <template v-slot:item="{ item, expand, isExpanded }">
                            <tr v-if="item.is_archived == 0">
                                <td>
                                    <v-card-actions>
                                        <v-list-item>
                                            <v-list-item-avatar>
                                                <v-icon>mdi-account</v-icon>
                                            </v-list-item-avatar>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ item.supplier_info.supplier_name
                                                    }}</v-list-item-title>
                                                <v-list-item-subtitle>
                                                    - {{ item.name }}
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-card-actions>
                                </td>
                                <td>{{ item.supplier_info.town }}</td>
                                <td>{{ item.supplier_info.county }}</td>
                                <td>{{ item.supplier_info.province }}</td>
                                <td>{{ item.supplier_info.country }}</td>
                                <td>
                                    <v-btn @click="expand(!isExpanded)" rounded color="info" class="pl-5 pr-5">
                                        <v-icon class="mr-2">mdi-eye</v-icon>Show rates
                                    </v-btn>
                                </td>
                                <td><label>{{ item.supplierproducttype.name }}</label></td>
                                <td>
                                    <v-btn fab elevation="0" color="primary" small @click="selected_service(item)">
                                        <v-icon>mdi-plus</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                        </template>
                        <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headers.length">
                                <v-list>
                                    <v-list-group v-for="list in item.product_options" :key="list.name" no-action
                                        @click="get_rates(list)">
                                        <template v-slot:activator>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ list.name }}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </template>
                                        <v-data-table :headers="header_rates" :items="get_rates_vuex"
                                            :expanded.sync="expandedchild" single-expand>
                                            <template v-slot:item="{ item, expand, isExpanded }">
                                                <tr>
                                                    <td>{{ item.start_date }}</td>
                                                    <td>{{ item.end_date }}</td>
                                                    <td>
                                                        <v-btn @click="expand(!isExpanded)" rounded color="info"
                                                            class="pl-5 pr-5" small>
                                                            <v-icon class="mr-2">
                                                                mdi-eye
                                                            </v-icon>
                                                            Show rate
                                                        </v-btn>
                                                    </td>
                                                </tr>
                                            </template>
                                            <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headers.length">
                                <v-data-table :headers="header_rates_child" :items="item.supplierrates">
                                    <template v-slot:item="{ item }">
                                        <tr>
                                            <td>{{ item.day }}</td>
                                            <td>{{ item.net }}</td>
                                            <td>{{ item.gross }}</td>
                                            <td>{{ item.markup }} %</td>
                                            <td>{{ item.commission }} %</td>
                                        </tr>
                                    </template>
                                </v-data-table>
                            </td>
                        </template>
                    </v-data-table>
                    </v-list-group>
                    </v-list>
                    </td>
</template>
</v-data-table>
<br>
<br>
<h5>These suppliers do not have services/rates added to them yet.</h5>
<v-data-table :headers="inactive_headers" :items="inactive_suppliers" :sort-by.sync="inactive_sort_by"
    :sort-desc.sync="sortDesc">
    <template v-slot:item="{ item }">
        <tr>
            <td>{{ item.supplier_name }}</td>
            <td>{{ item.get_supplier_type.name }}</td>
            <td>
                <v-btn fab elevation="0" color="primary" small disabled>
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </td>
        </tr>
    </template>
</v-data-table>
</v-card-text>
</v-card>
</v-dialog>
</v-row>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    components: {
    },
    props: [
        'value'
    ],
    data() {
        return {
            is_loading: false,
            suppliers: [],
            inactive_suppliers: [],
            sortDesc: false,
            sortBy: 'supplier_info.supplier_name',
            inactive_sort_by: 'supplier_name',
            s: {
                supplier_name: null
            },
            headers: [
                {
                    text: 'Name',
                    align: 'start',
                    sortable: true,
                    value: 'supplier_info.supplier_name',
                    width: '70%'
                },
                { text: "Town", sortable: false },
                { text: "County", sortable: false },
                { text: "Province", sortable: false },
                { text: "Country", sortable: false },
                { text: '', sortable: false, },
                { text: 'Service Type', value: 'supplier_info.service_type_id', sortable: false },
                { text: '', sortable: false, },
            ],
            inactive_headers: [
                {
                    text: 'Name',
                    align: 'start',
                    sortable: true,
                    value: 'supplier_name',
                    width: '70%'
                },
                { text: 'Type', value: 'supplier_info.service_type_id', sortable: false },
                { text: '', sortable: false, },
            ],
            supplier_types: [
                { text: 'Accommodation', value: 1 },
                { text: 'Activity', value: 2 },
                { text: 'Transportation', value: 3 },
                { text: 'Other', value: 4 },
            ],
            supplier_type: null,
            town: null,
            towns: [],
            county: null,
            counties: [],
            province: null,
            provinces: [],
            country: null,
            countries: [],
            expanded: [],
            header_rates: [
                { text: 'Start Date' },
                { text: 'End Date' },
                { text: '' }
            ],
            expandedchild: [],
            header_rates_child: [
                { text: 'Day' },
                { text: 'Net' },
                { text: 'Gross' },
                { text: 'Markup' },
                { text: 'Commission' }
            ]
        }
    },
    mounted() {
        this.search_supplier()
    },
    async created() {
        this.$store.dispatch('itinerary/fetch_countries')
    },
    computed: {
        ...mapGetters({
            get_selected_company: "auth/get_selected_company",
            get_day_selected: 'itinerary/get_day_selected',
            get_countries: 'itinerary/get_countries',
            get_cities: 'itinerary/get_cities',
            get_rates_vuex: 'itinerary/get_rates'
        })
    },
    methods: {
        clear_search() {
            this.s.supplier_name = null
            this.supplier_type = null
            this.town = null
            this.county = null
            this.province = null
            this.country = null
            this.search_supplier()
        },
        async search_supplier() {
            let tp = {
                company_id: this.get_selected_company.id,
                service_type_id: this.supplier_type,
                supplier_name: this.s.supplier_name,
                town: this.town,
                county: this.county,
                province: this.province,
                country: this.country
            }
            this.is_loading = true
            this.$axios.get('itinerary/build/fetch_supplier', tp)
                .then(({ data }) => {
                    this.inactive_suppliers = []
                    this.suppliers = []
                    data.data.forEach(q => {
                        if (q.products.length > 0) {
                            q.products.forEach(qq => {
                                this.suppliers.push(qq)
                            })
                        }
                        else {
                            this.inactive_suppliers.push(q)
                        }
                    })
                    this.is_loading = false
                    this.towns = data.towns
                    this.counties = data.counties
                    this.provinces = data.provinces
                    this.countries = data.countries
                })

        },
        closeDialog() {
            this.clear_search()
            this.$emit('closeDialog')
        },
        selected_service(item) {
            this.$store.dispatch('itinerary/set_chosen_service_supplier', item)
            this.clear_search()
            this.$emit('close_service_dialog')
        },
        search_city() {
            let data = {
                "country": this.country
            }
            this.$store.dispatch('itinerary/fetch_cities', data)
        },
        async get_rates(data) {
            await this.$store.dispatch('itinerary/fetch_rates', data)
        }
    },
    watch: {
    }
}
</script>

<style scoped lang="scss"></style>