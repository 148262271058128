<template>
    <div>
        <v-app-bar color="#343642" dark>
            <v-toolbar-title>PlanMyTour</v-toolbar-title>
        </v-app-bar>
        <slot />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters({
            get_user: 'auth/get_user'
        })
    }
}
</script>

<style scoped>
.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: inherit;
    height: inherit;
    margin-right: 15px;
    cursor: pointer;
    margin-top: 7px;
}
</style>