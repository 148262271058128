import Vue from "vue";
import axios from 'axios'
const dayjs = require('dayjs')

export default {
    namespaced: true,
    state: {
        itinerary: null,
        itinerary_action: null,
        map_type: [],
        itinerary_info: [],
        itinerary_status: [],
        itinerary_template: [],
        contact_info: [],
        itinerary_contributors: [],
        itinerary_build: [],
        itinerary_build_list: [],
        itinerary_preview: [],
        /**Author: Edgar Garcia 
         * Module: Book Module
        */
        itinerary_build_config: {},
        abs_date_range: {},
        day_selected: {},
        supplier_lists: [],
        isloading: false,
        chosen_service_supplier: {},
        supplier_amenities: [],
        to_push_amenities: [],
        /**End */
        /**
         * Author: Edgar Garcia
         * Module: Pricing
         * Date: April 10, 2023
         */
        pricing: [],
        // end
        /**
         * Author: Edgar Garcia
         * Module: Booking
         * Date: April 11, 2023
         */
        booking: [],
        /**
         * Author: Edgar Garcia
         * Module: Country
         * Date: June 13, 2023
         */
        countries: [],
        cities: [],
        rates: [],
        /**
         * Author: Edgar Fracis Garcia
         * Module: Edit itinerary item
         * Date: June 26, 2023
         */
        itinerary_edit_item: [],
        /**
         * payment notification
         */
        payment_notification_suppliers: [],
        group_name: null,
        itinerary_number: null,
        created_by_info: {},
        invoices: [],
        total_due: null,
        total_paid: null,
        reference_number: null,
        service_charge: 0,
        orr: 0,
    },
    mutations: {
        set_service_charge(state, payload) {
            state.service_charge = payload
        },
        set_orr(state, payload) {
            state.orr = payload
        },
        set_map_type(state, payload) {
            state.map_type = [...payload]
        },
        set_itinerary_info(state, payload) {
            state.itinerary_info = []
            state.itinerary_info = [...payload]
        },
        set_itinerary_status(state, payload) {
            state.itinerary_status = [...payload]
        },
        set_itinerary_template(state, payload) {
            state.itinerary_template = [...payload]
        },
        set_itinerary_action(state, payload) {
            state.itinerary_action = payload
        },
        set_itinerary(state, payload) {
            state.itinerary = payload;
        },
        set_contact_info(state, payload) {
            state.contact_info = [...payload];
        },
        set_itinerary_contributors(state, payload) {
            state.itinerary_contributors = [...payload];
        },
        set_itinerary_build(state, payload) {
            state.itinerary_build = payload;
        },
        set_itinerary_build_list(state, payload) {
            state.itinerary_build_list = [...payload];
        },
        set_itinerary_preview(state, payload) {
            state.itinerary_preview = [...payload]
        },
        set_itinerary_build_config(state, payload) {
            state.itinerary_build_config = payload
        },
        set_abs_date_range(state, payload) {
            state.abs_date_range = payload
        },
        set_day_selected(state, payload) {
            state.day_selected = payload
        },
        set_supplier_lists(state, payload) {
            state.supplier_lists = [...payload]
        },
        set_isloading(state, payload) {
            state.isloading = payload
        },
        set_chosen_service_supplier(state, payload) {
            state.chosen_service_supplier = payload
        },
        set_supplier_amenities(state, payload) {
            state.supplier_amenities = [...payload]
        },
        set_pricing(state, payload) {
            state.pricing = [...payload]
        },
        set_is_lock(state, payload) {
            const data = state.pricing.find(q => {
                return q.id === payload.item.id
            })
            switch (payload.state) {
                case 'is_lock_net': {
                    data.is_lock_net = !data.is_lock_net
                    break
                }
                case 'is_lock_markup': {
                    data.is_lock_markup = !data.is_lock_markup
                    break
                }
                case 'is_lock_gross': {
                    data.is_lock_gross = !data.is_lock_gross
                    break
                }
                case 'is_lock_all': {
                    data.is_lock_all = !data.is_lock_all
                    break
                }
            }
        },
        set_to_push_amenities(state) {
            state.to_push_amenities = []
        },
        set_booking(state, payload) {
            state.booking = [...payload]
        },
        set_countries(state, payload) {
            state.countries = [...payload]
        },
        set_cities(state, payload) {
            state.cities = [...payload]
        },
        set_rates(state, payload) {
            state.rates = [...payload]
        },
        set_itinerary_edit_item(state, payload) {
            state.itinerary_edit_item = [...payload]
        },
        set_payment_notification_suppliers(state, payload) {
            state.payment_notification_suppliers = [...payload]
        },
        set_group_name(state, payload) {
            state.group_name = payload
        },
        set_itinerary_number(state, payload) {
            state.itinerary_number = payload
        },
        set_created_by_info(state, payload) {
            state.created_by_info = payload
        },
        set_invoices(state, payload) {
            state.invoices = [...payload]
        },
        set_total_due(state, payload) {
            state.total_due = payload
        },
        set_total_paid(state, payload) {
            state.total_paid = payload
        },
        set_reference_number(state, payload) {
            state.reference_number = payload
        },
        update_booking_status_mutation(state, payload) {
            const booking = state.booking.filter((item) => item.id == payload.supplier_info.id)
            booking[0].status_id = payload.request == 'Send booking request' ? 2 : 5
            booking[0].get_booking_status = {
                id: payload.request == 'Send booking request' ? 2 : 5,
                status: payload.request == 'Send booking request' ? 'Request Confirmation' : 'Request Cancellation'
            }
        },
        updateBookingStatusBySupplierIdMutation(state, payload) {
            const booking = state.booking.filter((item) => item.id == payload.supplier_id)
            booking[0].status_id = payload.data.id
            booking[0].get_booking_status = {
                id: payload.data.id,
                status: payload.data.status
            }
        }
    },
    getters: {
        get_service_charge: state => state.service_charge,
        get_orr: state => state.orr,
        get_map_type: state => state.map_type,
        get_itinerary_info: state => state.itinerary_info,
        get_itinerary_status: state => state.itinerary_status,
        get_itinerary_template: state => state.itinerary_template,
        get_itinerary_action: state => state.itinerary_action,
        get_itinerary: state => state.itinerary,
        get_contact_info: state => state.contact_info,
        get_itinerary_contributors: state => state.itinerary_contributors,
        get_itinerary_build: (state) => state.itinerary_build,
        get_itinerary_build_list: (state) => state.itinerary_build_list,
        get_itinerary_preview: (state) => state.itinerary_preview,
        get_itinerary_build_config: (state) => state.itinerary_build_config,
        get_abs_date_range: (state) => state.abs_date_range,
        get_day_selected: (state) => state.day_selected,
        get_supplier_lists: (state) => state.supplier_lists,
        get_isloading: (state) => state.isloading,
        get_chosen_service_supplier: (state) => state.chosen_service_supplier,
        get_supplier_amenities: (state) => state.supplier_amenities,
        get_amenities: (state) => (item) => {
            let dd = []
            state.chosen_service_supplier.product_options.filter((q) => {
                let check_in_date = state.day_selected.date
                let check_out_date = state.day_selected.date
                let check_in_time = null
                let check_out_time = null

                if (state.chosen_service_supplier.supplier_info.service_type_id == 1) {
                    check_in_date = state.day_selected.date
                    check_out_date = dayjs(state.day_selected.date).add(1, 'day').format('YYYY-MM-DD')
                    check_in_time = '15:00'
                    check_out_time = '12:00'
                }

                if (state.chosen_service_supplier.supplier_info.service_type_id == 2) {
                    check_in_date = state.day_selected.date
                    check_out_date = state.day_selected.date
                    check_in_time = null
                    check_out_time = null
                }

                if (state.chosen_service_supplier.supplier_info.service_type_id == 3) {
                    check_in_date = state.day_selected.date
                    check_out_date = state.day_selected.date
                    check_in_time = '06:00'
                    check_out_time = '12:00'
                }

                item.forEach(qq => {
                    if (q.id === qq) {
                        dd.push({
                            id: q.id,
                            amenities: q.name,
                            supplier_product_id: q.id,
                            count: 1,
                            check_in_date: check_in_date,
                            check_in_time: check_in_time,
                            check_out_date: check_out_date,
                            check_out_time: check_out_time,
                            instruction: null
                        })
                    }
                })
            })
            state.to_push_amenities = [...dd]
        },
        get_to_push_amenities: (state) => state.to_push_amenities,
        get_pricing: (state) => state.pricing,
        get_booking: (state) => {
            const group = {};            
            state.booking.forEach(obj => {
                const id = obj.amenities.id;
                if(!group[id]) {
                    group[id] = { ...obj, children: []};
                } else {
                    group[id].children.push(obj);
                }
            });

            const item = Object.values(group);            
            return item;
            // return state.booking;
        },
        get_countries: (state) => state.countries,
        get_cities: (state) => state.cities,
        get_rates: (state) => state.rates,
        get_itinerary_edit_item: (state) => state.itinerary_edit_item,
        get_payment_notification_suppliers: (state) => state.payment_notification_suppliers,
        get_group_name: (state) => state.group_name,
        get_itinerary_number: (state) => state.itinerary_number,
        get_created_by_info: (state) => state.created_by_info,
        get_invoices: (state) => state.invoices,
        get_total_due: (state) => state.total_due,
        get_total_paid: (state) => state.total_paid,
        get_reference_number: (state) => state.reference_number
    },
    actions: {
        set_service_charge({ commit }, payload) {
            commit('set_service_charge', payload)
        },
        set_orr({ commit }, payload) {
            commit('set_orr', payload)
        },
        async fetch_map_type({ commit }) {
            await Vue.axios.get('/itinerary/fetch_map_type')
                .then(({ data }) => {
                    commit('set_map_type', data.data)
                })
        },
        async fetch_itinerary_info({ commit }, payload) {
            await Vue.axios.get(`/itinerary/fetch_itinerary_info/${payload}`)
                .then(({ data }) => {
                    commit('set_itinerary_info', data.data)
                })
        },
        async fetch_itinerary_status({ commit }) {
            await Vue.axios.get('/itinerary/fetch_itinerary_status')
                .then(({ data }) => {
                    commit('set_itinerary_status', data.data)
                })
        },
        async fetch_itinerary_template({ commit }) {
            await Vue.axios.get('/itinerary/fetch_itinerary_template')
                .then(({ data }) => {
                    commit('set_itinerary_template', data.data)
                })
        },
        set_itinerary_action({ commit }, payload) {
            commit('set_itinerary_action', payload)
        },
        set_itinerary({ commit }, payload) {
            commit('set_itinerary', payload);
        },
        async fetch_contact_info({ commit }, payload) {
            await Vue.axios.get('/itinerary/fetch_contact', payload)
                .then(({ data }) => {
                    commit('set_contact_info', data.data)
                })
        },
        async fetch_itinerary_contributors({ commit }, payload) {
            await Vue.axios.get('/itinerary/fetch_users_for_contributor', payload)
                .then(({ data }) => {
                    commit('set_itinerary_contributors', data.data)
                })
        },
        set_itinerary_build({ commit }, payload) {
            commit("set_itinerary_build", payload);
        },

        set_itinerary_build_list({ commit }, payload) {
            commit("set_itinerary_build_list", payload);
        },
        async fetch_itinerary_build_list({ commit }, payload) {
            await Vue.axios
                .get(`/itinerary/build/list/${payload}`)
                .then(({ data }) => {
                    commit("set_itinerary_build_list", data.data);
                });
        },
        async fetch_itinerary_preview({ commit }, payload) {
            await Vue.axios.get(`/itinerary/preview/g_preview/${payload}`)
                .then(({ data }) => {
                    commit('set_itinerary_preview', data.data)
                })
        },
        async fetch_itinerary_build_config({ commit }, payload) {
            await Vue.axios.get(`/itinerary/get_itinerary_info/${payload}`)
                .then(({ data }) => {
                    const dateArr = []
                    let currentDate = new Date(data.data.start_date)
                    const stopDate = new Date(data.data.end_date)
                    while (currentDate <= stopDate) {
                        dateArr.push(
                            new Date(currentDate).toLocaleString(undefined, {
                                year: "numeric",
                                day: "2-digit",
                                month: "short",
                                weekday: "short",
                            })
                        )
                        currentDate.setUTCDate(currentDate.getUTCDate() + 1)
                    }
                    let date_data = {
                        date_range: dateArr,
                        start_date: data.data.start_date,
                        end_date: data.data.end_date
                    }
                    commit('set_itinerary_build_config', data.data)
                    Vue.axios.post(`/itinerary/build/base_build/${payload}`, date_data)
                        .then(({ data }) => {
                            commit('set_abs_date_range', data.data)
                        })
                })
        },
        set_day_selected({ commit }, payload) {
            commit('set_day_selected', payload)
        },
        async fetch_supplier_lists({ commit }, payload) {
            await Vue.axios.get('itinerary/build/fetch_supplier', payload)
                .then(({ data }) => {
                    let dt = []
                    data.data.forEach(q => {
                        if (q.products.length > 0) {
                            q.products.forEach(qq => {
                                dt.push(qq)
                            })
                        }
                    })
                    commit('set_supplier_lists', dt)
                    commit('set_isloading', false)
                })
        },
        set_isloading({ commit }, payload) {
            commit('set_isloading', payload)
        },
        set_chosen_service_supplier({ commit }, payload) {
            commit('set_chosen_service_supplier', payload)
        },
        async fetch_supplier_amenities({ commit }, payload) {
            await Vue.axios.get(`/itinerary/build/fetch_supplier_amenities/${payload}`)
                .then(({ data }) => {
                    commit('set_supplier_amenities', data.data)
                })
        },
        async save_itinerary_item(_, payload) {
            await Vue.axios.post('/itinerary/build/save_itinerary_item', payload)
        },
        async fetch_pricing({ commit }, payload) {
            await Vue.axios.get(`/itinerary/pricing/get_pricing/${payload}`)
                .then(({ data }) => {
                    let dt = []
                    data.data.filter(q => {
                        if (q.get_child.length > 0) {
                            q.get_child.forEach(qq => {
                                dt.push({
                                    is_lock_all: false,
                                    is_lock_net: false,
                                    is_lock_markup: false,
                                    is_lock_gross: false,
                                    ...qq
                                })
                            })
                        }
                    })
                    commit('set_pricing', dt)
                })
        },
        async fetch_booking({ commit }, payload) {
            await Vue.axios.get(`itinerary/booking/get_booking_with_status/${payload}`)
                .then(({ data }) => {
                    let dd = [];
                    data.data.filter(q => {
                        if (q.get_child.length > 0) {
                            q.get_child.forEach(qq => {
                                dd.push(qq)
                            })
                        }
                    })

                    commit('set_booking', dd)
                })
        },
        async fetch_countries({ commit }) {
            await axios.get('https://countriesnow.space/api/v0.1/countries')
                .then(({ data }) => {
                    let dd = []
                    data.data.map(q => {
                        dd.push(q.country)
                    })
                    commit('set_countries', dd)
                })
        },
        async fetch_cities({ commit }, payload) {
            await axios.post('https://countriesnow.space/api/v0.1/countries/cities', payload)
                .then(({ data }) => {
                    commit('set_cities', data.data)
                })
        },
        async fetch_rates({ commit }, payload) {
            await Vue.axios.get('itinerary/build/get_rates', payload)
                .then(({ data }) => {
                    commit('set_rates', data.data)
                })
        },
        set_itinerary_edit_item({ commit }, payload) {
            commit('set_itinerary_edit_item', payload)
        },
        async edit_itinerary_item(_, payload) {
            await Vue.axios.patch('/itinerary/build/edit_itinerary_item', payload)
        },
        async delete_itinerary_item(_, payload) {
            await Vue.axios.delete(`/itinerary/build/delete_item/${payload}`)
        },
        async update_payment_status_pricing(_, payload) {
            await Vue.axios.patch('/itinerary/pricing/update_is_paid', payload)
        },
        set_payment_notification_suppliers({ commit }, payload) {
            commit('set_payment_notification_suppliers', payload)
        },
        set_group_name({ commit }, payload) {
            commit('set_group_name', payload)
        },
        async update_booking_id(_, payload) {
            await Vue.axios.patch(`/itinerary/booking/add_booking_reference_id/${payload.id}`, payload)
        },
        set_itinerary_number({ commit }, payload) {
            commit('set_itinerary_number', payload)
        },
        set_created_by_info({ commit }, payload) {
            commit('set_created_by_info', payload)
        },
        async fetch_invoices({ commit }, payload) {
            await Vue.axios.get('itinerary/payments/get_invoices', payload)
                .then(({ data }) => {
                    commit('set_invoices', data.data)
                    if (data.data.length > 0) {
                        commit('set_total_due', data.total_due)
                        commit('set_total_paid', data.total_paid)
                    }
                    else {
                        commit('set_total_due', 0)
                        commit('set_total_paid', 0)
                    }
                })
        },
        async send_booking_request(_, payload) {
            await Vue.axios.post('/itinerary/booking/send_booking_request', payload)
        },
        set_reference_number({ commit }, payload) {
            commit('set_reference_number', payload)
        },
        update_booking_status_action({ commit }, payload) {
            commit('update_booking_status_mutation', payload)
        },
        updateBookingStatusBySupplierIdAction({ commit }, payload) {
            commit('updateBookingStatusBySupplierIdMutation', payload)
        }
    }
}
