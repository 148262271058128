function valBlank(value){
    if(value==''){
        return true;
    }
    else{
        return false;
    }
}

function valCompare(value, compare){
    if(value==compare){
        return true;
    }
    else{
        return false;
    }
}

function valPassword(password){
    const promise = new Promise(function (resolve) {
        const reg_small = /[a-z]/;
        const reg_big = /[A-Z]/;
        const reg_numeric = /[0-9]/;
        if(reg_small.test(password)){
            if(reg_big.test(password)){
                if(reg_numeric.test(password)){
                    if(password.length>=8){
                        resolve(true);
                    }
                    else{
                        resolve(false);
                    }
                }
                else{
                    resolve(false);
                }
            }
            else{
                resolve(false);
            }
        }else{
            resolve(false);
        } 
    });
    return promise;
}

function valEmail(email){
    const promise = new Promise(function (resolve) {
        const reg_ex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(reg_ex.test(email)){
            resolve(true);
        }else{
            resolve(false);
        } 
    });
    return promise;
}

export default {
    valBlank,
    valCompare,
    valEmail,
    valPassword
};