<template>
  <v-container fluid style="background-color: #f4f4f5 !important">
    <v-row class="mt-5" justify="center" align="center">
      <v-card width="1200" elevation="2">
        <v-card-title class="text-small">
          <small><strong>Rates</strong></small>

          <!-- switch for expired season -->
          <v-spacer></v-spacer>
          <small class="mr-3"> Show expired rates </small>
          <v-switch inset dense v-model="switch_expire_rate" @change="toggle_expire_rate"></v-switch>
        </v-card-title>
        <v-card-text>

          <v-expansion-panels>
            <v-expansion-panel v-for="(rootrate, rootrateindex) in get_rates" :key="rootrateindex">
              <v-expansion-panel-header>
                {{ rootrate.name }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-expansion-panels>
                  <v-expansion-panel v-for="(rateoption, rateoptionindex) in rootrate.supplieroption"
                    :key="rateoptionindex">
                    <v-expansion-panel-header>
                      {{ rateoption.name }}
                      <v-spacer />
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-expansion-panels v-for="(itemoptionrate, itemoptionrateindex) in rateoption.supplieroptionrates"
                        :key="itemoptionrateindex" class="rounded-0" outlined>
                        <v-expansion-panel>
                          <div v-if="!itemoptionrate.expired || switch_expire_rate == true">
                            <v-expansion-panel-header>
                              {{ itemoptionrate.start_date }} - {{ itemoptionrate.end_date }}
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <v-data-table :headers="rate_headers" :items="itemoptionrate.supplierrates.filter(x => x.itinerary_id == null)">
                                <template v-slot:item="{ item }">
                                  <tr>
                                    <td>
                                      {{ item.day }}
                                    </td>
                                    <td>
                                      {{ item.net }}
                                    </td>
                                    <td>
                                      {{ item.markup }}
                                    </td>
                                    <td>
                                      {{ item.gross }}
                                    </td>
                                    <td>
                                      {{ item.commission }}
                                    </td>
                                    <td>
                                      <v-btn icon @click="edit_rate(item)">
                                        <v-icon small>mdi-pen</v-icon>
                                      </v-btn>
                                    </td>
                                  </tr>
                                </template>
                              </v-data-table>
                            </v-expansion-panel-content>
                          </div>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

        </v-card-text>
      </v-card>
    </v-row>
    <EditRateDialog :value="open_edit_dialog" @close_dialog="close_dialog" />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import EditRateDialog from './subcomponents/RateEdit.vue'
export default {
  components: {
    EditRateDialog
  },
  props: [],
  data: () => ({
    rate_headers: [
      // {
      //   text: 'Currency'
      // },
      {
        text: 'Day'
      },
      {
        text: 'Net'
      },
      {
        text: 'Markup'
      },
      {
        text: 'Gross'
      },
      {
        text: 'Commission'
      },
      {
        text: ''
      }
    ],
    open_edit_dialog: false,
    switch_expire_rate: true
  }),
  async mounted() {
    await this.$store.dispatch('supplier_rates/fetch_rates', this.get_supplier_setup.id)
  },
  created() { },
  computed: {
    ...mapGetters({
      get_selected_company: "auth/get_selected_company",
      get_supplier_setup: "supplier/get_supplier_setup",
      get_rates: 'supplier_rates/get_rates'
    }),
  },
  methods: {
    edit_rate(data) {
      this.$store.dispatch('supplier_rates/assign_edit_rates_obj', data)
      this.open_edit_dialog = true
    },
    close_dialog() {
      this.open_edit_dialog = false
    },
    toggle_expire_rate() {
      this.$store.dispatch('supplier_rates/toggle_expire_rate', this.switch_expire_rate);
    }
  },
  watch: {},
};
</script>

<style scoped lang="scss"></style>
