import { render, staticRenderFns } from "./SetupComponent.vue?vue&type=template&id=66645d67&scoped=true&"
import script from "./SetupComponent.vue?vue&type=script&lang=js&"
export * from "./SetupComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66645d67",
  null
  
)

export default component.exports