<template>
  <v-container>
    <v-row v-if="get_selected_company.company_name">
      <v-col cols="12" class="mt-15 text-center">
        <h1 class="display-2 font-weight-bold mb-3">
          Welcome to {{ get_selected_company.company_name }}
        </h1>
      </v-col>
      <v-col cols="2">
        <h2>Reports</h2>
        <v-btn @click="openAccountingReportDialog()" color="primary" class="mt-5">Bookings</v-btn>
        <br>
        <v-btn @click="openFinancialReportDialog()" color="primary" class="mt-5">Financial</v-btn>
      </v-col>
      <v-col cols="2">
        <h2>Emails</h2>
        <v-btn @click="openEmailDialog()" color="primary" class="mt-5">Send Bulk Emails</v-btn>
      </v-col>
    </v-row>
    <v-dialog :value="accounting_report_dialog" persistent max-width="800">
      <v-card>
        <v-card-title>
          Booking Reports
          <v-spacer></v-spacer>
          <v-btn @click="closeAccountingReportDialog()" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="2" style="display:flex;align-items:center;justify-content:end;">
              Start/Tour Date
            </v-col>
            <v-col cols="5" class="px-3">
              <v-menu v-model="start_from_menu" :close-on-content-click="false" :nudge-right="40"
                transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="start_from_date" label="From" readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="start_from_date" @input="start_from_menu = false"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="5" class="px-3">
              <v-menu v-model="start_to_menu" :close-on-content-click="false" :nudge-right="40"
                transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="start_to_date" label="To" readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="start_to_date" @input="start_to_menu = false"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12">
              <v-select multiple v-model="selected_status" label="Filter by status" class="mx-5 mt-5" dense
                :items="[{ name: 'None', value: null }, ...get_itinerary_status]" item-text="name"
                item-value="id"></v-select>
            </v-col>
            <v-col cols="12">
              <v-btn @click="getItineraries()" dark>Search</v-btn>
            </v-col>
            <v-col cols="12" v-if="!loading">
              {{ itineraries.length }} records found
            </v-col>
            <v-col cols="12" v-if="itineraries.length > 0 && !loading">
              <download-csv :data="accounting_data">
                <v-btn rounded color="primary" class="pt-4 pb-4 ml-2" style="text-transform: unset !important;">
                  <v-icon class="mr-2">
                    mdi-download-circle-outline
                  </v-icon>
                  Export for Accounting
                </v-btn>
              </download-csv>
            </v-col>
            <v-col cols="6" v-if="itineraries.length > 0 && !loading">
              <download-csv :data="booking_data">
                <v-btn rounded color="primary" class="pt-4 pb-4 ml-2" style="text-transform: unset !important;">
                  <v-icon class="mr-2">
                    mdi-download-circle-outline
                  </v-icon>
                  Export for Supplier Purchases
                </v-btn>
              </download-csv>
            </v-col>
            <v-col cols="6" v-if="itineraries.length > 0 && !loading">
              <v-select v-model="selected_supplier" @change="filterBySupplier()" label="Filter supplier purchases"
                class="mx-5 mt-5" dense :items="suppliers" item-text="supplier_name" item-value="id"
                return-object></v-select>
            </v-col>
            <v-col cols="12" v-if="loading">
              Searching...
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog :value="financial_report_dialog" persistent max-width="800">
      <v-card>
        <v-card-title>
          Financial Reports
          <v-spacer></v-spacer>
          <v-btn @click="closeFinancialReportDialog()" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="2" style="display:flex;align-items:center;justify-content:end;">
              Start/Tour Date
            </v-col>
            <v-col cols="5" class="px-3">
              <v-menu v-model="start_from_menu_financial" :close-on-content-click="false" :nudge-right="40"
                transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="start_from_date_financial" label="From" readonly v-bind="attrs"
                    v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="start_from_date_financial"
                  @input="start_from_menu_financial = false"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="5" class="px-3">
              <v-menu v-model="start_to_menu_financial" :close-on-content-click="false" :nudge-right="40"
                transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="start_to_date_financial" label="To" readonly v-bind="attrs"
                    v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="start_to_date_financial"
                  @input="start_to_menu_financial = false"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12">
              <v-btn @click="getItinerariesFinancial()" dark>Search</v-btn>
            </v-col>
            <v-col cols="12" v-if="!loading">
              {{ itineraries.length }} records found
            </v-col>
            <v-col cols="12" v-if="itineraries.length > 0 && !loading">
              <download-csv :data="financial_data">
                <v-btn rounded color="primary" class="pt-4 pb-4 ml-2" style="text-transform: unset !important;">
                  <v-icon class="mr-2">
                    mdi-download-circle-outline
                  </v-icon>
                  Export Financial Report
                </v-btn>
              </download-csv>
            </v-col>
            <v-col cols="12" v-if="loading">
              Searching...
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog :value="email_dialog" persistent max-width="800">
      <v-card>
        <v-card-title>
          Send Emails
          <v-spacer></v-spacer>
          <v-btn @click="closeEmailDialog()" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field v-model="from_email" label="Sender email" outlined></v-text-field>
            </v-col>
            <v-col>
              <v-text-field v-model="email_subject" label="Subject" outlined></v-text-field>
            </v-col>
            <v-col>
              <v-file-input v-model="recipients_csv" label="Reipients CSV" outlined></v-file-input>
            </v-col>
          </v-row>
          <wysiwyg v-model="email_body" />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeEmailDialog()" text>Close</v-btn>
          <v-btn @click="sendEmails()" dark>Send</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Dashboard',
  data: () => ({
    itineraries: [],
    start_menu: false,
    end_menu: false,
    start_from_menu: false,
    start_to_menu: false,
    start_from_date: null,
    start_to_date: null,
    start_from_menu_financial: false,
    start_to_menu_financial: false,
    start_from_date_financial: null,
    start_to_date_financial: null,
    selected_status: [],
    loading: false,
    accounting_data: [],
    booking_data: [],
    financial_data: [],
    accounting_report_dialog: false,
    financial_report_dialog: false,
    email_dialog: false,
    email_body: null,
    from_email: null,
    email_subject: null,
    recipients_csv: null,
    selected_supplier: null,
    suppliers: [],
    original_data: [],
  }),
  async mounted() {
    await this.$store.dispatch('itinerary/fetch_itinerary_status')
  },
  computed: {
    ...mapGetters({
      get_selected_company: 'auth/get_selected_company',
      get_itinerary_status: 'itinerary/get_itinerary_status',
    }),
  },
  methods: {
    showSnackBar(message) {
      this.$store.commit('auth/setMessage',
        { show: true, message: message },
        { root: 1 })
    },
    getItineraries() {
      this.loading = true
      let payload = {
        itinerary_status_id: this.selected_status,
        start_from_date: (this.start_from_date != null && this.start_from_date != 'None') ? this.start_from_date : null,
        start_to_date: (this.start_to_date != null && this.start_to_date != 'None') ? this.start_to_date : null,
      }
      this.$axios.get(`/itinerary/fetch_itinerary_info_for_report/${this.get_selected_company.id}`, payload)
        .then(({ data }) => {
          console.log(data)
          if (data.response) {
            this.itineraries = data.data
            this.loading = false
            this.setAccountingReport()
            this.setBookingReport()
          }
        })
    },
    getItinerariesFinancial() {
      this.loading = true
      let payload = {
        start_from_date: (this.start_from_date_financial != null && this.start_from_date_financial != 'None') ? this.start_from_date_financial : null,
        start_to_date: (this.start_to_date_financial != null && this.start_to_date_financial != 'None') ? this.start_to_date_financial : null,
      }
      this.$axios.get(`/itinerary/fetch_itinerary_invoices/${this.get_selected_company.id}`, payload)
        .then(({ data }) => {
          console.log(data)
          if (data.response) {
            this.itineraries = data.data
            this.loading = false
            this.setFinancialReport()
          }
        })
    },
    openAccountingReportDialog() {
      this.accounting_report_dialog = true
    },
    closeAccountingReportDialog() {
      this.accounting_report_dialog = false
      this.itineraries = []
    },
    openFinancialReportDialog() {
      this.financial_report_dialog = true
    },
    closeFinancialReportDialog() {
      this.financial_report_dialog = false
      this.itineraries = []
    },
    openEmailDialog() {
      this.email_dialog = true
    },
    closeEmailDialog() {
      this.email_dialog = false
      this.recipients_csv = null
      this.from_email = null
      this.email_body = null
      this.email_subject = null
    },
    async sendEmails() {
      if (this.recipients_csv == null || this.from_email == null || this.email_body == null || this.email_subject == null) {
        this.showSnackBar('Please fill out all fields')
        return
      }
      const formData = new FormData()
      formData.append('file', this.recipients_csv)
      formData.append('from_email', this.from_email)
      formData.append('email_subject', this.email_subject)
      formData.append('email_body', this.email_body)
      await this.$axios.post('dashboard/send_emails', formData)
        .then(({ data }) => {
          if (data.response) {
            this.showSnackBar(data.message)
          }
        })
    },
    async calculateItineraries() {
      await this.$axios.post('/itinerary/calculate_itineraries')
    },
    setAccountingReport() {
      this.accounting_data = []
      this.itineraries.forEach(itinerary => {
        itinerary.get_builds.forEach(build => {
          build.get_child.forEach(child => {
            if (child.get_supplier && child.get_option) {
              let days = this.$date(child.check_out_date).diff(this.$date(child.check_in_date), 'DAY')
              if (days == 0) {
                days = 1
              }
              let new_data = {
                ContactName: child.get_supplier.supplier_name,
                EmailAddress: child.get_supplier.email,
                POAddressLine1: null,
                POAddressLine2: null,
                POAddressLine3: null,
                POAddressLine4: null,
                POCity: null,
                PORegion: null,
                POPostalCode: null,
                POCountry: null,
                InvoiceNumber: itinerary.itinerary_name + ' - ' + itinerary.itinerary_number + ' - ' + child.id,
                InvoiceDate: this.$date(itinerary.start_date).format('DD/MM/YYYY'),
                DueDate: this.$date(itinerary.end_date).format('DD/MM/YYYY'),
                SubTotal: null,
                TotalTax: null,
                Total: null,
                Description: (child.get_option.product ? child.get_option.product.name : null) + ' - ' + child.get_option.name,
                Quantity: child.count,
                UnitAmount: (child.net * child.count * days),
                AccountCode: 310,
                TaxType: 'Tax on Sales (23%)',
                TaxAmount: null,
                TrackingName1: null,
                TrackingOption1: null,
                TrackingName2: null,
                TrackingOption2: null,
                Currency: child.get_supplier.currency
              }
              this.accounting_data.push(new_data)
            }
          })
        })
      })
    },
    setBookingReport() {
      this.booking_data = []
      this.original_data = []
      this.suppliers = []
      this.itineraries.forEach(itinerary => {
        itinerary.get_builds.forEach(build => {
          build.get_child.forEach(child => {
            if (child.get_supplier && child.get_option) {
              let days = this.$date(child.check_out_date).diff(this.$date(child.check_in_date), 'DAY')
              if (days == 0) {
                days = 1
              }
              let new_data = {
                Supplier: child.get_supplier.supplier_name + ' (' + child.get_supplier.currency + ')',
                Date: this.$date(child.check_in_date).format('DD/MM/YYYY'),
                Itinerary: itinerary.itinerary_name,
                Service_Option: (child.get_option.product ? child.get_option.product.name : null) + ', ' + child.get_option.name,
                Booking_ID: child.id,
                Booking_Ref: child.booking_reference_number,
                Itin_Status: itinerary.status_info.name,
                Bkg_Status: child.booking_status ? child.booking_status.status : 'Not yet requested',
                Gross: child.get_supplier.currency + ' ' + (child.gross * child.count * days),
                Net: child.get_supplier.currency + ' ' + (child.net * child.count * days),
                Days: days,
                Quantity: child.count,
              }
              this.booking_data.push(new_data)
              this.original_data.push(new_data)
              this.suppliers.push(child.get_supplier)
            }
          })
        })
      })
      this.booking_data = this.booking_data.sort(function (a, b) {
        if (a.Supplier < b.Supplier) {
          return -1;
        }
        if (a.Supplier > b.Supplier) {
          return 1;
        }
        return 0;
      })

      console.log(this.booking_data)
    },
    filterBySupplier() {
      this.booking_data = this.original_data.filter(x => x.Supplier == (this.selected_supplier.supplier_name + ' (' + this.selected_supplier.currency + ')'))
    },
    setFinancialReport() {
      this.financial_data = []
      this.itineraries.forEach(itinerary => {
        let paid_amount = 0
        itinerary.invoices.forEach(invoice => {
          paid_amount += parseFloat(invoice.amount_paid)
        })
        let new_data = {
          ArrivalDate: this.$date(itinerary.start_date).format('DD-MMM-YYYY'),
          BookingDate: this.$date(itinerary.created_at).format('DD-MMM-YYYY'),
          ItineraryId: itinerary.id,
          ItineraryName: itinerary.itinerary_name,
          AssignedTo: itinerary.contributor_info[0] ? itinerary.contributor_info[0].user.name : null,
          SellPrice: itinerary.gross,
          CostOfSales: itinerary.net,
          Margin: itinerary.margin,
          CustomerPayment: paid_amount,
          AmountDueFromClient: itinerary.gross - paid_amount,
          Surcharge: paid_amount - itinerary.gross,
          Status: itinerary.status_info.name,
          FinalPaymentDueDate: this.$date(itinerary.start_date).subtract(42, 'day').format('DD-MMM-YYYY'),
        }
        this.financial_data.push(new_data)
      })
    },
  }
}
</script>
