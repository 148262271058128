import Vue from "vue";
export default {
    namespaced: true,
    state: {
        booking_status: []
    },
    mutations: {
        setBookingStatusMutation(state, payload) {
            state.booking_status = [...payload]
        }
    },
    getters: {
        getBookingStatusGetters: state => state.booking_status
    },
    actions: {
        send_request(payload){           
            Vue.axios.post('/itinerary/booking/send_booking_request', payload)
        },
        setBookingStatusAction({commit}, payload) {
            commit('setBookingStatusMutation', payload)
        }
    }
}
