import vue from "vue";

export default {
  namespaced: true,
  state: {
    contact_to_archive: {},
    contacts: [],
    contacts_archive: [],
    contact_setup: [],
    contact_list: [],
    gender: [],
    country: [],
    language_spoken: [],
    relationship: [],
    source: [],
  },
  mutations: {
    set_contact_to_archive(state, payload) {
      state.contact_to_archive = payload;
    },
    set_contacts_archive(state, payload) {
      state.contacts_archive = [...payload];
    },
    set_contacts(state, payload) {
      state.contacts = [...payload];
    },
    set_contact_setup(state, payload) {
      state.contact_setup = payload;
    },
    set_contact_list(state, payload) {
      state.contact_list = [...payload];
    },
    set_gender(state, payload) {
      state.gender = [...payload];
    },
    set_country(state, payload) {
      state.country = [...payload];
    },
    set_language_spoken(state, payload) {
      state.language_spoken = [...payload];
    },
    set_relationship(state, payload) {
      state.relationship = [...payload];
    },
    set_source(state, payload) {
      state.source = [...payload];
    },
  },
  getters: {
    get_contact_to_archive: (state) => state.contact_to_archive,
    // get_contacts: (state) => state.contacts,
    get_contacts_archive: (state) => state.contacts_archive,
    get_contacts: (state) => (value, archived) => {
      if (archived) {
        return state.contacts_archive;
      }
      if (value === null) {
        return state.contacts;
      }
      return state.contacts.filter((q) => {
        return q.first_name.toLowerCase().includes(value);
      });
    },
    get_contact_setup: (state) => state.contact_setup,
    get_contacts_via_value: (state) => (number, value, archived) => {
      if (archived) {
        return state.contacts_archive.filter((q) => {
          return q.contact_type_id == number;
        });
      }
      if (value === null || value === "") {
        return state.contacts.filter((q) => {
          return q.contact_type_id == number;
        });
      }
      return state.contacts.filter((q) => {
        return (
          q.supplier_name.toLowerCase().includes(value) &&
          q.contact_type_id == number
        );
      });
    },
    get_contact_list: (state) => state.contact_list,
    get_gender: (state) => state.gender,
    get_country: (state) => state.country,
    get_language_spoken: (state) => state.language_spoken,
    get_relationship: (state) => state.relationship,
    get_source: (state) => state.source,
  },
  actions: {
    set_contact_to_archive({ commit }, payload) {
      commit("set_contact_to_archive", payload);
    },
    async fetch_contacts({ commit }, id) {
      await vue.axios.get(`/contacts/contacts/${id}`).then(({ data }) => {
        commit("set_contacts", data.data.data);
        commit("set_contacts_archive", data.data.archived);
      });
    },
    set_contact_setup({ commit }, payload) {
      commit("set_contact_setup", payload);
    },

    async fetch_contact_list({ commit }) {
      await vue.axios.get("/contacts/contact_type").then(({ data }) => {
        commit("set_contact_list", data.data);
      });
    },
    async fetch_gender({ commit }) {
      await vue.axios.get("contacts/gender").then(({ data }) => {
        commit("set_gender", data.data);
      });
    },
    async fetch_country({ commit }) {
      await vue.axios.get("contacts/country").then(({ data }) => {
        commit("set_country", data.data);
      });
    },
    async fetch_language_spoken({ commit }) {
      await vue.axios.get("contacts/language_spoken").then(({ data }) => {
        commit("set_language_spoken", data.data);
      });
    },
    async fetch_relationship({ commit }) {
      await vue.axios.get("contacts/relationship").then(({ data }) => {
        commit("set_relationship", data.data);
      });
    },
    async fetch_source({ commit }) {
      await vue.axios.get("contacts/source").then(({ data }) => {
        commit("set_source", data.data);
      });
    },
  },
};
