<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-img
          :src="require('../../assets/logo.svg')"
          class="my-3"
          contain
          height="200"
        />
      </v-col>

      <v-col cols="12">
          <v-card
            max-width="515"
            class="mx-auto card-shadow card-nopadding"
          >
            <v-card-text>
              <div class="card-header">
                <h2>Reset Password</h2>
              </div>
              <p class="instruction">Please enter your email address to receive password reset instructions.</p>
              <v-text-field
                label="Email"
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <div class="card-action">
                  <v-btn
                    elevation="2"
                    rounded
                    dark
                    color="cyan"
                    class="login-btn"
                    large
                  >Reset Password</v-btn>
                  <div class="terms-links">
                  </div>
              </div>

            </v-card-actions>
            <div class="card-footer">
              Remembered your password? <a href="/login">Log in</a>
            </div>
          </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'Login',
    data: () => ({
      show_password: false
    }),
    methods: {
    },
  }
</script>

<style scoped>
.card-shadow {
    padding: 10px;
    box-shadow: 1px 1px 15px #888888 !important;
}
.card-nopadding {
  padding: 0;
}
.card-header {
  text-align: center;
}
.card-header h2 {
  margin-bottom: 5px;
}
.card-header span {
  font-size: 11px;
}
.card-forgot span {
  font-size: 11px;
  color: blue;
}
.card-forgot span:hover {
  cursor: pointer;
  text-decoration: underline;
}
.card-action {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.card-action .login-btn {
  max-width: auto;
  margin: 0 auto;
}
.card-action .terms-links{
  font-size: 10px;
  text-align: center;
}
.card-footer {
  background-color: hsl(0, 0%, 83%, 0.5);
  text-align: center;
  padding: 7px;
  font-size: 11px;
}
.instruction {
    margin-top: 15px;
}
</style>
