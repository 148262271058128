<template>
    <v-container fluid class="ma-0 pa-0">
        <v-alert color="#79A2BD" dark class="text-center pa-5 ma-0">
            <strong>{{ get_selected_company.company_name }}</strong>
        </v-alert>
        <v-col cols="12" class="ma-0 pa-0">
            <v-tabs centered icons-and-text dark background-color="#588BAD" v-model="tab" fixed-tabs>
                <v-tab href="#tab-1">
                    Teams
                    <v-icon>
                        mdi-account-group-outline
                    </v-icon>
                </v-tab>
                <v-tab href="#tab-2">
                    Company
                    <v-icon>
                        mdi-domain
                    </v-icon>
                </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
                <v-tab-item value="tab-1">
                    <Team :domain_data="get_selected_company" />
                </v-tab-item>
            </v-tabs-items>
            <v-tabs-items v-model="tab">
                <v-tab-item value="tab-2">
                    <Company />
                </v-tab-item>
            </v-tabs-items>
        </v-col>
    </v-container>
</template>

<script>
import Company from './components/CompanyComponent.vue'
import Team from './components/TeamComponent.vue'
import { mapGetters } from 'vuex'
export default {
    components: {
        Company,
        Team
    },
    props: [
    ],
    data: () => ({
        tab: null
    }),
    mounted() {
    },
    created() {
    },
    computed: {
        ...mapGetters({
            get_selected_company: 'auth/get_selected_company'
        })
    },
    methods: {
    },
    watch: {
    }
}
</script>

<style scoped lang="scss"></style>