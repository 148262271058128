import Vue from 'vue'

export default {
    namespaced: true,
    state: {
        resouces_type: [],
        resources_info: [],
        media_info: []
    },
    mutations: {
        set_resources_type(state, payload){
            state.resouces_type = [...payload]
        },
        set_resources_info(state, payload){
            state.resources_info = [...payload]
        },
        set_resources(state, payload){
            state.resources_info.push(payload)
        },
        set_resources_filter(state, payload){
            state.resources_filter = payload
        },
        set_media_info(state, payload){
            state.media_info = [...payload]
        }
    },
    getters: {
        get_resources_type : state => state.resouces_type,
        get_resources_info : state => (filter, search) => {
            if(filter==0){
                if(search){
                    return state.resources_info.filter(el => {
                        return el.title.toLowerCase().startsWith(search) || el.title.toUpperCase().startsWith(search)
                    })
                }
                else{
                    return state.resources_info
                }
            }
            else{
                if(search){
                    return state.resources_info.filter(el => {
                        return el.resources_type_id == filter && (el.title.toLowerCase().startsWith(search) || el.title.toUpperCase().startsWith(search))
                    })
                }
                else{
                    return state.resources_info.filter(el => {
                        return el.resources_type_id == filter
                    })
                }
            }
        },
        get_media_info : state => (search) => {
            if(search){
                return state.media_info.filter(el => {
                    if(el.original_name.toLowerCase().startsWith(search) || el.original_name.toUpperCase().startsWith(search)){
                        return el
                    }
                    else{
                        if(el.tags != null){
                            return el.tags.some((tag) => {
                                if(tag.toLowerCase().startsWith(search) || tag.toUpperCase().startsWith(search)){
                                    return el
                                }
                            });
                        }
                    }
                })
            }
            else{
                return state.media_info
            }
        },
    },
    actions: {
        async fetch_resources_type({commit}){
            await Vue.axios.get('/resources/fetch_resources_type')
            .then(({data}) => {
                commit('set_resources_type', data.data)
            })
        },
        async fetch_resources_info({commit}, payload){
            await Vue.axios.get(`/resources/fetch_resources_info/${payload}`)
            .then(({data}) => {
                commit('set_resources_info', data.data)
            })
        },
        async fetch_archived_resources_info({commit}, payload){
            await Vue.axios.get(`/resources/fetch_archived_resources_info/${payload}`)
            .then(({data}) => {
                commit('set_resources_info', data.data)
            })
        },
        set_resources({commit}, payload){
            commit('set_resources', payload)
        },
        async fetch_media_info({commit}, payload){
            await Vue.axios.get(`/resources/fetch_media_info/${payload}`)
            .then(({data}) => {
                commit('set_media_info', data.data)
            })
        }
    }
}