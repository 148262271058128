<template>
    <v-row justify="center">
        <v-dialog
            :value="value"
            persistent
            max-width="50%"
        >
            <v-card>
                <v-card-title>
                    <strong>Delete entire row?</strong>
                    <v-spacer/>
                    <v-icon
                        @click="cancel()"
                    >
                        mdi-close
                    </v-icon>
                </v-card-title>
                <v-card-text>
                    Are you sure about this operation? This cannot be reverted
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn
                        outlined
                        rounded
                        class="pl-5 pr-5"
                        @click="cancel()"
                    >
                        cancel
                    </v-btn>
                    <v-btn
                        color="primary"
                        rounded
                        class="pl-5 pr-5"
                        @click="delete_row()"
                    >
                        Delete
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
export default {
  components: {
  },
  props: [
    'value'
  ],
  data () {
    return {
    }
  },
  mounted () {
  },
  created () {
  },
  computed: {
  },
  methods: {
    cancel(){
        this.$emit('cancel')
    },
    delete_row(){
        this.$emit('delete_row')
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>