<template>
  <v-container>
    <div class="spacing"></div>
    <v-row>
        <v-col cols="12" md="6" class="text-center">
            <v-img
                :src="require('../../assets/logo.svg')"
                class="my-3"
                contain
                height="200"
            />
            <h2>Welcome to APPNAME</h2>
            <p>APPNAME is tour operator software that comes complete with everything you need to build, price, book, manage and sell unforgettable travel experiences, without breaking a sweat.</p>
            <v-btn
                elevation="2"
                rounded
                dark
                color="cyan"
                large
            >Learn more</v-btn>
        </v-col>
        <v-col cols="12" md="6">
            <v-card
                max-width="515"
                class="mx-auto card-shadow card-nopadding"
            >
                <v-card-text>
                <div class="card-header">
                    <h2>Sign up to APPNAME</h2>
                    <span>Please fill in required fields</span>
                </div>
                <v-row
                    class="mt-3"
                >
                    <v-col cols="6">
                        <v-text-field
                            label="First Name *"
                            name="first Name"
                            v-model="payload.firstname"
                            @keyup="ValFirstname()"
                            dense
                            outlined
                            prepend-inner-icon="mdi-account-outline"
                            @keyup.enter="Register"
                        ></v-text-field>
                        <ErrMessage :message="validation.firstname.message" :show="validation.firstname.show" :success="validation.firstname.success"/>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field
                            label="Last Name *"
                            name="last Name"
                            v-model="payload.lastname"
                            @keyup="ValLastname()"
                            dense
                            outlined
                            prepend-inner-icon="mdi-account-outline"
                            @keyup.enter="Register"
                        ></v-text-field>
                        <ErrMessage :message="validation.lastname.message" :show="validation.lastname.show" :success="validation.lastname.success"/>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            label="Email *"
                            name="email"
                            v-model="payload.email"
                            @keyup="ValEmail()"
                            dense
                            outlined
                            prepend-inner-icon="mdi-email-outline"
                            @keyup.enter="Register"
                        ></v-text-field>
                        <ErrMessage :message="validation.email.message" :show="validation.email.show" :success="validation.email.success"/>
                    </v-col>
                    <!-- <v-col cols="12">
                        <v-text-field
                            label="Company name *"
                            name="company_name"
                            v-model="payload.company_name"
                            @keyup="ValCompanyName()"
                            dense
                            outlined
                            @keyup.enter="Register"
                        ></v-text-field>
                        <ErrMessage :message="validation.company_name.message" :show="validation.company_name.show" :success="validation.company_name.success"/>
                    </v-col> -->
                    <!-- <v-col cols="12">
                        <v-text-field
                            label="Company website"
                        ></v-text-field>
                    </v-col> -->
                    <v-col cols="12">
                        <v-text-field
                            :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="show_password ? 'text' : 'password'"
                            name="password"
                            label="Password *"
                            @click:append="show_password = !show_password"
                            v-model="payload.password"
                            @keyup="ValPassword()"
                            dense
                            outlined
                            prepend-inner-icon="mdi-lock-outline"
                            @keyup.enter="Register"
                        ></v-text-field>
                        <ErrMessage :message="validation.password.message" :show="validation.password.show" :success="validation.password.success"/>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            :append-icon="show_con_password ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="show_con_password ? 'text' : 'password'"
                            name="confirm_password"
                            label="Confirm password *"
                            @click:append="show_con_password = !show_con_password"
                            v-model="payload.confirm_password"
                            @keyup="ValConfirmPassword()"
                            dense
                            outlined
                            prepend-inner-icon="mdi-lock-outline"
                            @keyup.enter="Register"
                        ></v-text-field>
                        <ErrMessage :message="validation.confirm_password.message" :show="validation.confirm_password.show" :success="validation.confirm_password.success"/>
                    </v-col>
                    <v-col cols="12">
                        <!-- <div class="agree-terms">
                            <v-checkbox
                                name="terms"
                                v-model="terms"
                                @change="ValTerms()"
                            ></v-checkbox>
                            <span>By creating an account you accept APPNAME <a href="">Terms and conditions</a> and <a href="">Privacy policy</a></span>
                        </div> -->
                        <div style="margin-left: 32px;">
                            <ErrMessage :message="validation.terms.message" :show="validation.terms.show" :success="validation.terms.success"/>
                        </div>
                    </v-col>
                </v-row>

                <div class="card-forgot">
                </div>
                </v-card-text>
                <v-card-actions>
                <div class="card-action">
                    <v-btn
                        elevation="2"
                        rounded
                        dark
                        color="cyan"
                        class="login-btn"
                        small
                        @click="Register()"
                        block
                    >Sign up</v-btn>
                    <div class="terms-links">
                    </div>
                </div>

                </v-card-actions>
                <div class="card-footer">
                    Already have an account? <a @click="login_page">Log in</a> now.
                </div>
            </v-card>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>

  import ErrMessage from '../errmessage/ErrMessage.vue';
  import Validation from '../../common/validation';

  export default {
    name: 'Register',
    components: {
        ErrMessage
    },
    data: () => ({
        show_password: false,
        show_con_password: false,
        validation: {
            firstname: {
                message: '',
                show: false,
                success: false
            },
            lastname: {
                message: '',
                show: false,
                success: false
            },
            company_name: {
                message: '',
                show: false,
                success: false
            },
            email: {
                message: '',
                show: false,
                success: false
            },
            password: {
                message: '',
                show: false,
                success: false
            },
            confirm_password: {
                message: '',
                show: false,
                success: false
            },
            terms: {
                message: '',
                show: false,
                success: false
            }
        },
        payload: {
            firstname: '',
            lastname: '',
            email: '',
            name: '',
            company_name: '',
            company_website: '',
            password: '',
            confirm_password: ''
        },
        terms: false,
        err_counter: 0
    }),
    methods: {
        login_page(){
            this.$router.push({name: '/login'})
        },
        ValFirstname(){
            if(Validation.valBlank(this.payload.firstname)){
                this.validation.firstname.message = 'Please input your firstname.';
                this.validation.firstname.show = true;
                this.validation.firstname.success = false;
                return true;
            }
            else{
                this.validation.firstname.show = false;
                return false;
            }
        },
        ValLastname(){
            if(Validation.valBlank(this.payload.lastname)){
                this.validation.lastname.message = 'Please input your lastname.';
                this.validation.lastname.show = true;
                this.validation.lastname.success = false;
                return true;
            }
            else{
                this.validation.lastname.show = false;
                return false;
            }
        },
        ValEmail(){
            if(Validation.valBlank(this.payload.email)){
                this.validation.email.message = 'Please input your email.';
                this.validation.email.show = true;
                this.validation.email.success = false;
                return true;
            }
            else{
                Validation.valEmail(this.payload.email).then((value) => {
                    if(value){
                        this.validation.email.show = false;
                        return false;
                    }
                    else{
                        this.validation.email.message = 'Please input valid email.';
                        this.validation.email.show = true;
                        this.validation.email.success = false;
                        return false;
                    }
                });
            }
        },
        ValCompanyName(){
            if(Validation.valBlank(this.payload.company_name)){
                this.validation.company_name.message = 'Please input your company name.';
                this.validation.company_name.show = true;
                this.validation.company_name.success = false;
                this.err_counter += 1;
            }
            else{
                this.validation.company_name.show = false;
            }
        },
        ValPassword(){
            if(Validation.valBlank(this.payload.password)){
                    this.validation.password.message = 'Please input your password.';
                    this.validation.password.show = true;
                    this.validation.password.success = false;
                    return true;  
            }
            else{
                Validation.valPassword(this.payload.password).then((value) => {
                    if(value){
                        this.validation.password.show = false;
                        return false;
                    }
                    else{
                        this.validation.password.message = 'Your password must include at least 8 alphanumeric characters.';
                        this.validation.password.show = true;
                        this.validation.password.success = false;
                        return true;  
                    }
                });
            }
        },
        ValConfirmPassword(){
            if(Validation.valBlank(this.confirm_password)){
                this.validation.confirm_password.message = 'Please input your confirm password.';
                this.validation.confirm_password.show = true;
                this.validation.confirm_password.success = false;
                return true;
            }
            else{
                this.validation.confirm_password.show = false;
                return false;
            }
        },
        ValPasswordConfirmPassword(){
            if(!Validation.valCompare(this.payload.password, this.confirm_password)){
                this.validation.confirm_password.message = 'Your input password is not match to your confirm password.';
                this.validation.confirm_password.show = true;
                this.validation.confirm_password.success = false;
                return true;
            }
            else{
                this.validation.confirm_password.show = false;
                return false;
            }
        },
        ValTerms(){
            if(!this.terms){
                this.validation.terms.message = 'Please accept the terms and condition and its policy.';
                this.validation.terms.show = true;
                this.validation.terms.success = false;
                return true;
            }
            else{
                this.validation.terms.show = false;
                return false;
            }
        },
        FormValidation(){
            this.err_counter = 0;
            if(this.ValFirstname()){
                this.err_counter += 1;
            }
            if(this.ValLastname()){
                this.err_counter += 1;
            }
            if(this.ValEmail()){
                this.err_counter += 1;
            }
            if(this.ValCompanyName()){
                this.err_counter += 1;
            }
            if(this.ValPassword()){
                this.err_counter += 1;
            }
            if(this.ValConfirmPassword()){
                this.err_counter += 1;
            }
            else{
                if(this.ValPasswordConfirmPassword()){
                    this.err_counter += 1;
                }
            }
            if(this.ValTerms()){
                this.err_counter += 1;
            }
            return this.err_counter;
        },
        showSnackBar(message){
            this.$store.commit("auth/setMessage", 
            {show: true, message: message}, 
            {root: 1})
        },
        async Register(){
            // if(this.FormValidation()==0){
            //     console.log('NO ERROR')
            // }
            await this.$axios.post('/un_auth/register', {
                name:                       this.payload.firstname + ' ' + this.payload.lastname,
                email:                      this.payload.email,
                password:                   this.payload.password,
                confirm_password:           this.payload.confirm_password
            })
            .then(({data}) => {
                if(!data.response){
                    this.showSnackBar(data.message)
                }
                else{
                    this.$router.push({name: '/login'})
                }
            })
            .catch(err => {
                this.showSnackBar(err.message)
            })
        }
    }
  }
</script>

<style scoped>
.card-shadow {
    padding: 10px;
    box-shadow: 1px 1px 15px #888888 !important;
}
.card-nopadding {
  padding: 0;
}
.card-header {
  text-align: center;
}
.card-header h2 {
  margin-bottom: 5px;
}
.card-header span {
  font-size: 11px;
}
.card-forgot span {
  font-size: 11px;
  color: blue;
}
.card-forgot span:hover {
  cursor: pointer;
  text-decoration: underline;
}
.card-action {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.card-action .login-btn {
  max-width: auto;
  margin: 0 auto;
}
.card-action .terms-links{
  font-size: 10px;
  text-align: center;
}
.card-footer {
  background-color: hsl(0, 0%, 83%, 0.5);
  text-align: center;
  padding: 7px;
  font-size: 11px;
}
.spacing {
    margin-top: 3rem;
}
.agree-terms {
    display: flex;
    flex-direction: row;
}
.agree-terms span {
    margin-top: 13px;
}
</style>