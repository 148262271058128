import Vue from 'vue'
import VueRouter from 'vue-router'

// import Login from '../components/login/routes/index.js'
import Login from '../components/login/routes/index.js'
import Dashboard from '../components/dashboard/Dashboard.vue'
// import Register from '../components/register/Register.vue';
import Register from '../components/register/router/index.js'
// import ForgotPassword from '../components/forgotpassword/ForgotPassword.vue';
import ForgotPassword from '../components/forgotpassword/router/index.js'
import OrganisationRoute from '../components/organisation/router/index.js'
import SupplierRoute from '../components/suppliers/router/index.js'
import ResourcesRoute from '../components/resources/router/index.js'
import ContactRoute from '../components/contacts/router/index.js'
import TaskRouter from '../components/task/router/index.js'
import AgenciesRouter from '../components/agencies/router/index.js'
import ItineraryRouter from '../components/itinerary/router/index.js'
import EmailRouter from '../components/emails/router/index.js'

Vue.use(VueRouter)

export default new VueRouter({
    mode: 'history',
    routes: [
        ...Login,
        ...Register,
        ...ForgotPassword,
        ...OrganisationRoute,
        ...SupplierRoute,
        ...ContactRoute,
        ...TaskRouter,
        ...AgenciesRouter,
        ...ItineraryRouter,
        {
            path: '', name: '/', meta: { layout: 'blank' }, component: Login,
        },
        ...ResourcesRoute,
        ...EmailRouter,
        // {
        //     path: '/login', name: 'Login', meta: { layout: 'blank' }, component: Login,
        // },
        // {
        //     path: '/register', name: 'Register', meta: { layout: 'blank' }, component: Register,
        // },
        // {
        //     path: '/forgotpassword', name: 'ForgotPassword', meta: { layout: 'blank' }, component: ForgotPassword,
        // },
        {
            path: '/dashboard', name: '/dashboard', component: Dashboard,
        }
    ]
})