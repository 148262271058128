import router from '../router/router.js'
import axios from 'axios'

axios.interceptors.response.use(
	(response) => response,
	(error) => {
		if(error.response.status == 401){
			localStorage.clear()
			router.push({name: '/'})
			location.reload()
			throw error
		}
		throw error
	}
)

export default function(Vue) {

	Vue.axios = {
		setupHeaderConfig (config) {
			let g_token;
			let token;
			if(JSON.parse(localStorage.getItem('vuex'))){
				g_token = JSON.parse(localStorage.getItem('vuex'))
				token = g_token.auth.token
			}
			!config['headers'] ? config['headers'] = {} : null
			token ? config['headers']['Authorization'] = 'Bearer ' + token : null
			config['withCredentials'] = true
			config['baseURL'] = process.env.VUE_APP_API_URL
			config['headers']['Content-Type'] = 'application/json'
			config['headers']['Access-Control-Allow-Origin'] = "*"
			config['headers']['Access-Control-Allow-Headers'] = "*"
			return config
		},

		post (url, params, config = { headers: {} }) {
			return new Promise(function(resolve) {
				const newConfig = Vue.axios.setupHeaderConfig(config)
				axios.post(url, params, newConfig)
					.then((response) => resolve(response))
					.catch((err) => resolve(err.response || err))
			})
		},

		get (url, params, config = { headers: {} }) {
			return new Promise(function(resolve) {
				config['params'] = params
				const newConfig = Vue.axios.setupHeaderConfig(config)
				axios.get(url, newConfig)
					.then((response) => resolve(response))
					.catch((err) => resolve(err.response || err))
			})
		},

		put (url, params, config = { headers: {} }) {
			return new Promise(function(resolve) {
				const newConfig = Vue.axios.setupHeaderConfig(config)
				// console.log(newConfig)
				axios.put(url, params, newConfig)
					.then((response) => resolve(response))
					.catch((err) => resolve(err.response || err))
			})
		},

		patch (url, params, config = { headers: {} }) {
			return new Promise(function(resolve) {
				const newConfig = Vue.axios.setupHeaderConfig(config)
				axios.patch(url, params, newConfig)
					.then((response) => resolve(response))
					.catch((err) => resolve(err.response || err))
			})
		},

		delete (url, config = { headers: {} }) {
			return new Promise((resolve, reject) => {
				const newConfig = Vue.axios.setupHeaderConfig(config)
				axios.delete(url, {
					...newConfig
				})
					.then((response) => resolve(response))
					.catch((err) => reject(err.response || err))
			})
		}
	}

	Object.defineProperties(Vue.prototype, {
		$axios: {
			get: () => {
				return Vue.axios
			}
		}
	})
}