<template>
  <v-container fluid class="ma-0 pa-0">
    <v-alert color="#79A2BD" dark class="text-center">
      <strong>{{ get_selected_company.company_name }}</strong>
    </v-alert>
    <v-row justify="center" align="center">
      <v-card elevation="2" width="90%" class="mt-5">
        <v-card-title style="font-size: 20px">
          <strong>Suppliers</strong>
          <v-spacer></v-spacer>
          <vue-excel-xlsx :data="suppliers" :columns="columns" :file-name="'Suppliers'" :file-type="'xlsx'"
            :sheet-name="'Sheet 1'">
            <v-btn rounded outlined class="pl-10 pr-10 mr-3">
              EXPORT
            </v-btn>
          </vue-excel-xlsx>
          <v-btn rounded dark color="#525A68" class="pl-10 pr-10" @click="$router.push({ name: '/create-supplier' })">
            Create Supplier
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="3">
              <v-autocomplete label="Town" prepend-inner-icon="mdi-view-list-outline" :items="towns" v-model="town"
                @change="getSuppliers()" />
            </v-col>
            <v-col cols="3">
              <v-autocomplete label="County" prepend-inner-icon="mdi-view-list-outline" :items="counties"
                v-model="county" @change="getSuppliers()" />
            </v-col>
            <v-col cols="3">
              <v-autocomplete label="Province" prepend-inner-icon="mdi-view-list-outline" :items="provinces"
                v-model="province" @change="getSuppliers()" />
            </v-col>
            <v-col cols="3">
              <v-autocomplete label="Country" prepend-inner-icon="mdi-view-list-outline" :items="countries"
                v-model="country" @change="getSuppliers()" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-autocomplete label="Supplier Type" prepend-inner-icon="mdi-view-list-outline" :items="supplier_types"
                item-title="name" item-value="value" v-model="supplier_type" @change="getSuppliers()" />
            </v-col>
            <v-col cols="6">
              <v-text-field label="Supplier Name" prepend-inner-icon="mdi-account" v-model="supplier_name"
                v-on:keyup.enter="getSuppliers()">
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined class="pa-5" rounded @click="clear_search">
            Clear Search
          </v-btn>
          <v-btn color="primary" class="pa-5" rounded @click="getSuppliers()">
            Search
          </v-btn>
        </v-card-actions>
        <v-card-text v-if="searching" class="text-center">
          <strong>Searching...</strong>
          <br />
          <v-progress-circular :size="120" :width="10" color="primary" indeterminate></v-progress-circular>
        </v-card-text>
        <v-card-text v-else>
          <v-data-table :headers="headers_all" :items="suppliers" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
            <template v-slot:item="{ item }">
              <tr style="cursor:pointer;" @click="supplier_setup(item)">
                <td><b>{{ item.supplier_name }}</b></td>
                <td>{{ item.get_supplier_type.name }}</td>
                <td>{{ item.town }}</td>
                <td>{{ item.county }}</td>
                <td>{{ item.province }}</td>
                <td>{{ item.country }}</td>
                <td>{{ item.currency }}</td>
                <td>
                  <v-menu right bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="duplicate(item.id)">
                        <v-list-item-title>
                          Duplicate
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                    <v-list v-if="item.deleted_at == null">
                      <v-list-item @click="archive(item.id)">
                        <v-list-item-title>
                          Archive
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                    <v-list v-else>
                      <v-list-item @click="restore(item.id)">
                        <v-list-item-title>
                          Restore
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </template>
          </v-data-table>
          <div v-if="get_user.id == 1">
            <v-file-input label="import iceland suppliers" v-model="suppliers_iceland_file"
              @change="importIcelandSuppliers()">
            </v-file-input>
            <v-file-input label="import suppliers" v-model="suppliers_file" @change="importSuppliers()"
              v-if="supplier_type">
            </v-file-input>
            <v-file-input label="import accommodations (for missing supplier ids)" v-model="accommodations_file"
              @change="importAccommodations()">
            </v-file-input>
            <v-file-input label="import services" v-model="services_file" @change="importServices()">
            </v-file-input>
            <v-file-input label="import options" v-model="options_file" @change="importOptions()">
            </v-file-input>
            <v-file-input label="import rates" v-model="rates_file" @change="importRates()">
            </v-file-input>
            <v-file-input label="import description" v-model="additional_file" @change="importAdditional()">
            </v-file-input>
            <v-file-input label="import currency" v-model="currency_file" @change="importCurrency()">
            </v-file-input>
          </div>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex"
export default {
  components: {
  },
  props: [],
  data: () => ({
    supplier_name: null,
    supplier_type: null,
    town: null,
    towns: [],
    county: null,
    counties: [],
    province: null,
    provinces: [],
    country: null,
    countries: [],
    searching: false,
    suppliers_iceland_file: null,
    accommodations_file: null,
    suppliers_file: null,
    services_file: null,
    options_file: null,
    rates_file: null,
    additional_file: null,
    currency_file: null,
    sortBy: 'supplier_name',
    sortDesc: false,
    show_archived: false,
    supplier_types: [
      { text: 'Accommodation', value: 1 },
      { text: 'Activity', value: 2 },
      { text: 'Transportation', value: 3 },
      { text: 'Other', value: 4 },
    ],
    headers_all: [
      // { text: "ID", value: 'supplier_id' },
      { text: "Supplier Name", value: 'supplier_name' },
      { text: "Type", value: 'service_type_id' },
      { text: "Town", sortable: false },
      { text: "County", sortable: false },
      { text: "Province", sortable: false },
      { text: "Country", sortable: false },
      { text: "Currency", value: 'currency' },
      // { text: "Description", sortable: false },
      // { text: "Created", value: 'created' },
      // { text: "Services", sortable: false },
      { text: "" },
    ],
    suppliers: [],
    archived_suppliers: [],
    columns: [
      {
        label: "ID",
        field: "id",
      },
      {
        label: "Name",
        field: "supplier_name",
      },
      {
        label: "Type",
        field: "get_supplier_type.name",
      },
      {
        label: "Email",
        field: "email",
      },
      {
        label: "Address",
        field: "address",
      },
      {
        label: "Phone",
        field: "phone",
      },
      {
        label: "Mobile",
        field: "mobile",
      },
      {
        label: "Free Phone",
        field: "free_phone",
      },
      {
        label: "Website",
        field: "website",
      },
      {
        label: "Notes",
        field: "notes",
      },
    ],
  }),
  async mounted() {
    this.getSuppliers()
  },
  created() { },
  computed: {
    ...mapGetters({
      get_user: 'auth/get_user',
      get_selected_company: 'auth/get_selected_company',
    }),
  },
  methods: {
    clear_search() {
      this.supplier_name = null
      this.supplier_type = null
      this.town = null
      this.county = null
      this.province = null
      this.country = null
      this.getSuppliers()
    },
    async importIcelandSuppliers() {
      if (this.suppliers_iceland_file) {
        const formData = new FormData()
        formData.append('file', this.suppliers_iceland_file)
        formData.append('company_id', this.get_selected_company.id)
        await this.$axios.post('suppliers/import_iceland_suppliers', formData)
          .then(({ data }) => {
            console.log(data)
            this.suppliers_iceland_file = null
            this.getSuppliers()
          })
      }
    },
    async importAccommodations() {
      if (this.accommodations_file) {
        const formData = new FormData()
        formData.append('file', this.accommodations_file)
        formData.append('company_id', this.get_selected_company.id)
        formData.append('service_type_id', 1)
        await this.$axios.post('suppliers/import_accommodations', formData)
          .then(({ data }) => {
            console.log(data)
            this.accommodations_file = null
            this.getSuppliers()
          })
      }
    },
    async importSuppliers() {
      if (this.suppliers_file) {
        const formData = new FormData()
        formData.append('file', this.suppliers_file)
        formData.append('company_id', this.get_selected_company.id)
        formData.append('service_type_id', this.supplier_type)
        await this.$axios.post('suppliers/import_suppliers', formData)
          .then(({ data }) => {
            console.log(data)
            this.suppliers_file = null
            this.getSuppliers()
          })
      }
    },
    async importServices() {
      if (this.services_file) {
        const formData = new FormData()
        formData.append('file', this.services_file)
        formData.append('company_id', this.get_selected_company.id)
        await this.$axios.post('suppliers/import_services', formData)
          .then(({ data }) => {
            console.log(data)
            this.services_file = null
            this.getSuppliers()
          })
      }
    },
    async importOptions() {
      if (this.options_file) {
        const formData = new FormData()
        formData.append('file', this.options_file)
        formData.append('company_id', this.get_selected_company.id)
        await this.$axios.post('suppliers/import_options', formData)
          .then(({ data }) => {
            console.log(data)
            this.options_file = null
            this.getSuppliers()
          })
      }
    },
    async importRates() {
      if (this.rates_file) {
        const formData = new FormData()
        formData.append('file', this.rates_file)
        formData.append('company_id', this.get_selected_company.id)
        await this.$axios.post('suppliers/import_rates', formData)
          .then(({ data }) => {
            console.log(data)
            this.rates_file = null
            this.getSuppliers()
          })
      }
    },
    async importAdditional() {
      if (this.additional_file) {
        const formData = new FormData()
        formData.append('file', this.additional_file)
        formData.append('company_id', this.get_selected_company.id)
        await this.$axios.post('suppliers/import_additional', formData)
          .then(({ data }) => {
            console.log(data)
            this.additional_file = null
            this.getSuppliers()
          })
      }
    },
    async importCurrency() {
      if (this.currency_file) {
        const formData = new FormData()
        formData.append('file', this.currency_file)
        formData.append('company_id', this.get_selected_company.id)
        await this.$axios.post('suppliers/import_currency', formData)
          .then(({ data }) => {
            console.log(data)
            this.currency_file = null
            this.getSuppliers()
          })
      }
    },
    async getSuppliers() {
      let tp = {
        company_id: this.get_selected_company.id,
        service_type_id: this.supplier_type,
        supplier_name: this.supplier_name,
        town: this.town,
        county: this.county,
        province: this.province,
        country: this.country
      }
      this.searching = true
      await this.$axios.get(`suppliers/get_suppliers/${this.get_selected_company.id}`, tp)
        .then(({ data }) => {
          if (data.response) {
            this.suppliers = data.data
            this.archived_suppliers = data.archived
            this.towns = data.towns
            this.counties = data.counties
            this.provinces = data.provinces
            this.countries = data.countries
            this.searching = false
          }
        });
    },
    async duplicate(id) {
      await this.$axios.post(`/suppliers/duplicate_supplier/${id}`)
        .then(({ data }) => {
          if (data.response) {
            this.getSuppliers()
          }
        })
    },
    async archive(id) {
      await this.$axios
        .delete(`/suppliers/archive_supplier/${id}`)
        .then(({ data }) => {
          if (data.response) {
            this.getSuppliers()
          }
        });
    },
    async restore(id) {
      await this.$axios
        .patch(`/suppliers/restore_supplier/${id}`)
        .then(({ data }) => {
          if (data.response) {
            this.getSuppliers()
          }
        });
    },
    supplier_setup(data) {
      this.$store.dispatch("supplier/set_supplier_setup", data);
      this.$router.push({ name: "/supplier-setup" });
    },
  },
  watch: {},
};
</script>

<style scoped lang="scss">
::-webkit-scrollbar {
  width: 7px;
  background: white;
}

::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: #D9D9D9;
  color: #D9D9D9;
  height: 20px;
}
</style>
