<template>
    <v-row justify="center">
        <v-dialog
            :value="value"
            persistent
            :max-width="width"
        >
            <v-card
                style="border-radius: 8px;"
            >
                <v-card-title
                    style="fontSize: 20px;"
                >
                    <small>{{title}}</small>
                </v-card-title>
                <v-card-text>
                    <div
                        v-for="(body, bodyindex) in body"
                        :key="bodyindex"
                    >
                        <label
                            v-if="body.label != ''"
                        >
                            {{body}}
                            <strong>{{details}}</strong>
                        </label>
                    </div>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    class="pl-10 pr-10"
                    @click="close_dialog"
                    text
                >
                    Cancel
                </v-btn>
                <v-btn
                    :color="color"
                    dark
                    class="pl-10 pr-10"
                    rounded
                    @click="action_fn"
                >
                    {{action}}
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
export default {
  components: {
  },
  props: [
    'value',
    'title',
    'body',
    'details',
    'width',
    'action',
    'color',
    'data'
  ],
  data: () => ({
  }),
  mounted () {
  },
  created () {
  },
  computed: {
  },
  methods: {
    close_dialog(){
        this.$emit('close_dialog')
    },
    action_fn(){
        this.$emit('proceed_archive')
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>