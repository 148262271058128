import { render, staticRenderFns } from "./SupplierComponent.vue?vue&type=template&id=54668272&scoped=true&"
import script from "./SupplierComponent.vue?vue&type=script&lang=js&"
export * from "./SupplierComponent.vue?vue&type=script&lang=js&"
import style0 from "./SupplierComponent.vue?vue&type=style&index=0&id=54668272&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54668272",
  null
  
)

export default component.exports