<template>
    <v-container fluid style="background-color: #F4F4F5 !important;">
        <v-row
            class="mt-5"
            justify="center" align="center"
        >
            <v-card
                width="820"
                elevation="2"
            >
                <v-card-title class="text-small">
                    <small><strong>Amenities</strong></small>
                </v-card-title>
                <v-card-subtitle>
                    Add features and facilities included with this supplier
                </v-card-subtitle>
                <v-card-text>
                    <v-combobox
                        hide-selected
                        v-model="amenities"
                        label="Add an amenities"
                        hint="press ENTER to add"
                        @keyup.enter="add_amenities"
                        :items="get_amenities"
                        item-value="id"
                        item-text="amenities"
                        @change="select_amenities"
                    ></v-combobox>
                    <!-- {{get_supplier_amenities}} -->
                    <v-list-item two-line
                        v-for="(amenities, amenitiesindex) in get_supplier_amenities"
                        :key="amenitiesindex"
                        style="border-top: 1px solid gray; border-bottom: 1px solid gray;"
                        class="mt-2"
                    >
                        <v-list-item-content>
                            <v-list-item-title>
                                <label
                                    style="fontSize: 16px;"
                                >{{amenities.amenities}}</label>
                            </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-btn icon>
                                <v-icon color="grey lighten-1"
                                    @click="remove_amenities(amenities)"
                                >mdi-delete-outline</v-icon>
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item>
                </v-card-text>
            </v-card>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {
  },
  props: [
  ],
  data: () => ({
    amenities: null
  }),
  mounted () {
  },
  created () {
  },
  computed: {
    ...mapGetters({
        get_selected_company:           'auth/get_selected_company',
        get_supplier_setup:             'supplier/get_supplier_setup',
        get_amenities:                  'supplier/get_amenities',
        get_supplier_amenities:         'supplier/get_supplier_amenities'
    })
  },
  methods: {
    async add_amenities(){
        await this.$axios.post('/suppliers/add_amenities', {
            supplier_id:        this.get_supplier_setup.id,
            company_id:         this.get_selected_company.id,
            amenities:          this.amenities
        })
        .then(({data}) => {
            if(data.response){
                this.$store.dispatch('supplier/fetch_supplier', this.get_selected_company.id)
                this.$store.dispatch('supplier/set_supplier_setup', data.supplier_setup)
                this.amenities = null
            }
        })
    },
    async select_amenities(target){
        await this.$axios.patch('/suppliers/update_amenities_list', {
            supplier_id:            this.get_supplier_setup.id,
            amenities:              target.amenities,
            amenities_id:           target.id
        })
        .then(({data}) => {
            if(data.response){
                this.$store.dispatch('supplier/fetch_supplier', this.get_selected_company.id)
                this.$store.dispatch('supplier/set_supplier_setup', data.supplier_setup)
                this.amenities = null
            }
        })
    },
    async remove_amenities(payload){
        await this.$axios.delete(`/suppliers/remove_amenities_list/${payload.amenities_id}/${this.get_selected_company.id}/${this.get_supplier_setup.id}`)
        .then(({data}) => {
            if(data.response){
                this.$store.dispatch('supplier/fetch_supplier', this.get_selected_company.id)
                this.$store.dispatch('supplier/set_supplier_setup', data.supplier_setup)
            }
        })
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>