<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-img :src="require('../../assets/logo.svg')" class="my-3" contain height="200" />
      </v-col>

      <v-col cols="12">
        <v-card max-width="515" class="mx-auto card-shadow card-nopadding">
          <v-card-text>
            <div class="card-header">
              <h2>Log in to get started</h2>
              <span>Please fill in all fields</span>
            </div>
            <v-text-field label="Email" prepend-inner-icon="mdi-email-outline" outlined dense
              v-model="u.email"></v-text-field>
            <v-text-field :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show_password ? 'text' : 'password'" name="input-10-1" label="Password"
              @click:append="show_password = !show_password" prepend-inner-icon="mdi-lock-outline" outlined dense
              @keyup.enter="login" v-model="u.password"></v-text-field>
            <div class="card-forgot">
              <span @click="forgotPassword()">Forgot Password?</span>
            </div>
          </v-card-text>
          <v-card-actions>
            <div class="card-action">
              <v-btn elevation="2" rounded dark color="cyan" class="login-btn" small block @click="login">Login</v-btn>
              <div class="terms-links">
                <span><a href="">Terms and conditions</a> and <a href="">Privacy policy</a></span>
              </div>
            </div>

          </v-card-actions>
          <div class="card-footer">
            Don't have an account? <a @click="register">Sign up</a>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Login',
  data: () => ({
    show_password: false,
    u: {
      email: null,
      password: null
    }
  }),
  mounted() {
    const token = this.$store.getters['auth/get_user']
    if (Object.keys(token).length !== 0) {
      this.$router.push({ name: '/dashboard' })
    }
  },
  methods: {
    async login() {
      let payload = {
        email: this.u.email,
        password: this.u.password
      }
      await this.$axios.post('/un_auth/login', payload)
        .then(({ data }) => {
          if (data.response) {
            if (data.message) {
              this.$toast.success(data.message)
            }
            this.$router.push({ name: '/dashboard' })
            this.$store.dispatch('auth/set_user', data.user)
            this.$store.dispatch('auth/set_token', data.token)
            return
          }
          this.$toast.error(data.message)
          return
        })
    },
    register() {
      this.$router.push({ name: '/register' })
    },
    forgotPassword() {
      this.$router.push('/forgotpassword')
    },
  },
}
</script>

<style scoped>
.card-shadow {
  padding: 10px;
  box-shadow: 1px 1px 15px #888888 !important;
}

.card-nopadding {
  padding: 0;
}

.card-header {
  text-align: center;
}

.card-header h2 {
  margin-bottom: 5px;
}

.card-header span {
  font-size: 11px;
}

.card-forgot span {
  font-size: 11px;
  color: blue;
}

.card-forgot span:hover {
  cursor: pointer;
  text-decoration: underline;
}

.card-action {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.card-action .login-btn {
  max-width: auto;
  margin: 0 auto;
}

.card-action .terms-links {
  font-size: 10px;
  text-align: center;
}

.card-footer {
  background-color: hsl(0, 0%, 83%, 0.5);
  text-align: center;
  padding: 7px;
  font-size: 11px;
}
</style>
