import Login from '../Login.vue'

export default [
    {
        path: '', 
        name: '/', 
        meta: { layout: 'blank' }, 
        component: Login,
    },
    {
        path: '/login', 
        name: '/login', 
        meta: { layout: 'blank' }, 
        component: Login,
    }
]