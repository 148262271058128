<template>
    <v-container fluid>
        <div v-for="(day, dayindex) in builds" :key="dayindex">
            <v-row v-for="(build, buildindex) in day" :key="buildindex">
                <v-col cols="12"
                    :set="start_day = $date(build.check_in_date).diff($date(itinerary.start_date), 'd') + 1"
                    :set2="end_day = $date(build.check_out_date).diff($date(itinerary.start_date), 'd') + 1" :set3="is_between_days = (group_accommodation_dates == false) ? false :
                        (build.option.product.supplier.service_type_id == 2
                            && ((dayindex > 0 && builds[dayindex - 1].length > 0 && $date(build.check_in_date).isBefore($date(builds[dayindex - 1][0].check_out_date)))
                                || dayindex > 1 && builds[dayindex - 2].length > 0 && $date(build.check_in_date).isBefore($date(builds[dayindex - 2][0].check_out_date))
                                || dayindex > 2 && builds[dayindex - 3].length > 0 && $date(build.check_in_date).isBefore($date(builds[dayindex - 3][0].check_out_date)))
                        )">
                    <v-card elevation="0">
                        <v-card-title v-if="buildindex == 0 && !is_between_days"
                            style="background-color:#030963;color:white;" class="mb-5">
                            <b style="color:white;font-size:16px;font-weight:600;">
                                <label v-if="dayindex == 0">Arrive - </label>
                                <label v-if="dayindex == builds.length - 1">Depart - </label>
                                Day {{ start_day }}
                                <label v-if="start_day != end_day">
                                    to {{ end_day }}
                                </label>:
                                {{ $date(build.check_in_date).format('MMMM D') }}
                                <label v-if="start_day != end_day">
                                    - {{ $date(build.check_out_date).format('MMMM D') }}
                                </label>
                            </b>
                        </v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col :cols="build.option.product.supplier.service_type_id == 2 ? 12 : 8">
                                    <div>
                                        <label v-if="build.option.product.supplier_product_type_id != 5"
                                            style="color:green;font-weight:600;font-size:24px;">
                                            {{ build.option.product.supplier.service_type_id == 1 ? 'HOTEL' :
                                                build.option.product.supplier.service_type_id == 2 ? 'ACTIVITY' :
                                                    'TRANSPORTATION' }}
                                        </label>
                                        <br>
                                        <label style="color:#030963;font-weight:600;font-size:20px;">
                                            <v-icon class="mr-2">
                                                {{ build.option.product.type.icon }}
                                            </v-icon>
                                            <label v-if="show_supplier_name">
                                                {{ build.option.product.supplier.supplier_name }}
                                            </label>
                                            <label
                                                v-if="show_supplier_name && show_product_name || show_supplier_name && show_option_name">
                                                - </label>
                                            <label v-if="show_product_name">
                                                {{ build.option.product.name }}
                                            </label>
                                            <label v-if="show_product_name && show_option_name"> - </label>
                                            <label v-if="show_option_name">
                                                x{{ build.count }} {{ build.option.name }}
                                            </label>
                                        </label>
                                    </div>
                                    <v-row class="mt-2" v-if="build.option.product.supplier.service_type_id == 1">
                                        <v-col cols="5">
                                            <b>Location: </b><br>
                                            <b>Check In: </b><br>
                                            <b>Check Out: </b><br>
                                            <!-- <b>Nights: </b><br> -->
                                            <b>Room: </b>
                                        </v-col>
                                        <v-col cols="7">
                                            {{ build.option.product.supplier.address }}
                                            <br>
                                            {{ $date(build.check_in_date).format('MMMM DD') + ', ' +
                                                $date('2024-01-01' + build.check_in_time).format('h:mm A') }}
                                            <br>
                                            {{ $date(build.check_out_date).format('MMMM DD') + ', ' +
                                                $date('2024-01-01' + build.check_out_time).format('h:mm A') }}
                                            <br>
                                            <!-- {{ $date(build.check_out_date).diff($date(build.check_in_date), 'd') + 1 }} night(s) -->
                                            <!-- <br> -->
                                            x{{ build.count }} {{ build.option.product.name }}
                                        </v-col>
                                    </v-row>
                                    <v-row class="mt-2" v-if="build.option.product.supplier.service_type_id == 3">
                                        <v-col cols="3" style="font-weight:500;color:black;">
                                            <b>{{ build.option.product.type ? build.option.product.type.name : 'Transporation' }}: </b><br>
                                            <b>Pickup Time: </b><br>
                                        </v-col>
                                        <v-col cols="9">
                                            {{ build.option.product.name }}
                                            <br>
                                            {{ $date(build.check_in_date).format('MMMM D') }}
                                            <br>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <label v-if="build.option.product.description != null"
                                                v-html="build.option.product.description">
                                            </label>
                                            <label v-else v-html="build.option.product.supplier.description">
                                            </label>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="4" v-if="build.option.product.supplier.service_type_id != 2">
                                    <v-img v-if="build.option.product.supplier.service_type_id != 3"
                                        :src="build.option.product.supplier.base64_image" eager />
                                    <br>
                                    <v-img :src="build.option.base64_image" eager />
                                </v-col>
                            </v-row>
                            <div v-if="build.option.product.supplier.service_type_id == 2">
                                <v-img v-if="build.option.base64_image" :src="build.option.base64_image" eager />
                            </div>
                        </v-card-text>
                    </v-card>
                    <hr>
                    <br class="html2pdf__page-break">
                </v-col>
            </v-row>
        </div>
        <v-row>
            <v-col cols="12">
                <div>
                    <b style="color:green;font-size:24px;">
                        TERMS AND CONDITIONS
                    </b>
                </div>
                <br>
                <div>
                    <b>{{ itinerary.company.company_name }} T & C</b>
                    <p>Explanation of Terms.</p>
                    <p>In these T&C 'we' and 'us' means {{ itinerary.company.company_name }}</p>
                    <p>
                        'You' means the person purchasing the vacation who has requested a quote and has agreed to
                        our T&C. You must be aware that on signing the booking form and or ticking the box on the quote
                        request you are signing on behalf of every person travelling. If the booking form is signed
                        on behalf of a limited company 'you' means that company. 'Event' means all aspects of a holiday
                        booking with {{ itinerary.company.company_name }} be it an active, social or relaxation.
                    </p>
                    <p>
                        <a :href="'https://www.' + itinerary.company.company_name + '.com/terms-and-conditions/'">
                            View Full Terms & Conditions
                        </a>
                    </p>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    props: [
        'builds', 'itinerary', 'show_supplier_name', 'show_product_name', 'show_option_name', 'group_accommodation_dates'
    ],
}
</script>

<style scoped lang='scss'>
:deep(div),
:deep(.v-image) {
    page-break-inside: avoid;
}
</style>