<template>
  <v-container fluid>
    <v-data-table :headers="headers" :items="get_contacts">
      <template v-slot:top>
        <v-row>
          <v-col cols="9">
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              placeholder="search all category here..."
              label="search all category here..."
            >
            </v-text-field>
          </v-col>
          <v-col cols="3">
            <v-switch
              v-model="show_archived"
              label="Show archived"
              style="position: relative; bottom: 13px"
            ></v-switch>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item="{ item }">
        <tr style="cursor: pointer" @click="contact_setup(item)">
          <td>
            {{ item.first_name }}
          </td>
          <td>
            {{ item.last_name }}
          </td>
          <td>
            {{ item.email }}
          </td>
          <td>
            {{ item.contact_type ? item.contact_type.name : null }}
          </td>
          <td>
            {{ item.created_at }}
          </td>
          <td>
            <v-menu right bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list v-if="item.deleted_at == null">
                <v-list-item @click="archive(item.id)">
                  <v-list-item-title> Archive </v-list-item-title>
                </v-list-item>
              </v-list>
              <v-list v-else>
                <v-list-item @click="restore(item.id)">
                  <v-list-item-title> Restore </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
export default {
  components: {},
  props: ["headers"],
  data: () => ({
    search: null,
    show_archived: false,
  }),
  mounted() {},
  created() {},
  computed: {
    get_contacts() {
      return this.$store.getters["contact/get_contacts_via_value"](
        3,
        this.search,
        this.show_archived
      );
    },
  },
  methods: {
    duplicate(data) {
      console.log(data);
    },
    archive(id) {
      this.$emit("arhive", id);
    },
    restore(id) {
      this.$emit("restore", id);
    },
    contact_setup(data) {
      this.$emit("contact_setup", data);
    },
  },
  watch: {},
};
</script>

<style scoped lang="scss"></style>
