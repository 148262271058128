import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate'

/**
 * imports from app
 */
import AuthenticationState from '../components/login/states/index.js'
import SupplierState from '../components/suppliers/states/index.js'
import ResourcesState from '../components/resources/states/index'
import ContactState from '../components/contacts/states/index.js'
import TaskState from '../components/task/states/index.js'
import AgenciesState from '../components/agencies/states/index.js'
import ItineraryState from '../components/itinerary/states/index.js'
import OrganizationState from '../components/organisation/state/index.js'
import SupplierPricing from '../components/suppliers/states/supplierpricing.js'
import SupplierRates from '../components/suppliers/states/supplierrates.js'
import BookingState from '../components/itinerary/components/booking/states/bookinstate.js'

/**
 * end import from app
 */

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth:                   AuthenticationState,
        supplier:               SupplierState,
        resources:              ResourcesState,
        contact:                ContactState,
        task:                   TaskState,
        agencies:               AgenciesState,
        itinerary:              ItineraryState,
        organization:           OrganizationState,
        supplier_pricing:       SupplierPricing,
        supplier_rates:         SupplierRates,
        booking:                BookingState
    },
    plugins: [createPersistedState()]
});